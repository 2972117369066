import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';

import styles from '../Agreement.module.scss';

interface IAgreementContractsHistoryWrapperProps {
  children: any,
  header?: string
}

const AgreementContractsHistoryWrapper: React.FC<IAgreementContractsHistoryWrapperProps> = ({children, header = 'Agreement contracts'}) => {
  return (
    <Card className={`${styles.agreement} mb-4`}>
      <Card.Header>
        <div className="card-header-content">
          {header}
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        <ListGroup variant="flush">
          {children}
        </ListGroup>
      </Card.Body>
    </Card>
  )
};

export default AgreementContractsHistoryWrapper;