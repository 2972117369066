export const fr = {
    'translation': {
        'user_name': 'User name FR: ',
        'dashboard': 'Dashboard FR',
        'agenda': 'Agenda FR',
        'pipeline': 'Pipeline FR',
        'vehicles': 'Vehicles FR',
        'clients': 'Clients FR',
        'notifications': 'Notifications FR',
        'user': 'User FR'
    }
};
