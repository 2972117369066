import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';

import SignPad from '../../signature-pad/SignaturePad';
import { ConsignmentPartyTypeEnum, DealerAgreementPartyTypeEnum, IAgreement, IParty } from '../Agreement';
import { DateFormatter } from '../../date/DateFormatter';
import { MethodData, useAxios } from '../../custom-axios/CustomAxios';
import { fileUploadPath, saveContractSign } from '../../api/Api';
import FormBuilder, { FieldTypes } from '../../form/FormBuilder';
import { ToastBg } from '../../toast/NotificationToast';
import createToast from '../../toast/CreateToast';
import { JointContext } from '../../../contexts/consignment/JointContext';
import { IConsignment } from '../../interfaces/consignment/consignment';
import Alert from '../../alerts/Alert';
import { getPhotoThumbnailPath, makeFileLowQuality } from '../../utils';

interface ISignatureProps {
    parties: Partial<IAgreement['parties']> | undefined;
    myParty: Partial<IAgreement['myParty']> | undefined;
    onSign: (data: any) => void;
    disabled?: boolean;
    disabledMessage?: string;
    signText?: string
}

const Signature: React.FC<ISignatureProps> = ({
                                                  parties,
                                                  myParty,
                                                  onSign,
                                                  disabled = false,
                                                  disabledMessage,
                                                  signText = 'Sign'
                                              }): JSX.Element => {
    const {signToken} = useContext(JointContext);
    const {fetch: saveSign, error, loading} = useAxios();
    const {fetch: uploadFile, loading: uploadLoading} = useAxios();
    const [signFile, setSignFile] = useState<any>(null);

    return (
        <div className="signature-section">
            {
                disabled && disabledMessage ? (
                    <Alert
                        className={'m-3'}
                        show={true}
                        autoHide={false}
                        type="error"
                        message={disabledMessage}
                    />
                ) : null
            }
            {
                (
                    myParty?.signatureImgPath ? (
                        <ul>
                            {
                                parties?.map(party => (
                                    <li key={party?.id}>
                                        <strong>{!!party?.type && getPartyName(party?.type)}</strong>
                                        <div className="p-4 d-flex align-items-center justify-content-center">
                                            {
                                                party?.signatureImgPath ? (
                                                    <img src={getPhotoThumbnailPath(party?.signatureImgPath, 200, 200)}
                                                         alt=""/>
                                                ) : (
                                                    <p>Not signed yet</p>
                                                )
                                            }
                                        </div>
                                        <div
                                            className="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
                                            <span>{getSignerName(party)}</span>
                                            <span>
                                            {
                                                party?.signedDate ? (
                                                    <DateFormatter date={party.signedDate} format={'MMMM DD, YYYY'}/>
                                                ) : null
                                            }
                                        </span>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    ) : (
                        <div className="signature-pad-wrapper">
                            <FormBuilder submitLabel={signText} forceDisable={!signFile || disabled || loading || uploadLoading} fields={[
                                {
                                    fieldType: FieldTypes.TEMPLATE,
                                    name: 'sign',
                                    template: () => (
                                        <div>
                                            <p className="mb-1">
                                                <strong>{getPartyName(myParty?.type as ConsignmentPartyTypeEnum)}</strong>
                                            </p>
                                            <p>Use your mouse or finger to sign in the box</p>
                                            <SignPad disabled={disabled} onSignSave={setSignFile}/>
                                            <div
                                                className="d-flex flex-column flex-sm-row justify-content-between mt-2 mb-4">
                                                <span>{myParty?.name}</span>
                                                <span>
                                                    {
                                                        <DateFormatter date={myParty?.signedDate || dayjs()}
                                                                       format={'MMMM DD, YYYY'}/>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    )
                                }
                            ]} onSubmit={data => {
                                if ((myParty?.type === 'seller' || myParty?.type === 'joint_owner') && myParty?.status !== 'accepted') {
                                    createToast({
                                        bg: ToastBg.Danger,
                                        content: 'Please accept all sections to sign the contract'
                                    });
                                    return;
                                }
                                onSignSave();
                            }}/>
                        </div>
                    )
                )
            }
        </div>
    );

    function onSignSave() {
        const uploadingCallback = (file: File | Blob) => {
            const formData = new FormData();
            formData.append('file', file);

            uploadFile(fileUploadPath, {
                headers: {},
                body: formData,
                queryParams: signToken ? {
                    signToken
                } : {}
            }).then((json: { path: string }) => {
                myParty?.contractId && saveSign(saveContractSign(myParty.contractId, signToken), {
                    method: MethodData.PUT,
                    body: {
                        signatureImgPath: json.path
                    }
                }).then((data: IConsignment) => {
                    data && onSign(data);
                });
            });
        };
        if (signFile.type.includes('image')) {
            makeFileLowQuality(signFile, uploadingCallback);
        } else {
            uploadingCallback(signFile);
        }
    }

    function getPartyName(type: ConsignmentPartyTypeEnum | DealerAgreementPartyTypeEnum) {
        switch (type) {
            case ConsignmentPartyTypeEnum.FACILITATOR:
                return 'Facilitator (Authorized Representative)';
            case ConsignmentPartyTypeEnum.DEALER:
            case DealerAgreementPartyTypeEnum.DEALER_REPRESENTATIVE:
                return 'Consignee (Authorized Representative)';
            case ConsignmentPartyTypeEnum.JOINT_OWNER:
                return 'Joint owner';
            case ConsignmentPartyTypeEnum.SELLER:
                return 'Consignor';
        }
    }

    function getSignerName(party: IParty | undefined) {
        switch (party?.type) {
            case ConsignmentPartyTypeEnum.DEALER:
                return party.signer ? `${party.signer?.firstName} ${party.signer?.lastName} from ${party.name}` : party.name;
            default:
                return party?.name;
        }
    }
};

export default Signature;
