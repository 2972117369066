import { IBaseProps } from '../input/IInputTypes';
import React, { useState } from 'react';
import { CloseButton, Form } from 'react-bootstrap';

import styles from './file-upload.module.scss';
import { fileUploadPath } from '../api/Api';
import { useAxios } from '../custom-axios/CustomAxios';
import { Loader } from '../loader/Loader';
import { getDocumentFileMimeTypes } from '../utils';

export interface IFileUploadProps extends IBaseProps {
    inputProps?: any;
    multiple?: any;
    accept?: string;
    onFilesChange?: (files: string[]) => void;
}

const FileUpload: React.FC<IFileUploadProps> = ({
                                                    inputProps, defaultValue, validations,
                                                    disabled, isInvalid, label,
                                                    name, multiple = false, accept = getDocumentFileMimeTypes(),
                                                    onFilesChange
                                                }) => {
    const [imageUrls, setImageUrls] = useState<string[]>(defaultValue || []);
    const [fileLoading, setFileLoading] = useState<boolean>(false);
    const {fetch: uploadFile} = useAxios();

    return (
        <div className={styles.fileUpload}>
            <Form.Group className="mb-3">
                <Form.Label htmlFor={`file-uploader-${name}`}>{label}</Form.Label>
                <Form.Control id={`file-uploader-${name}`} type="file"
                              accept={accept}
                              multiple={multiple}
                              disabled={disabled}
                              isInvalid={isInvalid}
                              className={'file-input-button form-control'}
                              onChange={onFileUpload}
                              {...validations as any}/>
            </Form.Group>
            <div>
                {
                    imageUrls?.length ? (
                        <div style={{display: 'flex'}}>
                            {
                                imageUrls.map(url => (
                                    <div key={url} className={'image-thumbnail'}>
                                        <CloseButton onClick={() => onRemove(url)}/>
                                        <img src={url} alt={''}/>
                                    </div>
                                ))
                            }
                            {
                                fileLoading ? (
                                    <div className={'image-thumbnail'}>
                                        <Loader/>
                                    </div>
                                ) : null
                            }
                        </div>
                    ) : (
                        <svg width="96" height="65" viewBox="0 0 96 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_118:9336)">
                                <rect width="96" height="64" rx="9" fill="white"/>
                                <rect x="28" y="12" width="40" height="40" rx="20" fill="black" fillOpacity="0.05"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M53.5125 25.75H55.5C56.163 25.75 56.7989 26.0134 57.2678 26.4822C57.7366 26.9511 58 27.587 58 28.25V35.75C58 36.7446 57.6049 37.6984 56.9017 38.4016C56.1984 39.1049 55.2446 39.5 54.25 39.5H41.75C40.7554 39.5 39.8016 39.1049 39.0983 38.4016C38.3951 37.6984 38 36.7446 38 35.75V28.25C38 27.587 38.2634 26.9511 38.7322 26.4822C39.2011 26.0134 39.837 25.75 40.5 25.75H42.4875C42.8154 25.7486 43.1297 25.6184 43.3625 25.3875L45.1375 23.6125C45.3703 23.3816 45.6846 23.2514 46.0125 23.25H49.9875C50.3154 23.2514 50.6297 23.3816 50.8625 23.6125L52.6375 25.3875C52.8703 25.6184 53.1846 25.7486 53.5125 25.75ZM49.25 24.5H46.75C46.5842 24.5 46.4253 24.5659 46.3081 24.6831C46.1909 24.8003 46.125 24.9592 46.125 25.125C46.125 25.2908 46.1909 25.4497 46.3081 25.5669C46.4253 25.6841 46.5842 25.75 46.75 25.75H49.25C49.4158 25.75 49.5747 25.6841 49.6919 25.5669C49.8091 25.4497 49.875 25.2908 49.875 25.125C49.875 24.9592 49.8091 24.8003 49.6919 24.6831C49.5747 24.5659 49.4158 24.5 49.25 24.5ZM45.2222 36.4698C46.0444 37.0192 47.0111 37.3125 48 37.3125C49.3261 37.3125 50.5979 36.7857 51.5355 35.848C52.4732 34.9104 53 33.6386 53 32.3125C53 31.3236 52.7068 30.3569 52.1574 29.5346C51.6079 28.7124 50.827 28.0715 49.9134 27.6931C48.9998 27.3147 47.9944 27.2157 47.0245 27.4086C46.0546 27.6015 45.1637 28.0777 44.4645 28.777C43.7652 29.4762 43.289 30.3671 43.0961 31.337C42.9031 32.3069 43.0022 33.3123 43.3806 34.2259C43.759 35.1395 44.3999 35.9204 45.2222 36.4698ZM50.8125 32.3125C50.8125 33.8658 49.5533 35.125 48 35.125C46.4467 35.125 45.1875 33.8658 45.1875 32.3125C45.1875 30.7592 46.4467 29.5 48 29.5C49.5533 29.5 50.8125 30.7592 50.8125 32.3125Z"
                                      fill="#222222"/>
                                <rect x="0.5" y="0.5" width="95" height="63" rx="8.5" stroke="black"
                                      strokeOpacity="0.15"/>
                            </g>
                            <defs>
                                <filter id="filter0_d_118:9336" x="0" y="0" width="96" height="65"
                                        filterUnits="userSpaceOnUse"
                                        colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                                   values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                   result="hardAlpha"/>
                                    <feOffset dy="1"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix"
                                             result="effect1_dropShadow_118:9336"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_118:9336"
                                             result="shape"/>
                                </filter>
                            </defs>
                        </svg>
                    )
                }
            </div>
        </div>
    );

    function onFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
        const newFiles: string[] = [];
        const promiseArr: any = [];

        e.target.files?.length && setFileLoading(true);
        e.target.files && Object.values(e.target.files).forEach(file => {
            const formData = new FormData();
            formData.append('file', file);

            promiseArr.push(uploadFile(fileUploadPath, {
                headers: {},
                body: formData
            }));
        });

        Promise.all(promiseArr).then(json => {
            json.forEach((item: any) => {
                newFiles.push(item.path);
            });

            const filesToUpdate = [
                ...imageUrls,
                ...newFiles
            ];

            setImageUrls(filesToUpdate);
            onFilesChange?.(filesToUpdate);
            setFileLoading(false);
        });

    }

    function onRemove(url: string) {
        const newUrls = imageUrls.slice(0);
        const index = newUrls.indexOf(url);

        newUrls.splice(index, 1);
        setImageUrls(newUrls);
        onFilesChange?.(newUrls);
    }
};

export default FileUpload;
