import React, { useContext } from 'react';
import { Button, Card } from 'react-bootstrap';

import { useAxios } from '../../custom-axios/CustomAxios';
import { sendContractToParties } from '../../api/Api';
import { JointContext } from '../../../contexts/consignment/JointContext';
import { UserContext } from '../../../contexts/user/UserContext';
import { PermissionsEnum } from '../../permissions/Permissions';

interface ISendAgreementProps {
    contractId: number;
    onSend: (data: any) => void;
}

const SendAgreement: React.FC<ISendAgreementProps> = ({contractId, onSend}): JSX.Element => {
    const {checkPermission} = useContext(UserContext);
    const disableWriteConsignment = !checkPermission?.(PermissionsEnum.CONTRACT_SIGN);
    const {signToken} = useContext(JointContext);
    const {fetch: sendContractAxios, loading: sendContractAxiosLoading, data} = useAxios();

    return (
        <Card className="send-contact">
            <Card.Body>
                <h5>Send Contract</h5>
                <p>
                    The consignment agreement is ready to be sent to the owner’s for review and signature
                </p>
                <Button data-testid={'send-agreement'}
                        className="standardButton send-agreement-btn"
                        disabled={signToken ? false : disableWriteConsignment}
                        onClick={() => !(signToken ? false : disableWriteConsignment) && sendAgreement()}>
                    Send agreement
                </Button>
            </Card.Body>
        </Card>
    );

    function sendAgreement() {
        sendContractAxios(sendContractToParties(contractId, signToken)).then((json: any) => onSend(json));
    }
};

export default SendAgreement;
