export enum DealerOnboardedSetupEnum {
    EMAIL_VERIFICATION = 'email_verification',
    DEALERSHIP_INFO = 'dealership_information',
    PURR_APPROVAL = 'purr_approval',
    OPERATING_HOURS = 'operating_hours',
    CARFAX_INTEGRATIONS = 'carfax',
    INVITE_STAFF_MEMBERS = 'invite_staff_members',
    CONFIGURE_NOTIFICATIONS = 'configure_notifications',
    SETUP_BANKING_INFORMATION = 'setup_banking_information',
    R22 = 'r22'
}

export interface IHoliday {
    createdAt: string;
    createdByDealer: boolean;
    day: string;
    dealerId: number;
    enabled: boolean;
    holidayId: number;
    id: number;
    isOpen: boolean;
    modifiedAt: string;
    month: string;
    name: string;
    workingEndHour: string;
    workingStartHour: string;
    year: string;
}
export interface IOperatingHours {
    createdAt: string;
    dayOfWeek: string;
    dealerId: number;
    id: number;
    isOpen: boolean
    modifiedAt: string;
    workingEndHour: string;
    workingStartHour: string;
}