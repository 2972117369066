import { IErrorProps } from "../validations/validations";

export enum InputTypes {
    TEXT = 'text',
    PASSWORD = 'password',
    EMAIL = 'email',
    NUMBER = 'number',
    CURRENCY = 'currency',
    SEARCH = 'search',
    TEL = 'tel',
    TIME = 'time',
    TEXTAREA = 'textarea',
    DATE = 'date',
    DATETIME = 'datetime-local',
    HIDDEN = 'hidden',
}

export enum CheckboxTypes {
    CHECKBOX = 'checkbox',
    RADIO = 'radio',
}

export interface IBaseProps {
    name: string;
    label?: string | JSX.Element;
    type?: InputTypes | CheckboxTypes;
    helperText?: string | JSX.Element;
    successMsg?: string;
    error?: IErrorProps;
    errorMsg?: string;
    prefix?: string | JSX.Element;
    suffix?: string | JSX.Element;
    disabled?: boolean;
    getDisabled?: (model: any) => boolean;
    isInvalid?: boolean;
    validations?: IValidationProps;
    placeholder?: string;
    control?: any;
    defaultValue?: any;
    setValue?: (name: string, value: any, opt?: any) => void;
    groupOptions?: { value: any; text: string | JSX.Element, hex?: string, description?: string, customLabel?: JSX.Element, disabled?: boolean }[];
    reset?: (values: any, opts: any) => void;
    className?: string;
    showOptionalBadge?: boolean;
    showRequiredBadge?: boolean;
    min?: number | string;
    max?: number | string;
    testId?: string;
}

export interface IValidationProps {
    required?: boolean | string;
    min?: number;
    max?: number;
    minLength?: number;
    maxLength?: number;
    pattern?: RegExp | any;
    validate?: any;
    setValueAs?: (v: any) => void
}
