import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';

import { DateFormatter } from '../../date/DateFormatter';
import { getContractName } from '../../utils';
import { AgreementSubtypes, ConsignmentStatusEnum, CONTRACT_TYPE, IAgreement } from '../Agreement';
import DownloadContractPdf from '../download/DownloadContractPdf';
import styles from '../Agreement.module.scss';
import dayjs from 'dayjs';
import AgreementContractsHistoryWrapper from './AgreementContractsHistoryWrapper';

interface IAgreementContractsHistoryProps {
  otherContracts: IAgreement[];
}

const AgreementContractsHistory: React.FC<IAgreementContractsHistoryProps> = ({otherContracts}) => {  
  return (
    <AgreementContractsHistoryWrapper>
      {
        otherContracts.sort((a: any, b: any) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf()).map(oContract => (
          <ListGroup.Item key={oContract.id}>
            <div className={'contract-list-item'}>
              <span>
                {getContractName(oContract.subType, oContract.type)}{' '}
                (<DateFormatter 
                  date={oContract.executionDate || oContract.createdAt}
                  format={`MMM DD YYYY${oContract.type !== CONTRACT_TYPE.DEALER_AGREEMENT || oContract.type === CONTRACT_TYPE.DEALER_AGREEMENT && oContract.subType === AgreementSubtypes.ORIGINAL ? ' hh:mma' : ''}`}
                />)
              </span>
              <span>
                <DownloadContractPdf
                  contractId={oContract?.id}
                  contractStatus={oContract.subType === AgreementSubtypes.CHANGE ? ConsignmentStatusEnum.EXECUTED : oContract?.status}
                />
              </span>
            </div>
          </ListGroup.Item>
        ))
      }
    </AgreementContractsHistoryWrapper>
  )
}

export default AgreementContractsHistory;