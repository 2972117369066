import React, { useContext, useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { Button } from 'react-bootstrap';

import { AccordionContainer } from '../accordion/Accordion';
import SendAgreement from './send-agreement/SendAgreement';
import Status from './status/Status';
import Signature from './signature/Signature';
import Accepted from './accepted/Accepted';
import Accept from './accepted/Accept';
import { acceptIcon, checkboxIcon, checkedCheckboxIcon, infoIcon } from '../svg-icons/SvgIcons';
import styles from './Agreement.module.scss';
import DownloadContractPdf from './download/DownloadContractPdf';
import { UserContext, UserType } from '../../contexts/user/UserContext';
import { PermissionsEnum } from '../permissions/Permissions';
import { getWindow } from '../utils';
import Alert from '../alerts/Alert';
import { VehicleStagesEnum } from '../interfaces/vehicle/vehicle';
import AgreementContractsHistory from './agreement-contracts/AgreementContractsHistory';

export enum CONTRACT_TYPE {
    CONSIGNMENT = 'consignment',
    SELLER_PAYMENT = 'sellerPayment',
    DEALER_AGREEMENT = 'dealerOnboardingAgreement'
  };

export enum ConsignmentStatusEnum {
    NOT_SENT = 'not_sent',
    SENT = 'sent',
    EXECUTED = 'executed',
    AMENDMENT_EXECUTED = 'amendment_executed',
    INVALID = 'invalid'
}

export enum PartyStatusEnum {
    NOT_SENT = 'not_sent',
    SENT = 'sent',
    ACCEPTED = 'accepted',
    SIGNED = 'signed'
}

export enum ConsignmentPartyTypeEnum {
    DEALER = 'dealer',
    SELLER = 'seller',
    FACILITATOR = 'facilitator',
    JOINT_OWNER = 'joint_owner'
}

export enum DealerAgreementPartyTypeEnum {
    DEALER_REPRESENTATIVE = 'dealerRepresentative',
    FACILITATOR = 'facilitator'
}

export interface IParty {
    contractId: string;
    createdAt: string;
    id: string;
    name: string;
    status: PartyStatusEnum;
    currentSection: string;
    modifiedAt: string;
    referenceId: string;
    referenceType: string;
    signatureImgPath: string;
    signedDate: string;
    signerId: number;
    sentDate: string;
    signer: {
        firstName: string;
        lastName: string;
    } | null,
    type: ConsignmentPartyTypeEnum | DealerAgreementPartyTypeEnum;
    acceptedDate?: string;
    acceptedSectionIds: string[]
}

export interface ISection {
    id?: string;
    title: string;
    isSigned: boolean;
    content: JSX.Element | string;
    diffContent?: JSX.Element | string;
    contentSnapshot?: JSX.Element | string;
    parties?: {
        id: string;
        isAccepted: boolean;
        acceptedAt: string;
    }[];
}

export enum AgreementSubtypes {
    ORIGINAL = 'original',
    AMENDMENT = 'amendment',
    CHANGE = 'change',
}

export interface IAgreement {
    id: number;
    status: ConsignmentStatusEnum;
    currentSection: string;
    myParty: IParty;
    parties: IParty[];
    createdAt: string;
    executionDate: string;
    subType: AgreementSubtypes;
    modifiedAt: string;
    previousContractId: string;
    sectionIds: string[];
    type: CONTRACT_TYPE;
}

interface IAgreementProps {
    title: string;
    otherContracts: IAgreement[];
    contractData: IAgreement;
    sectionsAndStyles: {
        sections: ISection[],
        styles: string[]
    };
    isForDealer?: boolean;
    vehicleStage?: VehicleStagesEnum;
    forceDisableAccept?: boolean;
    onAccept?: Function;
    getOverView?: () => void;
    getContracts?: () => void
}

const Agreement: React.FC<IAgreementProps> = ({
                                                  contractData,
                                                  sectionsAndStyles,
                                                  isForDealer = true,
                                                  onAccept, title = '',
                                                  otherContracts = [],
                                                  getOverView,
                                                  getContracts,
                                                  vehicleStage,
                                                  forceDisableAccept = false
                                              }): JSX.Element => {
    const {user, checkPermission, canEditVehicle} = useContext(UserContext);
    const disableSignConsignment = forceDisableAccept || !checkPermission?.(PermissionsEnum.CONTRACT_SIGN) || (vehicleStage && !canEditVehicle?.(vehicleStage)) ;
    const disableSendConsignment = !checkPermission?.(PermissionsEnum.CONSIGNMENT_WRITE) || (vehicleStage && !canEditVehicle?.(vehicleStage));
    const [sectionData, setSectionData] = useState(sectionsAndStyles || []);
    const [contract, setContract] = useState<IAgreement>(contractData || {});
    const [highlighChanges, setHighlighChanges] = useState<boolean>(false);
    const [showFullContract, setShowFullContract] = useState<boolean>(false);
    const [isSignSuccessful, setIsSignSuccessful] = useState(false);
    const filteredParties = contract?.parties?.filter(party => {
        return ![ConsignmentPartyTypeEnum.DEALER, ConsignmentPartyTypeEnum.FACILITATOR].includes(party?.type as ConsignmentPartyTypeEnum);
    });

    useEffect(() => {
        contractData && setContract(contractData);
    }, [contractData]);
    useEffect(() => {
        sectionsAndStyles && setSectionData(sectionsAndStyles);
    }, [sectionsAndStyles]);

    useEffect(() => {
        if (sectionData?.styles?.length) {
            const docHead = document.getElementsByTagName('head')[0];
            for (let style of sectionData.styles) {
                let styleTag = document.createElement('style');
                styleTag.appendChild(document.createTextNode(style));
                docHead.appendChild(styleTag);
            }
        }
    }, [sectionData?.styles]);

    let sections = (!showFullContract ? sectionData?.sections?.filter(section => !!section.content) : sectionData?.sections);
    if ((user?.type === UserType.DEALER ? !disableSignConsignment : true) && contract.subType !== AgreementSubtypes.CHANGE && contract?.status !== ConsignmentStatusEnum.NOT_SENT) {

        sections = sections?.concat([
            {
                title: 'Signature',
                id: 'signature',
                isSigned: !!contract?.myParty?.signedDate,
                content: (
                    <Signature
                        myParty={contract?.myParty}
                        parties={contract.parties}
                        disabledMessage={'Please accept all sections to sign'}
                        disabled={![PartyStatusEnum.ACCEPTED, PartyStatusEnum.SIGNED].includes(contract.myParty?.status)}
                        onSign={(data) => {
                            setContract(data);
                            getWindow()?.scrollTo({top: 0});
                            setIsSignSuccessful(true);
                            getOverView?.();
                            getContracts?.();
                        }}
                    />
                )
            }
        ]);
    }

    return (
        <div className={styles.agreement}>
            {
                !isForDealer && !isSignSuccessful && !contract?.myParty?.signedDate && (
                    <div className="consumer-warning">
                        <p>
                            <span className="me-2">{infoIcon}</span>
                            <b>Before you sign</b> - Please review each section to ensure all of the information
                            is correct and accurate.
                        </p>
                        Ask your dealer if you have any questions or corrections.
                    </div>
                )
            }
            {
                isSignSuccessful && (
                    <Alert className={'mb-3'} show={true} type={'success'} message={'Contract successfully signed.'}/>
                )
            }
            {isForDealer ? (
                <>
                    {contract?.status === ConsignmentStatusEnum.NOT_SENT && !disableSendConsignment ? (
                        <SendAgreement
                            contractId={contract?.id}
                            onSend={(data) => {
                                setContract(data);
                            }}
                        />
                    ) : null}
                </>) : null
            }
            {otherContracts.length ? <AgreementContractsHistory otherContracts={otherContracts} /> : null}
            {isForDealer ? (
                <>
                    <Status
                        parties={filteredParties}
                        contractId={contract?.id}
                        contractStatus={contract?.status}
                        forceDisabled={disableSendConsignment}
                        totalSections={contract?.sectionIds?.length}
                        getContracts={getContracts}
                    />
                </>
            ) : null}
            <div id="contractSections">
                {
                    title ? (
                        <h5 className="my-4">{title}</h5>
                    ) : null
                }
                {
                    contract?.subType === AgreementSubtypes.AMENDMENT ? (
                        <div className={'d-flex flex-wrap gap-2 my-2'}>
                            <Button
                                className={`standardButton`}
                                onClick={() => setHighlighChanges(!highlighChanges)}>
                                        <span className="d-flex me-2">
                                          {highlighChanges ? checkedCheckboxIcon : checkboxIcon}
                                        </span>
                                Highlight changes
                            </Button>
                            <Button
                                className={`standardButton`}
                                onClick={() => setShowFullContract(!showFullContract)}>
                                        <span className="d-flex me-2">
                                          {showFullContract ? checkedCheckboxIcon : checkboxIcon}
                                        </span>
                                Show full contract
                            </Button>
                        </div>
                    ) : null
                }
                {sections?.length && contract ? (
                    <AccordionContainer
                        key={`${showFullContract}`}
                        defaultExpandAll={showFullContract}
                        showExpandAll={contract?.subType !== AgreementSubtypes.AMENDMENT}
                        openMultiple={true}
                        buttons={
                            <DownloadContractPdf
                                contractId={contract?.id}
                                contractStatus={contract?.status}
                            />}
                        items={
                            sections.map((section, index: number) => {
                                const isAccepted = section.parties?.find(
                                    (p) => p.id === contract?.myParty.id && p.isAccepted
                                );
                                return {
                                    eventKey: `${index}`,
                                    header: (
                                        <>
                                                <span
                                                    className={`section-title`}>
                                                    {section.title}
                                                </span>
                                            {isAccepted || section.isSigned ? <span>{acceptIcon}</span> : null}
                                        </>
                                    ),
                                    body: (
                                        <div>
                                            {getSectionContent(section)}
                                            {isForDealer ? (
                                                contract.subType !== AgreementSubtypes.CHANGE && section.parties?.length ? (
                                                    <Accepted
                                                        sectionParties={section.parties}
                                                        parties={filteredParties}
                                                        isForDealer={isForDealer}
                                                        forceAccepted={!section.content}
                                                    />
                                                ) : null
                                            ) : section.id === 'signature' || isAccepted || (contract?.subType === AgreementSubtypes.AMENDMENT) && !(section.content && section.diffContent) ? null : (
                                                <Accept vehicleStage={vehicleStage}
                                                        contractId={contract.id}
                                                        sectionId={section.id as string}
                                                        forceDisable={forceDisableAccept}
                                                        onAccept={(data) => {
                                                            setSectionData(data);
                                                            onAccept?.();
                                                        }}
                                                />
                                            )}
                                        </div>
                                    )
                                };
                            })
                        }
                        activeKey={`${getActiveIndex()}`}
                    />
                ) : null}
            </div>
        </div>
    );

    function getActiveIndex() {
        const index = sections?.findIndex((data) => data.id === contract?.myParty?.currentSection) || 0;

        return index === -1 ? 0 : index;
    }

    function getSectionContent(section: ISection) {
        if (section.id === 'signature') {
            return drawContent(section.content);
        }

        let content: JSX.Element;
        if (highlighChanges) {
            content = drawContent(section.diffContent);
        } else {
            if (showFullContract) {
                content = drawContent(section.contentSnapshot);
            } else {
                content = drawContent(section.content);
            }
        }

        return (
            <>
                <div className={highlighChanges ? 'ejs-amendment-diff' : ''}>{content}</div>
            </>
        );
    }

    function drawContent(content: JSX.Element | string = '') {
        return (
            typeof content === 'string' ? (
                <div
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(content, {USE_PROFILES: {html: true}})
                    }}
                />
            ) : content
        );
    }
};

export default Agreement;
