import React, { PropsWithChildren, useEffect, useReducer, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { NextShield, NextShieldProps } from 'next-shield';
import { GoogleTagManager } from '@next/third-parties/google'

import SudoBar from '../sudo-bar/SudoBar';
import ConsumerHeader from '../header/ConsumerHeader';
import { isSudo } from '../../lib/token/token';
import { Loader } from '../../lib/loader/Loader';
import DealerHeader from '../dealer/header/DealerHeader';
import { DealerRolesEnum } from '../../contexts/dealer/Context';
import { PermissionsEnum } from '../../lib/permissions/Permissions';
import { getMePath, getUnreadNotificationsPath } from '../../lib/api/Api';
import { useLoggedIn } from '../../lib/user-logged-in/UserLoggedIn';
import { MethodData, useAxios } from '../../lib/custom-axios/CustomAxios';
import { IUser, UserContext, UserType } from '../../contexts/user/UserContext';
import UserReducer, { initialUserState } from '../../lib/reducer/user/UserReducer';
import { removeRedirectUrl, setRedirectUrl, setUser } from '../../lib/reducer/user/UserReducerDispatch';
import { VehicleStagesEnum } from '../../lib/interfaces/vehicle/vehicle';
import { InitialDataContext } from '../../contexts/init/InitialDataContext';
import SessionStorageProvider from '../../contexts/storage/SessionStorageProvider';
import LocalStorageProvider, { getStorage, updateStorageData } from '../../contexts/storage/LocalStorageProvider';

interface IAuthGuardProps {
    initData: any;
}

const AuthGuard: React.FC<PropsWithChildren<IAuthGuardProps>> = ({children, initData}) => {
    const marketingRoutes = [
        '/sell',
        '/free-appraisal',
        '/buy',
        '/dealer',
        '/about',
        '/contact',
        '/terms',
        '/privacy',
        '/'
    ];
    let hybridRoutes = [
        '/vin',
        '/license',
        '/marketplace',
        '/marketplace/detail/[listingId]',
        '/marketplace/detail/[listingId]/inspection',
        '/verify-email/[sessionId]/[verificationToken]',
        '/l/[listingId]',
        '/dealer/member-signup',
        '/contact-form',
        '/press-release',
        '/press-release/[slug]',
        '/blog',
        '/blog/[slug]',
        '/blog/category/[slug]',
        '/dealer-agreement/[agreementId]',
        '/dealer-agreement/[agreementId]/[contractId]',
        ...marketingRoutes
    ];
    const signRoutes = ['/signin', '/signin/oauth-confirmation/[provider]', '/signup'];
    let publicRoutes = signRoutes.concat(['/reset-password', '/dealer/onboard']);
    const [state, dispatch] = useReducer(UserReducer, initialUserState);
    hybridRoutes = hybridRoutes.concat(...(state?.user?.type === UserType.DEALER ? [] :
        [
            '/consignment/[consignmentId]',
            '/vehicle-lead',
            '/vehicle-lead/[sessionId]',
            '/seller-lead',
            '/seller-lead/[sessionId]',
            '/pre-approval'
        ]));
    const router = useRouter();
    const {isLoggedIn, updateIsLoggedIn} = useLoggedIn();
    const [isAuth, setIsAuth] = useState(false);
    const [isLoading, setLoading] = useState(!hybridRoutes.includes(router.route));
    const {fetch: getMe, loading: getMeLoading} = useAxios();
    const {
        fetch: fetchUnreadNotifications,
        data: unreadNotificationsData
    } = useAxios(state.user ? getUnreadNotificationsPath : '', {
        method: MethodData.GET
    });
    const intervalRef: React.MutableRefObject<any> = useRef();

    useEffect(() => {
        const {rdRedirectUrl} = getStorage();
        if (rdRedirectUrl) {
            dispatch?.(setRedirectUrl(rdRedirectUrl));
        }
        const handleRouteChange = ((url: string) => {
            if (signRoutes.includes(url) && location.pathname &&
                !!['/vehicle-lead', '/seller-lead', '/marketplace', '/l/', '/contact-form'].find((route: string) => location.pathname.includes(route))
            ) {
                dispatch?.(setRedirectUrl(location.pathname));
            }
        });

        router.events.on('routeChangeStart', handleRouteChange);
        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, []);

    useEffect(() => {
        if (router.asPath === state.redirectUrl) {
            dispatch?.(removeRedirectUrl());
        }
    }, [router.asPath]);

    useEffect(() => {
        if (state.user) {
            !isLoggedIn && updateIsLoggedIn(true);
        }
    }, [state.user]);

    useEffect(() => {
        if (initData) {
            !isLoggedIn && clearInterval(intervalRef.current);
            if (!state.user && isLoggedIn) {
                getUser(() => {
                    setIsAuth(true);
                    setLoading(false);
                });
            } else if (state.user) {
                setLoading(false);
                setIsAuth(true);
            } else {
                setLoading(false);
            }
            if (isLoggedIn) {
                intervalRef.current = setInterval(() => {
                    getUnreadNotificationsData?.();
                }, 5 * 60 * 1000);
            }

        }
        return () => intervalRef.current && clearInterval(intervalRef.current);
    }, [isLoggedIn, JSON.stringify(initData)]);

    const shouldHideHeader = (route: string) => {
        return ['/signin/oauth-confirmation', '/dealer/management/stripe-callback'].some((r) => route.includes(r));
    };

    const shieldConfig: NextShieldProps<any[], any[]> = {
        router,
        isAuth,
        isLoading,
        privateRoutes: [
            '/dealer/dashboard', '/dealer/agenda', '/dealer/agenda/action-needed', '/dealer/clients', '/dealer/mechanic', '/dealer/mechanic-service',
            '/dealer/notifications', '/dealer/pipeline', '/dealer/vehicles', '/notifications', '/profile', '/profile/vehicle/[dealerVehicleId]',
            '/profile/vehicle/[dealerVehicleId]/consignment/[consignmentId]', '/profile/listing/[listingId]', '/dealer/management', '/dealer/management/stripe-callback', '/dealer/mechanic-service/[dealerVehicleId]/inspection-report', '/dealer/vehicle/[dealerVehicleId]',
            '/marketplace/detail/preview/[listingId]', '/marketplace/detail/preview/[listingId]/inspection',
            '/dealer/clients/finance-lead/[userId]', '/dealer/clients/finance-lead/[userId]/[applicationId]'
        ],
        publicRoutes,
        hybridRoutes,
        LoadingComponent: <Loader/>,
        loginRoute: '/signin',
        RBAC: {
            dealer: {
                grantedRoutes: getDealerRoutesByPermission(),
                accessRoute: getDealerAccessRouteByPermission()
            },
            consumer: {
                grantedRoutes: [
                    '/notifications', '/profile', '/profile/listing/[listingId]', '/profile/vehicle/[dealerVehicleId]',
                    '/profile/vehicle/[dealerVehicleId]/consignment/[consignmentId]'
                ],
                accessRoute: state.redirectUrl || '/profile'
            }
        },
        userRole: state?.user?.type || undefined
    };

    return (
        <NextShield key={'react-shield'} {...shieldConfig}>
            <main>
                {initData?.googleTagManagerId && <GoogleTagManager gtmId={initData.googleTagManagerId} />}
                {initData?.googleTagManagerId && <noscript>
                  <iframe src={`https://www.googletagmanager.com/ns.html?id=${initData?.googleTagManagerId}`}
                          height="0" width="0" style={{display: 'none', visibility: 'hidden'}}/>
                </noscript>}
                <LocalStorageProvider>
                    <SessionStorageProvider>
                        <InitialDataContext.Provider value={{...initData}}>
                            <UserContext.Provider value={{
                                user: state.user,
                                socialPrefillData: state.socialPrefillData,
                                hasUnreadNotifications: unreadNotificationsData?.hasUnreadNotifications,
                                isFullyOnboarded: state.user?.dealerProfile?.dealer?.isFullyOnboarded,
                                isPaid: state.user?.dealerProfile?.dealer?.isPaid,
                                timezone: state.user?.type === UserType.DEALER ? state.user?.dealerProfile?.dealer?.timezone : state.user?.timezone,
                                redirectUrl: state.redirectUrl,
                                dispatch,
                                getMe: getUser,
                                checkPermission,
                                canEditVehicle,
                                getUnreadNotificationsData
                            }}>
                                {/*<Translate keyword={'user_name'}/> {state?.user?.firstName || '--'}*/}
                                {(!initData || initData?.env === 'prod-main') ? null : (
                                    <div style={{zIndex: 100000}}
                                         className="d-flex justify-content-center gap-3 bg-warning text-black position-sticky top-0 z-index-2">
                                        {initData?.env}
                                        <span onClick={() => router.push('/marketplace')}
                                              className="cursor-pointer">Marketplace &rarr;</span>
                                    </div>
                                )}
                                {
                                    shouldHideHeader(router.route) ? null : (
                                        state.user?.type === UserType.DEALER && !hybridRoutes.includes(router.route) ?
                                            <DealerHeader defaultRoute={getDealerAccessRouteByPermission()}/> :
                                            <ConsumerHeader defaultRoute={'/'}/>
                                    )
                                }
                                {
                                    isSudo() && state.user && <SudoBar/>
                                }
                                {/* {
                                state?.user && state?.user?.type !== UserType.DEALER && !state.user.isVerified && !router.route.includes('verify-email') && (
                                    <Container>
                                        <Alert show={true} type={'error'}
                                               className={'mt-3 mb-3'}
                                               autoHide={false}
                                               message={'Please verify your email'}
                                        />
                                    </Container>
                                )
                            } */}
                                {children}
                            </UserContext.Provider>
                        </InitialDataContext.Provider>
                    </SessionStorageProvider>
                </LocalStorageProvider>
            </main>
        </NextShield>
    );

    function checkPermission(permission: PermissionsEnum): any {
        return state?.user?.dealerProfile?.permissions.includes(permission);
    }

    function canEditVehicle(stage: VehicleStagesEnum): any {
        return ![VehicleStagesEnum.SOLD, VehicleStagesEnum.ACCEPTED, VehicleStagesEnum.SELLER_PAID, VehicleStagesEnum.ARCHIVED].includes(stage);
    }

    function getDealerAccessRouteByPermission(): string {
        const role = state?.user?.dealerProfile?.role;
        switch (role) {
            case DealerRolesEnum.ADMIN:
                return '/dealer/dashboard';
            case DealerRolesEnum.SALES_REP:
            case DealerRolesEnum.SALES_MANAGER:
                return '/dealer/dashboard';
            case DealerRolesEnum.SERVICE_MANAGER:
            case DealerRolesEnum.SERVICE_REP:
                return '/dealer/mechanic';
            case DealerRolesEnum.FINANCING_REP:
                return '/dealer/clients';
        }
        return '/dealer/dashboard';
    }

    function getDealerRoutesByPermission(): string[] {
        let routes: string[] = [
            '/profile',
            '/dealer/notifications',
            '/marketplace',
            '/marketplace/detail/preview/[listingId]',
            '/marketplace/detail/preview/[listingId]/inspection'
        ];

        if (checkPermission?.(PermissionsEnum.VEHICLE_LIST)) {
            routes = routes.concat(['/dealer/dashboard']);
        }

        if (!state.user?.dealerProfile?.dealer?.isFullyOnboarded) {
            if (checkPermission?.(PermissionsEnum.DEALER_MANAGE)) {
                routes = routes.concat(['/dealer/management', '/dealer/management/stripe-callback']);
            }
            return routes;
        }

        if (checkPermission?.(PermissionsEnum.DEALER_MANAGE) ||
            checkPermission?.(PermissionsEnum.USERS_MANAGE_MANAGERS) ||
            checkPermission?.(PermissionsEnum.USERS_MANAGE_SERVICE_REPS) ||
            checkPermission?.(PermissionsEnum.USERS_MANAGE_SALES_REPS)) {
            routes = routes.concat(['/dealer/management', '/dealer/management/stripe-callback']);
        }

        if (checkPermission?.(PermissionsEnum.VEHICLE_LIST)) {
            routes = routes.concat(['/dealer/pipeline', '/dealer/vehicles']);
        }
        if (checkPermission?.(PermissionsEnum.APPOINTMENT_LIST)) {
            routes = routes.concat(['/dealer/agenda', '/dealer/agenda/action-needed']);
        }
        if (checkPermission?.(PermissionsEnum.FINANCING_READ)) {
            routes = routes.concat([
                '/dealer/clients',
                '/dealer/clients/finance-lead/[userId]',
                '/dealer/clients/finance-lead/[userId]/[applicationId]'
            ]);
        }
        if (checkPermission?.(PermissionsEnum.CLIENTS_LIST)) {
            routes = routes.concat(['/dealer/clients']);
        }
        if (checkPermission?.(PermissionsEnum.SERVICE_QUEUE_LIST)) {
            routes = routes.concat(['/dealer/mechanic', '/dealer/mechanic-service', '/dealer/mechanic-service/[dealerVehicleId]/inspection-report']);
        }
        if (checkPermission?.(PermissionsEnum.VEHICLE_READ)) {
            routes = routes.concat(['/dealer/vehicle/[dealerVehicleId]']);
        }

        return routes;
    }

    function getUnreadNotificationsData() {
        fetchUnreadNotifications(getUnreadNotificationsPath, {method: MethodData.GET}, true);
    }

    function getUser(callBack?: Function) {
        !getMeLoading && getMe(getMePath, {method: MethodData.GET}).then((ud: IUser) => {
            dispatch?.(setUser(ud));
            updateStorageData?.('userType', ud?.type || '');
            callBack?.();
        });
    }
};

export default AuthGuard;
