import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

interface ITranslationProps {
    keyword: string;
    hasHtml?: boolean;
    capitalize?: boolean;
    replaceObj?: { [key: string]: string };
}

export const Translate: React.FC<ITranslationProps> = ({
                                                           keyword,
                                                           replaceObj = {},
                                                           hasHtml = false,
                                                           capitalize = false
                                                       }): JSX.Element => {
    const {t} = useTranslation();

    return (
        <>
            {
                hasHtml ? (
                    <em style={{fontStyle: 'normal'}}
                        dangerouslySetInnerHTML={{__html: t(keyword, {replace: replaceObj})}}/>
                ) : t(keyword, {replace: replaceObj})
            }
        </>
    );
};

export function getSimpleTranslation(keyword: string, replaceObj = {}) {
    return i18next.t(keyword, {replace: replaceObj});
}
