import React from 'react';

export interface IStorage {
    rdLastSearch?: string;
    rdRedirectUrl?: string;
    purchaseIntentData?: string;
    userType?: string;
    selectedDealerId?: string;
    dealerDirectLinkClosed?: boolean;
}

export interface ILocalStorageContext extends IStorage {
    updateStorage?: (storageKey: keyof IStorage, value: string) => void;
}

export const LocalStorageContext: React.Context<ILocalStorageContext> = React.createContext({});
export const SessionStorageContext: React.Context<ILocalStorageContext> = React.createContext({});
