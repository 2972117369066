import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import styles from './dealerSellsVehicle.module.scss';
import DealerSellsVehicleModal from './DealerSellsVehicleModal';
import { useWindowResize } from '../window-resize-hook/WindowResizeHook';

const DealerSellsVehicle = () => {
    const {isMobile} = useWindowResize();
    const [showVidModal, setShowVidModal] = useState(false);

    return (
        <div className={styles.dealerSellsVehicle}>
            <OverlayTrigger
                placement={'bottom'}
                overlay={isMobile ? <></> : (
                    <Tooltip id={`tooltip-add-vehicle-top`}>
                        Add vehicle
                    </Tooltip>
                )}
            >
                <Button data-testid={'dealer-add-vehicle'}
                        onClick={() => setShowVidModal(true)}
                        onFocus={(e: React.SyntheticEvent<HTMLButtonElement>) => ((e.target as HTMLButtonElement).blur())}
                        className={'standardButton icon'}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="17px" viewBox="0 0 24 24" width="17px"
                         fill="#000000">
                        <path fill="white"
                              d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px"
                         viewBox="0 0 24 24" width="24px"
                         fill="#white">

                        <g>
                            <path fill="white"
                                  d="M18.92,6.01C18.72,5.42,18.16,5,17.5,5h-11C5.84,5,5.29,5.42,5.08,6.01L3,12v7.5C3,20.33,3.67,21,4.5,21h0 C5.33,21,6,20.33,6,19.5V19h12v0.5c0,0.82,0.67,1.5,1.5,1.5h0c0.82,0,1.5-0.67,1.5-1.5V12L18.92,6.01z M7.5,16 C6.67,16,6,15.33,6,14.5S6.67,13,7.5,13S9,13.67,9,14.5S8.33,16,7.5,16z M16.5,16c-0.83,0-1.5-0.67-1.5-1.5s0.67-1.5,1.5-1.5 s1.5,0.67,1.5,1.5S17.33,16,16.5,16z M5.81,10l1.04-3h10.29l1.04,3H5.81z"/>
                        </g>
                    </svg>
                </Button>
            </OverlayTrigger>

            {showVidModal && (
                <DealerSellsVehicleModal
                    showVidModal={showVidModal}
                    setShowVidModal={setShowVidModal}
                />
            )}
        </div>
    );
};

export default DealerSellsVehicle;
