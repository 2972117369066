import React, { useEffect, useRef, useState } from 'react';
import SignaturePad from 'signature_pad';
import { Button } from 'react-bootstrap';

import styles from './sign-pad.module.scss';

interface ISignaturePadProps {
    filename?: string;
    disabled?: boolean;
    onSignSave: (file: File | null) => void;
}

const SignPad: React.FC<ISignaturePadProps> = ({onSignSave, filename, disabled = false}) => {
    const [isDataEmpty, setIsDataEmpty] = useState(true);
    const signaturePadRef: React.MutableRefObject<any> = useRef();

    useEffect(() => {
        disabled ? signaturePadRef.current?.off() : signaturePadRef.current?.on();
        if (!disabled && !signaturePadRef.current) {
            const canvas: any = document.getElementById('signature-pad');

            signaturePadRef.current = new SignaturePad(canvas);
            signaturePadRef.current.addEventListener('endStroke', () => {
                setIsEmpty();
                urltoFile(signaturePadRef.current.toDataURL('image/png'), filename || 'new_file', '')
                .then(file => onSignSave(file));
            });
        }
    }, [disabled]);

    return (
        <div className={styles.signPad} data-testid={'signature-pad'}>
            <div className="wrapper">
                <canvas id="signature-pad" className="signature-pad" height={260}
                        width={window.innerWidth > 500 ? 400 : 300}/>
                <Button variant="light"
                        className="clear-button"
                        style={{opacity: isDataEmpty ? 0.5 : 1}}
                        // disabled={isDataEmpty}
                        onClick={() => {
                            signaturePadRef.current?.clear();
                            onSignSave(null);
                            setIsEmpty();
                        }}>
                    Clear
                </Button>
            </div>
        </div>
    );

    function setIsEmpty() {
        setIsDataEmpty(signaturePadRef.current?.isEmpty());
    }

    function urltoFile(url: string, filename: string, mimeType: string) {
        mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
        return (fetch(url)
            .then(function (res) {
                return res.arrayBuffer();
            })
            .then(function (buf) {
                return new File([buf], filename, {type: mimeType});
            })
        );
    }
};

export default SignPad;
