import React from 'react';

export enum ToastBg {
    Primary = 'primary',
    Secondary = 'secondary',
    Success = 'success',
    Danger = 'danger',
    Warning = 'warning',
    Info = 'info',
    Light = 'light',
    Dark = 'dark'
}

interface INotificationToastProps {
    bg: ToastBg;
    content?: string | JSX.Element;
}

const Toast: React.FC<INotificationToastProps> = ({content, bg}): JSX.Element => {
    return (
        <>
            {getIcon()}
            <span>{content}</span>
        </>
    );

    function getIcon() {
        switch (bg) {
            case ToastBg.Success:
                return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z"
                        fill="#00DD42"/>
                    <path d="M7.625 12.2917L10.5417 15.2083L16.375 9.375" stroke="#181818" strokeWidth="2"
                          strokeLinecap="round" strokeLinejoin="round"/>
                </svg>;
            case ToastBg.Danger:
                return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.08572 3.9375L1.51072 17.0475C0.205716 19.2975 1.84072 22.1175 4.43572 22.1175H19.5707C22.1657 22.1175 23.7857 19.2975 22.4957 17.0475L14.9357 3.9375C13.6307 1.6875 10.3907 1.6875 9.08572 3.9375Z"
                        fill="#B91C1C"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M10.89 12.73C10.905 13.3 11.385 13.765 11.955 13.795H12.015C12.615 13.795 13.095 13.33 13.125 12.73L13.365 8.305C13.335 7.57 12.735 7 11.94 7C11.58 7.015 11.235 7.165 10.995 7.435C10.755 7.705 10.635 8.05 10.635 8.41L10.89 12.73ZM10.395 16.5999C10.395 17.4849 11.115 18.2049 12 18.2049C12.885 18.2049 13.605 17.4999 13.605 16.5999C13.605 15.7149 12.885 14.9949 12 14.9949C11.115 14.9949 10.395 15.7149 10.395 16.5999Z"
                          fill="white"/>
                </svg>;
            case ToastBg.Warning:
                return <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.5 2C10.5222 2 8.58879 2.58649 6.9443 3.6853C5.29981 4.78412 4.01808 6.3459 3.26121 8.17316C2.50433 10.0004 2.3063 12.0111 2.69215 13.9509C3.078 15.8907 4.03041 17.6725 5.42894 19.0711C6.82746 20.4696 8.60929 21.422 10.5491 21.8078C12.4889 22.1937 14.4996 21.9957 16.3268 21.2388C18.1541 20.4819 19.7159 19.2002 20.8147 17.5557C21.9135 15.9112 22.5 13.9778 22.5 12C22.4949 9.34939 21.4397 6.80881 19.5655 4.93455C17.6912 3.06028 15.1506 2.00508 12.5 2Z"
                        fill="#B45309"/>
                    <path
                        d="M12.9486 6.80994C12.7589 6.68315 12.5358 6.61548 12.3076 6.61548C12.0016 6.61548 11.7081 6.73704 11.4917 6.95343C11.2753 7.16982 11.1537 7.46331 11.1537 7.76932C11.1537 7.99753 11.2214 8.22062 11.3482 8.41037C11.475 8.60012 11.6552 8.74801 11.866 8.83534C12.0769 8.92267 12.3089 8.94552 12.5327 8.901C12.7565 8.85648 12.9621 8.74659 13.1235 8.58522C13.2848 8.42385 13.3947 8.21825 13.4393 7.99443C13.4838 7.7706 13.4609 7.5386 13.3736 7.32777C13.2863 7.11693 13.1384 6.93672 12.9486 6.80994Z"
                        fill="white"/>
                    <path
                        d="M12.4999 17.3847H13.2691C13.4731 17.3847 13.6688 17.3037 13.813 17.1594C13.9573 17.0151 14.0383 16.8195 14.0383 16.6155C14.0383 16.4115 13.9573 16.2158 13.813 16.0715C13.6688 15.9273 13.4731 15.8462 13.2691 15.8462V11.2309C13.2691 11.0268 13.1881 10.8312 13.0438 10.6869C12.8996 10.5427 12.7039 10.4616 12.4999 10.4616H11.7307C11.5266 10.4616 11.331 10.5427 11.1867 10.6869C11.0425 10.8312 10.9614 11.0268 10.9614 11.2309C10.9614 11.4349 11.0425 11.6305 11.1867 11.7748C11.331 11.919 11.5266 12.0001 11.7307 12.0001V16.6155C11.7307 16.8195 11.8117 17.0151 11.956 17.1594C12.1002 17.3037 12.2959 17.3847 12.4999 17.3847Z"
                        fill="white"/>
                </svg>;
        }
    }
};

export default Toast;
