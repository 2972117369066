import React, { PropsWithChildren, useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useFormContext } from 'react-hook-form';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';

interface IInputProps extends UseFormRegisterReturn {
    placeholder?: string;
    min?: string | number;
}

interface ICurrencyInputProps {
    inputProps: IInputProps;
    defaultValue?: string;
    reference?: React.RefCallback<HTMLInputElement>
    disabled?: boolean;
}

const CurrencyFormatInput: React.FC<PropsWithChildren<ICurrencyInputProps>> = ({
                                                                                   inputProps,
                                                                                   defaultValue,
                                                                                   reference,
                                                                                   disabled = false
                                                                               }) => {
    const formContext = useFormContext();
    const [value, setValue] = useState<string>(formContext?.getValues(inputProps.name));

    useEffect(() => {
        setValue(defaultValue || '');
    }, [defaultValue]);

    return (
        <div className={'input-group plain-control'}>
            <CurrencyInput className={'form-control'}
                           intlConfig={{locale: 'en-US', currency: 'USD'}}
                           name={inputProps.name}
                           onBlur={inputProps.onBlur}
                           placeholder={inputProps.placeholder}
                           {...(inputProps?.min ? {min: inputProps?.min} : {})}
                           {...(inputProps?.max ? {max: inputProps?.max} : {})}
                           {...(reference ? {ref: reference} : {})}
                           value={value}
                           allowNegativeValue={false}
                           disabled={disabled}
                           decimalScale={2}
                           onValueChange={(value, name) => {
                               if (formContext && name) {
                                   const parsedValue = `${value?.replace(/[^\d.-]/g, '').replace(/\.$/g, '') || ''}`;

                                   setValue(parsedValue || '');
                                   formContext.setValue(name, (parsedValue || '').split('.')[0], {
                                       shouldValidate: true
                                   });
                               }
                           }}/>
        </div>
    );
};

export default CurrencyFormatInput;