import React, { useContext, useState } from 'react';
import { useAxios } from '../../custom-axios/CustomAxios';
import { Button } from 'react-bootstrap';
import { sendContractToParties, sendContractToParty } from '../../api/Api';
import createToast from '../../toast/CreateToast';
import { ToastBg } from '../../toast/NotificationToast';
import { JointContext } from '../../../contexts/consignment/JointContext';

interface IResendProps {
    contractId: number;
    isForAll?: boolean;
    partyId?: string;
    partyName?: string;
    sendingItem?: string;
    getContracts?: () => void
}

const Resend: React.FC<IResendProps> = ({partyId, contractId, partyName, isForAll, sendingItem = 'Contract', getContracts}): JSX.Element => {
    const [disabled, setDisabled] = useState(false);
    const {signToken} = useContext(JointContext);
    const {fetch: sendContractToPartyAxios, loading: sendContractToPartyAxiosLoading} = useAxios();
    const {fetch: sendContractAxios, loading: sendContractAxiosLoading, data} = useAxios();

    return (
        <Button
            style={{color: '#222'}}
            className="link-button"
            variant="link"
            disabled={disabled || sendContractToPartyAxiosLoading || sendContractAxiosLoading}
            onClick={isForAll ? sendContract : sendContractToOne}>
            Resend
        </Button>
    );

    function sendContractToOne() {
        contractId && partyId && !disabled && sendContractToPartyAxios(sendContractToParty(contractId, signToken), {
            body: {partyId}
        }).then(() => {
            setDisabled(true);
            getContracts?.();
            createToast({
                bg: ToastBg.Success,
                content: `${sendingItem} has been successfully sent to ${partyName}`
            });
        });
    }

    function sendContract() {
        sendContractAxios(sendContractToParties(contractId, signToken)).then(() => {
            getContracts?.();
            createToast({
                bg: ToastBg.Success,
                content: `${sendingItem} has been successfully sent to parties`
            });
        });
    }
};

export default Resend;
