import React from 'react';
import { Image } from 'react-bootstrap';
import { vehicleMockupImg } from '../utils';

interface IVehicleImgProps {
    src: string;
    alt?: string;
    rounded?: boolean;
}

const VehicleImg: React.FC<IVehicleImgProps> = ({src, alt = 'Vehicle img', rounded = false}) => {
    return (
        <Image src={src || vehicleMockupImg} rounded={rounded} alt={alt}
               onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                   (e.target as HTMLImageElement).src = vehicleMockupImg;
               }}/>
    );
};

export default VehicleImg;