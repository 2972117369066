import React, { useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import { MethodData, useAxios } from '../../custom-axios/CustomAxios';
import { getContractPdfIdPath } from '../../api/Api';
import { ConsignmentStatusEnum } from '../Agreement';
import { JointContext } from '../../../contexts/consignment/JointContext';

interface IDownloadProps {
    contractId: number;
    contractStatus: ConsignmentStatusEnum;
    className?: string;
    disabled?: boolean;
    downloadText?: string;
    downloadUnsignedText?: string
}

const DownloadContractPdf: React.FC<IDownloadProps> = ({
                                                           contractId,
                                                           className = '',
                                                           disabled = false,
                                                           contractStatus,
                                                           downloadText = 'Download',
                                                           downloadUnsignedText = 'Download unsigned agreement'
                                                       }): JSX.Element => {
    const {signToken} = useContext(JointContext);
    const {loading: loadingPDF, fetch: fetchPDF} = useAxios();
    const [downloading, setDownloading] = useState(false);
    const isExecuted = contractStatus === ConsignmentStatusEnum.EXECUTED;

    return (
        <Button
            className={`${isExecuted ? 'standardButton' : 'link-button'} ${className}`}
            variant={`${isExecuted ? '' : 'link'}`}
            disabled={disabled || downloading}
            onClick={() => {
                setDownloading(true);
                !disabled && fetchPDF(getContractPdfIdPath(contractId, signToken), {
                    method: MethodData.GET,
                    responseType: 'arraybuffer'
                }).then((data: any) => {
                    const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'contract.pdf');
                    document.body.appendChild(link);
                    link.click();
                    setDownloading(false);
                });
            }}
        >
            <span className={loadingPDF ? 'me-2' : ''}>
                {`${isExecuted ? downloadText : downloadUnsignedText}`}
            </span>
            {loadingPDF && (
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            )}
        </Button>
    );

};

export default DownloadContractPdf;
