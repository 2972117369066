import React, { useState } from 'react';
import BarcodeScannerComponent from './BarcodeScannerComponent';
import Alert from '../alerts/Alert';
import { closeRoundIcon } from '../svg-icons/SvgIcons';
import useTrackEvent, { EVENT_TYPE } from '../track-event/TrackEvent';

interface IVinCodeScannerProps {
    onScan?: (code: string) => void;
    setShowScanner: (code: boolean) => void;
    setResult?: (code: string) => void;
    showScanner: boolean
}

const VinCodeScanner: React.FC<IVinCodeScannerProps> = ({onScan, showScanner, setShowScanner, setResult}) => {
    const {trackEvent} = useTrackEvent();
    const [errorMsg, setErrorMsg] = useState<string>('');

    return (
        <>
            {
                errorMsg ? (
                    <>
                        <Alert show={true}
                               autoHide={false}
                               type={'error'}
                               message={(
                                   <div className={'d-flex justify-content-between'}>
                                       {errorMsg}
                                       <span onClick={() => setShowScanner(false)}>{closeRoundIcon}</span>
                                   </div>
                               )}/>
                    </>
                ) : null
            }

            {showScanner && !errorMsg && (
                <BarcodeScannerComponent onScan={onScanCode}
                                         onOpen={() => {
                                             trackEvent([{
                                                 eventName: EVENT_TYPE.vin_code_scanner_opened,
                                             }]);
                                         }}
                                         onClose={() => {
                                             trackEvent([{
                                                 eventName: EVENT_TYPE.vin_code_scanner_closed
                                             }]);
                                             setShowScanner(false);
                                         }}/>
            )}
        </>
    );

    function onScanCode(data: any) {
        if (data && data?.type !== 'error') {
            let vinCode = data.text;

            // some cars might be imported and we need to remove initial I
            if (vinCode.toLowerCase().startsWith('i')) {
                vinCode = vinCode.substring(1);
            }
            trackEvent([{
                eventName: EVENT_TYPE.vin_code_scanned,
                value: vinCode
            }]);

            onScan?.(vinCode);
            setResult?.(vinCode);
            setShowScanner(false);
        } else {
            setErrorMsg(data.msg);
        }
    }
};

export default VinCodeScanner;
