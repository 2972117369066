import React, { useEffect, useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { IBaseProps } from './IInputTypes';
import { useFormContext, useWatch } from 'react-hook-form';
import { Thumbnails } from '../thumbnails/Thumbnails';
import { FieldTypes } from '../form/FormBuilder';
import InputLabelWithValidation from '../validations/InputLabelWithValidation';
import { IErrorProps } from '../validations/validations';
import { getImageFileMimeTypesForFileTypes } from '../utils';

export interface IFileInputProps extends IBaseProps {
    inputProps?: any;
    accept?: string;
    showThumbnails?: boolean;
    isImageRequired?: boolean;
}

const FileInput: React.FC<IFileInputProps> = ({
                                                  label = '',
                                                  helperText,
                                                  defaultValue,
                                                  inputProps,
                                                  validations,
                                                  control,
                                                  name,
                                                  testId,
                                                  successMsg,
                                                  errorMsg,
                                                  disabled = false,
                                                  isInvalid = false,
                                                  accept = getImageFileMimeTypesForFileTypes(),
                                                  reset,
                                                  isImageRequired,
                                                  showThumbnails = true,
                                                  setValue,
                                                  showOptionalBadge,
                                                  showRequiredBadge
                                              }) => {
    const {getFieldState} = useFormContext();
    const ref: React.MutableRefObject<HTMLInputElement | null> = useRef(null);
    const [photoUrl, setPhotoUrl] = useState(defaultValue);
    let watcher = useWatch({control, name});
    const files: File[] | null = watcher ? (watcher instanceof FileList ? Object.values(watcher) : [watcher]) : [];

    useEffect(() => {
        setPhotoUrl(defaultValue);

        defaultValue && setValue?.(name, defaultValue, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true
        });
    }, [defaultValue]);

    return (
        <Form.Group className="mb-4" data-testid={testId}>
            <InputLabelWithValidation
                label={label}
                helperText={helperText}
                error={getFieldState(name)?.error as IErrorProps}
                fieldType={FieldTypes.FILE}
                showOptionalBadge={showOptionalBadge}
                showRequiredBadge={showRequiredBadge}
            />
            <InputGroup className={'d-flex justify-content-start align-items-center file-upload-input'}>
                {
                    showThumbnails ? (
                        <div className="border-0">
                            <Thumbnails error={getFieldState(name)?.error as IErrorProps}
                                        allowDelete={false}
                                        defaultValue={photoUrl} files={files}/>
                        </div>
                    ) : null
                }
                <div className="d-flex flex-column border-0">
                    <Form.Label htmlFor={name}>{files?.length || photoUrl ? 'Replace' : 'Add photo'}</Form.Label>
                    <Form.Label onClick={(e) => {
                        if (!disabled) {
                            ref.current && (ref.current.value = '');
                            setValue?.(name, '', {
                                shouldValidate: true,
                                shouldDirty: false,
                                shouldTouch: true
                            });
                            setPhotoUrl('');
                        }

                    }}>Remove</Form.Label>
                </div>
                <Form.Control
                    type="file"
                    id={name}
                    ref={ref}
                    className={'file-input-button'}
                    disabled={disabled}
                    isInvalid={isInvalid}
                    multiple={false}
                    accept={accept}
                    onBlur={inputProps.onBlur}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        event.target.value && setValue?.(name, event.target.files, {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true
                        });
                    }}
                />
            </InputGroup>
        </Form.Group>
    );
};

export default FileInput;
