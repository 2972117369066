import React from 'react';
import { IInspection } from '../../lib/interfaces/inspection/inspection';
import { IVehicle } from '../../lib/interfaces/vehicle/vehicle';
import { ConsignmentStatusEnum } from '../../lib/agreement/Agreement';
import { IAvailableDateData } from '../../lib/date-time-slot/DateTimeSlot';
import { Dayjs } from 'dayjs';
import { convertDateToTimezone } from '../../lib/date/DateFormatter';

export const InspectiontContext: React.Context<{ getData?: any; inspectionData?: IInspection; vehicle?: IVehicle; hideLoader?: any; getOverView?: () => void }> = React.createContext({});
export const OverviewContext: React.Context<IOverview> = React.createContext({});
export const AgendaContext: React.Context<IAgenda> = React.createContext<IAgenda>({
    fetchFullData: () => {},
    fetchDayData: () => {},
    workingStartHour: '',
    workingEndHour: '',
    selectedDate: null,
    setSelectedDate: () => {},
    appointmentCapacity: 0,
    weekData: [],
    loadingWeekData: false,
    loadingDayData: false,
    dayDataExist: false,
    dateRange: { from: '', to: '' },
    setDateRange: () => {},
    today: convertDateToTimezone(undefined),
    timezone: undefined,
});

export interface IOverview {
    dealerVehicleId?: string,
    priceListing?: number,
    odometer?: number,
    intakeId?: number,
    vehicleLeadData?: any,
    dealerVehicleData?: any;
    getInProgressStatus?: (item: string) => string;
    getOverView?: () => void;
    getDealerVehicle?: () => void;
    stages?: IOverviewStages;
    showLienWarning?: boolean;
}

export interface IOverviewStages {
    hasUnpublishedChanges: boolean;

    [key: string]: IOverviewStage | any;
}

export interface IOverviewStage {
    appointmentDate?: string;
    completedAt: string;
    isCompleted: boolean;
    requiredPhotosCount?: number;
    uploadedPhotosCount?: number;
    progress?: number;
    requiredSpecificationsCount?: number;
    selectedSpecificationsCount?: number;
    requiredFeaturesCount?: number;
    selectedFeatuesCount?: number;
    contract_parties_count: number;
    contract_signed_count: number;
    currentStep: ConsignmentStatusEnum;
    favoriteCount: number;
    offersCount: number;
    searchesCount: number;
    testDriveCount: number;
    viewsCount: number;
    totalViewsCount: number;
    soldPrice: number;
};

export interface IFormattedDataForADay extends IAvailableDateData {
    appointmentsCount: number,
    blockedSlotsCount: number,
    affectedAppointmentsCount: number
}

export interface IAgenda {
    fetchFullData: () => void,
    fetchDayData: (resetData: boolean, date?: Dayjs) => void,
    workingStartHour: string,
    workingEndHour: string,
    selectedDate: IFormattedDataForADay | null,
    setSelectedDate: (date: IFormattedDataForADay | null) => void;
    appointmentCapacity: number,
    weekData?: IFormattedDataForADay[],
    dayData?: IFormattedDataForADay | null,
    loadingWeekData: boolean,
    loadingDayData: boolean,
    dayDataExist: boolean,
    dateRange: { from: string, to: string },
    setDateRange: (value: { from: string, to: string }) => void,
    today: Dayjs,
    timezone?: string
}

export enum DealerRolesEnum {
    ADMIN = 'admin',
    SALES_MANAGER = 'sales_manager',
    SERVICE_MANAGER = 'service_manager',
    SALES_REP = 'sales_rep',
    SERVICE_REP = 'service_rep',
    FINANCING_REP = 'financing_rep'
}

