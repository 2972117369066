import React from 'react';
import { CloseButton, Row } from 'react-bootstrap';

import { IFieldItemProps } from '../form/FormBuilder';

export interface IFieldsArrayListProps {
    name: string;
    fields: IFieldItemProps[] | undefined;
    fieldsArray: any;
    getFieldComponent?: (field: IFieldItemProps, index: number) => void;
    onRemove?: (index: number) => void;
    showRemoveBtn: boolean;
    showAddBtn: boolean;
}

const FieldsArrayList: React.FC<IFieldsArrayListProps> = ({
                                                              fields,
                                                              name,
                                                              getFieldComponent,
                                                              showRemoveBtn,
                                                              showAddBtn,
                                                              fieldsArray,
                                                              onRemove
                                                          }) => {
    return (
        <div>
            {
                fieldsArray.map((defaultField: any, fieldIndex: number) => {
                    const container: any = [];
                    fields?.forEach((fieldArrItem: any, index) => {
                        if (fieldArrItem.getDisabled) {
                            fieldArrItem.disabled = fieldArrItem.getDisabled(defaultField);
                        }

                        container.push(
                            <div
                                key={`${fieldArrItem.name}-${fieldIndex}`}
                                className={`${fieldArrItem.className ? fieldArrItem.className : ''} ${!showRemoveBtn && showAddBtn && index === 0 && fieldIndex ? 'mt-4' : ''}`}>
                                {
                                    getFieldComponent?.({
                                        ...fieldArrItem,
                                        testId: `${fieldArrItem.name}-${fieldIndex}`,
                                        name: `${name}[${fieldIndex}].${fieldArrItem.name}`,
                                        defaultValue: defaultField?.[fieldArrItem.name]
                                    }, fieldIndex) || null
                                }
                            </div>
                        );
                    });

                    return (
                        <Row
                            key={fieldIndex}
                            className="justify-content-center"
                            style={fieldIndex > 0 ? {
                                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                                paddingTop: '16px'
                            } : undefined}
                        >
                            {fieldIndex > 0 && showRemoveBtn && (
                                <span
                                    onClick={() => onRemove?.(fieldIndex)}
                                    className="d-flex justify-content-end my-4"
                                >
                                  <CloseButton/>
                              </span>
                            )}
                            {container}
                        </Row>
                    );
                })
            }
        </div>
    );
};

export default FieldsArrayList;
