import { UserDispatchProps } from './UserReducerDispatch';
import { IFavorite, INotification, IUser } from '../../../contexts/user/UserContext';
import { updateStorageData } from '../../../contexts/storage/LocalStorageProvider';

export interface IUserStateProps {
    redirectUrl: string;
    user: IUser | null;
    socialPrefillData: IUser | null;
    notifications: INotification[] | undefined;
}

export interface IUserActionProps {
    type: UserDispatchProps;
    data?: any;
    favorite?: IFavorite;
    favoriteId?: number;
    notifications?: INotification[]
    isFullyOnboarded?: boolean;
    redirectUrl?: string;
}

export const initialUserState: IUserStateProps = {
    user: null,
    notifications: [],
    redirectUrl: '',
    socialPrefillData: null
};

export let userId = null;

function UserReducer(state: IUserStateProps, action: IUserActionProps) {
    const {favorite, favoriteId, data, notifications, isFullyOnboarded, redirectUrl} = action;
    let user = {...state.user};
    const favorites: IFavorite[] = user?.favorites ? [...user.favorites] : [];
    let index: number | undefined;

    switch (action.type) {
        case UserDispatchProps.SET_USER:
            userId = data?.id;
            return {...state, user: data};
        case UserDispatchProps.SET_SOCIAL_PREFILL_USER:
            return {...state, socialPrefillData: data};
        case UserDispatchProps.UPDATE_USER:
            return {...state, user: {...user, ...data}};
        case UserDispatchProps.SET_REDIRECT_URL:
            updateStorageData('rdRedirectUrl', redirectUrl || '');
            return {...state, redirectUrl: redirectUrl || ''};
        case UserDispatchProps.REMOVE_REDIRECT_URL:
            updateStorageData('rdRedirectUrl', '');
            return {...state, redirectUrl: ''};
        case UserDispatchProps.ADD_FAVORITE:
            index = favorites?.findIndex(favItem => favItem.listingId === favorite?.listingId);

            if (index === -1) {
                favorite && favorites?.push(favorite);
            }

            user = {...user, favorites};

            return {...state, user};
        case UserDispatchProps.REMOVE_FAVORITE:
            index = favorites?.findIndex(favItem => favItem.listingId === favoriteId);

            if (index !== -1) {
                favorites?.splice(index, 1);
            }

            user = {...user, favorites};

            return {...state, user};
        case UserDispatchProps.UPDATE_USER_ACTIVATION:
            return {
                ...state,
                user: {
                    ...user,
                    dealerProfile: {
                        ...user.dealerProfile,
                        dealer: {
                            ...user?.dealerProfile?.dealer,
                            isFullyOnboarded
                        }
                    }
                }
            };
        default:
            return state;
    }
}

export default UserReducer;
