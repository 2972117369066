import React, { ReactText } from 'react';
import { toast } from 'react-toastify';
import Toast, { ToastBg } from './Toast';

interface ICreateToastProps {
    onClose?: () => void;
    bg: ToastBg;
    heading?: string | JSX.Element;
    content: string | JSX.Element
}

const createToast = ({onClose, bg, content, heading}: ICreateToastProps): ReactText => {
    return (
        toast(<Toast bg={bg} content={content}/>, {
            onClose,
            position: toast.POSITION.TOP_CENTER,
            className: `toastify-${bg}`,
            autoClose: 4000
        })
    );
};

export default createToast;
