import React from 'react';

import { IAgreement, ISection } from '../Agreement';
import { DateFormatter } from '../../date/DateFormatter';
import { checkedCheckboxIcon, checkboxIcon } from '../../svg-icons/SvgIcons';

interface IAcceptedProps {
  parties: Partial<IAgreement['parties']>;
  sectionParties: Partial<ISection['parties']>;
  isForDealer: boolean;
  forceAccepted?: boolean;
}

const Accepted: React.FC<IAcceptedProps> = ({parties, isForDealer, sectionParties, forceAccepted}): JSX.Element => {
  const acceptedParties = parties.filter((p: any) => sectionParties?.find((sp: any) => sp.id === p.id)?.isAccepted);

  return (
    <div className="mt-4">
      <h6 className="accepted-title mb-3"><strong>Accepted ({acceptedParties?.length || 0}/{parties?.length || 0})</strong></h6>
      <ul>
        {
          parties.map(party => {
            const sectionParty = sectionParties?.find((item: any) => item.id === party?.id);
            if (forceAccepted && sectionParty) {
              sectionParty.isAccepted = forceAccepted;
            }
	    
            return (
              <li key={party?.id} style={{display: 'flex', justifyContent: 'space-between'}} className={'mb-2'}>
                <span>
                  { sectionParty?.isAccepted ? checkedCheckboxIcon : checkboxIcon }
                  <span className="ms-2">
                    {party?.name}
                  </span>
                </span>
                {
                sectionParty?.isAccepted && sectionParty?.acceptedAt ? (
                  <DateFormatter date={sectionParty?.acceptedAt} format={'MMM DD/YYYY'}/>
                ) : null
                }
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};

export default Accepted;
