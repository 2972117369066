export enum PermissionsEnum {
    VEHICLE_LIST = 'vehicle.list',
    VEHICLE_READ = 'vehicle.read',
    VEHICLE_MANAGE = 'vehicle.manage',
    VEHICLE_CREATE = 'vehicle.create',
    APPOINTMENT_LIST = 'appointment.list',
    APPOINTMENT_UPDATE = 'appointment.update',
    APPOINTMENT_CREATE = 'appointment.create',
    INTAKE_READ = 'intake.read',
    INTAKE_WRITE = 'intake.write',
    CONSIGNMENT_READ = 'consignment.read',
    CONSIGNMENT_WRITE = 'consignment.write',
    CONTRACT_READ = 'contract.read',
    CONTRACT_WRITE = 'contract.write',
    CONTRACT_SIGN = 'contract.sign',
    INSPECTION_READ = 'inspection.read',
    INSPECTION_QUEUE = 'inspection.queue',
    INSPECTION_WRITE = 'inspection.write',
    REPAIR_READ = 'repair.read',
    REPAIR_WRITE = 'repair.write',
    LISTING_READ = 'listing.read',
    LISTING_WRITE = 'listing.write',
    DOCUMENTS_READ = 'documents.read',
    DOCUMENTS_WRITE = 'documents.write',
    USERS_MANAGE_MANAGERS = 'users.manage-managers',
    USERS_MANAGE_SERVICE_REPS = 'users.manage-service-reps',
    USERS_MANAGE_SALES_REPS = 'users.manage-sales-reps',
    DEALER_BANKING = 'dealer.banking',
    DEALER_MANAGE = 'dealer.manage',
    SERVICE_QUEUE_LIST = 'service-queue.list',
    CLIENTS_LIST = 'clients.list',
    OFFERS_READ = 'offers.read',
    INVENTORY_MANAGE = 'inventory_manage',
    FINANCING_READ = 'financing.read',
    FINANCING_WRITE = 'financing.write',
    MARKETPLACE_READ = 'marketplace.read',
    DATA_DASHBOARD_READ = 'data_dashboard.read',
}
