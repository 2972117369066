import React, {useEffect, useState} from 'react'
import SuccessAlert from './SuccessAlert'
import WarningAlert from './WarningAlert'
import ErrorAlert from './ErrorAlert'

interface IAlertProps {
  show: boolean,
  autoHide?: boolean,
  type: 'success' | 'warning' | 'error';
  message: string | JSX.Element;
  helperText?: string;
  onHide?: () => void;
  timeout?: number;
  className?: string
}

const Alert: React.FC<IAlertProps> = ({show = false, autoHide = true, type, message, helperText, onHide, timeout = 3000, className}) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(()=> {
    setShowAlert(show);
    if (autoHide && show) {
      setTimeout(()=>{
        setShowAlert(false);
        onHide?.();
      }, timeout)
    }
  }, [show]);

  const getAlertElement = () => {
    switch (type) {
      case 'success':
        return <SuccessAlert message={message} helperText={helperText}/>
      case 'warning':
          return <WarningAlert message={message} className={'align-items-center'} helperText={helperText}/>
      case 'error':
        return <ErrorAlert message={message} helperText={helperText}/>
    }
  }

  return (
    <>
      {showAlert && (
        <div className={className} data-testid={'alert-test'}>
          {getAlertElement()}
        </div>
      )}
    </>
  )
}

export default Alert;
