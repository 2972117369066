import React, { useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import * as _ from 'lodash';
import InputMask from 'react-input-mask';

import { IBaseProps, InputTypes } from './IInputTypes';
import styles from './input.module.scss';
import InputLabelWithValidation from '../validations/InputLabelWithValidation';
import { FieldTypes } from '../form/FormBuilder';
import { MaskTypes } from '../validations/validations';
import Password from '../form/Password';
import TextAreaFormControl from './text-area-form-control/TextAreaFormControl';
import CurrencyFormatInput from '../currency-input/CurrencyInput';
import { maskPhoneNumber } from '../utils';

export interface ITextInputProps extends IBaseProps {
    placeholder?: string;
    inputProps?: any;
    customDesign?: boolean;
    mask?: MaskTypes | any;
    maskPlaceholder?: string | null,
    step?: number
}

const TextInput: React.FC<ITextInputProps> = ({
                                                  defaultValue = '',
                                                  label = '',
                                                  type = InputTypes.TEXT,
                                                  helperText,
                                                  placeholder = null,
                                                  inputProps,
                                                  validations,
                                                  prefix,
                                                  suffix,
                                                  name,
                                                  mask,
                                                  step,
                                                  maskPlaceholder,
                                                  disabled = false,
                                                  setValue,
                                                  customDesign = false,
                                                  error,
                                                  showOptionalBadge,
                                                  showRequiredBadge,
                                                  max,
                                                  min,
                                                  testId
                                              }) => {
    const {formState, getValues} = useFormContext();
    const isTextArea = type === InputTypes.TEXTAREA;

    useEffect(() => {
        defaultValue && setValue?.(name, mask ? maskPhoneNumber(defaultValue) : defaultValue, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true
        });
    }, [defaultValue, mask]);

    return (
        type === InputTypes.PASSWORD ? (
            <Password
                label={label}
                error={error}
                inputProps={{
                    className: `${formState?.errors?.[name] ? 'is-invalid' : ''}`,
                    ...inputProps
                }}/>
        ) : type === InputTypes.CURRENCY ? (
                <Form.Group controlId={`currency-format-${name}`} className="form-group mb-3">
                    <InputLabelWithValidation
                        label={label}
                        error={error}
                        validations={validations}
                        helperText={helperText}
                        fieldType={FieldTypes.INPUT}
                        showOptionalBadge={showOptionalBadge}
                        showRequiredBadge={showRequiredBadge}
                    />
                    <CurrencyFormatInput disabled={disabled} inputProps={inputProps} defaultValue={defaultValue}/>
                </Form.Group>
            ) :
            (
                <Form.Group controlId={name}
                            data-testid={testId}
                            className={`${type === InputTypes.HIDDEN ? 'm-0' : 'mb-4'} ${styles.textInput}`}>
                    <InputLabelWithValidation
                        label={label}
                        error={error}
                        validations={validations}
                        helperText={helperText}
                        fieldType={FieldTypes.INPUT}
                        showOptionalBadge={showOptionalBadge}
                        showRequiredBadge={showRequiredBadge}
                    />
                    {isTextArea ? (
                        <TextAreaFormControl
                            name={name}
                            defaultValue={defaultValue}
                            disabled={disabled}
                            maxCharsCount={validations?.maxLength as number}
                            error={error?.type}
                            inputProps={inputProps}
                        />
                    ) : (
                        <InputGroup
                            data-testid={testId}
                            className={`${type === InputTypes.NUMBER ? 'numNoArrows' : ''} ${prefix ? 'input-with-prefix' : suffix ? 'input-with-suffix' : 'plain-control'} ${customDesign ? 'customGroupInput' : ''}`}>
                            {
                                mask ? (
                                    // @ts-ignore
                                    <InputMask
                                        mask={mask}
                                        maskPlaceholder={maskPlaceholder}
                                        className={error?.type ? 'is-invalid' : ''}
                                        defaultValue={maskPhoneNumber(defaultValue)}
                                        disabled={disabled}
                                        onChange={(event: any) => {
                                            inputProps.onChange(event);
                                        }}
                                        onBlur={inputProps.onBlur}

                                        {..._.omit(validations, ['validate'])}>
                                        <Form.Control
                                            name={inputProps.name}
                                            ref={inputProps.ref}
                                        />
                                    </InputMask>
                                ) : (
                                    <Form.Control
                                        max={max}
                                        min={min}
                                        step={step || 1}
                                        name={name}
                                        formNoValidate
                                        className={error?.type ? 'is-invalid' : ''}
                                        placeholder={placeholder}
                                        defaultValue={defaultValue}
                                        disabled={disabled}
                                        type={type}
                                        onInput={(e: React.SyntheticEvent<HTMLInputElement>) => {
                                            const target = e.target as HTMLInputElement;
                                            const trimmedTargetValue = target.value.trim();

                                            if (validations?.maxLength && trimmedTargetValue.length > validations?.maxLength) {
                                                target.value = target.value.slice(0, validations?.maxLength);
                                            }
                                        }}
                                        {...(type === InputTypes.NUMBER ? {onWheel: (e: any) => e.target.blur()} : {})}
                                        {...inputProps}
                                        onBlur={(ev) => {
                                            ev.target.value = ev.target.value.trim();
                                            inputProps?.onBlur(ev);
                                        }}
                                    />
                                )
                            }
                            {
                                prefix ? (
                                    <InputGroup.Text id={`prefix-${name}`}>{prefix}</InputGroup.Text>
                                ) : null
                            }
                            {
                                suffix ? (
                                    <InputGroup.Text id={`prefix-${name}`}>{suffix}</InputGroup.Text>
                                ) : null
                            }
                        </InputGroup>
                    )}
                </Form.Group>
            )
    );
};

export default TextInput;
