import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import CreatableSelect from 'react-select/creatable';
import { IBaseProps } from '../input/IInputTypes';
import styles from './customSelect.module.scss';
import InputLabelWithValidation from '../validations/InputLabelWithValidation';
import { FieldTypes } from '../form/FormBuilder';

export interface ISelectOptions {
  value: string | number;
  label: string;
  disabled?: boolean;
  [key: string]: any;
}

export interface ISelectProps extends IBaseProps {
  selectProps?: any;
  isSearchable?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  isCreatable?: boolean;
  isLoading?: boolean;
  onCreateNewOption?: (name: string, onAddCallback: Function) => void;
  options?: ISelectOptions[];
  valueProp?: 'value' | 'label';
  onChange?: (event: any) => void
}

const CustomSelect: React.FC<ISelectProps> = ({
                          options = [], defaultValue, label, name, placeholder,
                          selectProps, helperText, validations, testId,
                          disabled = false, setValue, valueProp = 'value',
                          isLoading = false, isSearchable = true, isClearable = true, isMulti = false,
                          isCreatable = false, onCreateNewOption, error, showOptionalBadge, showRequiredBadge, onChange
                        }) => {
  const [selectedValue, setSelectedValue] = useState<any>();
  const selectedRef: React.MutableRefObject<any> = useRef(null);

  useEffect(() => {
    if ((isMulti ? !selectedRef.current : defaultValue) && options?.length) {
      let selected: any = '';
      if (defaultValue) {
        selected = isMulti ? options.filter(opt => defaultValue?.includes(`${opt.value}`)) : [options.find(opt => opt.value === defaultValue)];
        selectedRef.current = selected;
      }

      setSelectedValue(selected);
      setValue?.(name, isMulti ? selected : (selected?.[0]?.[valueProp] || ''), {
        shouldValidate: false,
        shouldDirty: false,
        shouldTouch: false
      });
    }
  }, [options, defaultValue]);

  useEffect(() => {
    if (!selectProps.value) {
      setSelectedValue(selectProps.value || '');
      setValue?.(name, selectProps.value || '', {
        shouldValidate: false,
        shouldDirty: false,
        shouldTouch: false
      });
    }
  }, [selectProps.value]);

  return (
    <Form.Group controlId={name} className={`${styles.customSelect} mb-4`} data-testid={testId}>
      <InputLabelWithValidation
        label={label || ''}
        error={error}
        helperText={helperText}
        fieldType={FieldTypes.SELECT}
        showOptionalBadge={showOptionalBadge}
        showRequiredBadge={showRequiredBadge}
      />
      {!isCreatable ? (
        <Select
          classNamePrefix={`${error?.type ? 'invalid' : ''} custom-select`}
          options={options}
          isSearchable={isSearchable}
          isClearable={isClearable}
          placeholder={placeholder}
          isMulti={isMulti}
          isDisabled={disabled}
          getOptionValue={(option: ISelectOptions) => option[valueProp]}
          noOptionsMessage={() => 'No items'}
          {...selectProps}
          value={selectedValue}
          onChange={(newValue: any) => {
            setSelectedValue(newValue);
            setValue?.(name, isMulti ? newValue : newValue?.[valueProp], {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true
            });
            onChange?.(newValue);
          }}
          {...validations}
        />) : (
          <CreatableSelect
            classNamePrefix={`${error?.type ? 'invalid' : ''} custom-select`}
            options={options}
            isSearchable={isSearchable}
            isClearable={isClearable}
            isDisabled={disabled}
            isLoading={isLoading}
            {...selectProps}
            value={selectedValue}
            getOptionValue={(option: ISelectOptions) => option[valueProp]}
            onChange={(newValue: any) => {
              setSelectedValue(newValue);
              setValue?.(name, isMulti ? newValue : newValue?.[valueProp], {
                shouldValidate: true,
                shouldDirty: false,
                shouldTouch: false
              });
            }}
            onCreateOption={(input) => onCreateNewOption?.(input, (newValue: any) => {
              setSelectedValue(newValue);
              setValue?.(name, isMulti ? newValue : newValue?.[valueProp], {
                shouldValidate: true,
                shouldDirty: false,
                shouldTouch: false
              });
            })}
            {...validations}
          />
        )
      }
    </Form.Group>
  );
};

export default CustomSelect;
