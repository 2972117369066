import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import InputLabelWithValidation from '../validations/InputLabelWithValidation';
import { IErrorProps } from '../validations/validations';
import { bigEyeIcon, bigEyeSlashIcon } from '../svg-icons/SvgIcons';

interface IPassword {
    label?: string | JSX.Element;
    error?: IErrorProps;
    inputProps: any;
}

const Password: React.FC<IPassword> = ({label, error, inputProps}) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <Form.Group className="mb-3">
            <InputLabelWithValidation
                label={label}
                error={error}
            />
            <InputGroup className="input-with-suffix password">
                <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    defaultValue={''}
                    {...inputProps}
                />

                <InputGroup.Text onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    e.preventDefault();

                    setShowPassword((state: boolean) => !state);
                }}>
                    {showPassword ? bigEyeIcon : bigEyeSlashIcon}
                </InputGroup.Text>
            </InputGroup>
        </Form.Group>
    );
};

export default Password;
