import { useRouter } from 'next/router';
import Link from 'next/link';
import { CSSProperties, useContext } from 'react';
import { InitialDataContext } from '../../contexts/init/InitialDataContext';

interface INavLink {
    href: string;
    exact?: boolean;
    disabled?: boolean;
    className?: string;
    style?: CSSProperties;
    children?: JSX.Element | string;
    onClick?: React.MouseEventHandler;
    renderChildren?: (isActive: boolean) => JSX.Element | null;
}

function NavLink({href, exact, children, renderChildren, disabled = false, ...props}: INavLink) {
    const {host} = useContext(InitialDataContext)
    const {pathname} = useRouter();
    const isActive = exact ? pathname === href : pathname.startsWith(href);
    if (!props.className) {
        props.className = '';
    }

    props.className += isActive ? ' navi-link active-nav d-flex align-items-center' : ' navi-link inactive-nav d-flex align-items-center';
    props.className += disabled ? ' disabled' : '';

    return (
        <Link legacyBehavior href={disabled ? '' : host + href}>
            <a {...props}>
                {renderChildren ? renderChildren(isActive) : children}
            </a>
        </Link>
    );
}

export { NavLink };
