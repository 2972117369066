import { PropsWithChildren, useState } from 'react';
import { IStorage, LocalStorageContext } from './LocalStorageContext';
import { getLocalStorage } from '../../lib/utils';

const rdStorageKey = 'rd_storage';

interface ILocalStorageProvider {

}

const LocalStorageProvider: React.FC<PropsWithChildren<ILocalStorageProvider>> = ({children}) => {
    const [storage, setStorage] = useState<IStorage>(getStorage());

    return (
        <LocalStorageContext.Provider value={{...storage, updateStorage}}>
            {children}
        </LocalStorageContext.Provider>
    );

    function updateStorage(storageKey: keyof IStorage, value: string) {
        setStorage(updateStorageData(storageKey, value));
    }
};

export function getStorage(): IStorage {
    let parsedStorage: IStorage;
    try {
        parsedStorage = JSON.parse(getLocalStorage()?.getItem(rdStorageKey) || '');
    } catch (e) {
        parsedStorage = {};
    }

    return parsedStorage;
}

export function updateStorageData(storageKey: keyof IStorage, value: string) {
    const storage = {
        ...getStorage(), [storageKey]: value
    };
    getLocalStorage()?.setItem(rdStorageKey, JSON.stringify(storage));

    return storage;
}

export default LocalStorageProvider;