import React from 'react';

interface IInitialDataContext {
    host?: string;
    stripePublicKey?: string;
    googlePlacesApiKey?: string;
    r22Enabled?: boolean;
    posthogApiKey?: string;
    posthogFeatureFlags?: {[key: string]: string;}
}

export const InitialDataContext: React.Context<IInitialDataContext> = React.createContext({});
