import dayjs, { Dayjs } from 'dayjs';

import * as utc from 'dayjs/plugin/utc';
import * as weekday from 'dayjs/plugin/weekday';
import * as isToday from 'dayjs/plugin/isToday';
import * as timezone from 'dayjs/plugin/timezone';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as updateLocale from 'dayjs/plugin/updateLocale';
import * as localeData from 'dayjs/plugin/localeData';
import * as duration from 'dayjs/plugin/duration';
import { useContext } from 'react';
import { UserContext } from '../../contexts/user/UserContext';

dayjs.extend(utc as any);
dayjs.extend(weekday as any);
dayjs.extend(isToday as any);
dayjs.extend(timezone as any);
dayjs.extend(advancedFormat as any);
dayjs.extend(relativeTime as any);
dayjs.extend(updateLocale as any);
dayjs.extend(localeData as any);
dayjs.extend(duration as any);

dayjs.updateLocale('en', {
    // weekdays: [
    //     'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
    // ],
    // weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    // weekdaysMin: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
});

export interface IDateFormatterData {
    date?: any;
    format?: string;
}

const relativeTimeObj = {
    future: 'in %s',
    past: '%s ago',
    s: '1s',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmos',
    y: '1y',
    yy: '%dy'
};

export const DateFormatter: React.FC<IDateFormatterData> = ({
                                                                date = dayjs(),
                                                                format = 'YYYY MM DD hh:mma z'
                                                            }): JSX.Element => {
    const {timezone} = useContext(UserContext);
    const localTimezone = timezone || guessTimezone();

    return (
        <>
            {date ? dayjs(date).tz(localTimezone).format(format) : 'Datetime not selected'}
        </>
    );
};

export const convertDateToTimezone = (timezone: string | undefined, date: string | number | Date | Dayjs = dayjs()) => {
    return dayjs(date).tz(timezone || guessTimezone());
};

export const initDateWithTimezone = (timezone: string | undefined, date: string | number | Date | Dayjs = dayjs()) => {
    return dayjs.tz(date, timezone || guessTimezone());
};

export const formatDuration = (seconds: number, format = 'H[h] m[m] s[s]') => {
    return dayjs.duration(seconds, 'seconds').format(format)
    .replace(/\b0y\b/, '')
    .replace(/\b0m\b/, '')
    .replace(/\b0d\b/, '')
    .replace(/\b0h\b/, '');
};

export const dateFromNow = (date: string | number | Date | Dayjs, suffix = false) => {
    dayjs.updateLocale('en', {relativeTime: relativeTimeObj});
    return dayjs.utc(date).fromNow(suffix);
};

export const dateDiff = (dateFrom: string | number | Date | Dayjs, dateTo: string | number | Date | Dayjs, suffix = false) => {
    dayjs.updateLocale('en', {relativeTime: relativeTimeObj});
    return dayjs.utc(dateFrom).to(dayjs.utc(dateTo), suffix);
}

export const guessTimezone = () => dayjs.tz.guess();
export const getWeekNameByIndex = (index: number, isAbbreviation?: boolean) => {
    if (isAbbreviation) {
        dayjs.updateLocale('en', {
            weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        });
    } else {
        dayjs.updateLocale('en', {
            weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        });
    }
    return dayjs.weekdays()[index];
};
export const getWeekdaysMin = () => dayjs.weekdaysMin();

export const isDateExpired = (date:string, format = 'YYYY-MM-DD') => {
    if (!date) {
        return false;
    }

    return dayjs().isAfter(dayjs(date, format), 'day');
}
