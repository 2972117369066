import React from 'react';
import { Form, ListGroup } from 'react-bootstrap';

import { CheckboxTypes, IBaseProps } from './IInputTypes';
import styles from './input.module.scss';
import InputLabelWithValidation from '../validations/InputLabelWithValidation';
import { FieldTypes } from '../form/FormBuilder';

export interface ICheckboxProps extends IBaseProps {
    checkboxProps?: any;
    isCustomRadio?: boolean;
    showTopLabel?: boolean;
}

const Checkbox: React.FC<ICheckboxProps> = ({
                                                defaultValue = false,
                                                label = '',
                                                type = CheckboxTypes.CHECKBOX,
                                                helperText,
                                                checkboxProps,
                                                validations,
                                                showTopLabel = true,
                                                name,
                                                groupOptions,
                                                disabled = false,
                                                isInvalid = false,
                                                isCustomRadio = false,
                                                error,
                                                showOptionalBadge,
                                                showRequiredBadge
                                            }) => {

    return (
        <Form.Group className="mb-4">
            <div className="d-flex flex-column">
                <InputLabelWithValidation
                    label={label}
                    error={error}
                    helperText={helperText}
                    fieldType={FieldTypes.CHECKBOX}
                    showOptionalBadge={showOptionalBadge}
                    showRequiredBadge={showRequiredBadge}
                />
            </div>
            <ListGroup className={styles.checkbox}>
                {groupOptions?.map((groupOption, index) => {
                    return (
                        <ListGroup.Item
                            data-testid={`group-option-${groupOption.value}`}
                            key={index}
                            className={`${error ? 'invalid' : ''} p-0`}
                        >
                            <Form.Check
                                type={type}
                                className={isCustomRadio ? 'custom-radio-wrapper' : ''}
                                id={`${name}-${index}`}
                                label={groupOption.text}
                                isInvalid={isInvalid}
                                value={groupOption.value}
                                defaultChecked={
                                    typeof defaultValue === 'object'
                                        ? defaultValue[groupOption.value]
                                        : groupOption.value === defaultValue
                                }
                                disabled={disabled || groupOption.disabled}
                                {...checkboxProps}
                            >
                                <Form.Check.Input
                                    type={type}
                                    className={isCustomRadio ? 'custom-radio' : ''}
                                    id={`${name}-${index}`}
                                    label={groupOption.text}
                                    isInvalid={isInvalid}
                                    value={groupOption.value}
                                    defaultChecked={
                                        typeof defaultValue === 'object'
                                            ? defaultValue[groupOption.value]
                                            : groupOption.value === defaultValue
                                    }
                                    disabled={disabled || groupOption.disabled}
                                    {...checkboxProps}
                                />
                                <Form.Check.Label>
                                    {isCustomRadio ? groupOption.customLabel : groupOption.text}
                                    {groupOption.description && (
                                        <span style={{display: 'block', fontSize: '14px', fontWeight: 400}}>
                                            {groupOption.description}
                                        </span>
                                    )}
                                </Form.Check.Label>
                            </Form.Check>
                        </ListGroup.Item>
                    );
                }) || (
                    <Form.Check
                        type={type}
                        id={name}
                        label={label}
                        isInvalid={isInvalid}
                        defaultChecked={defaultValue}
                        disabled={disabled}
                        {...checkboxProps}
                    />
                )}
            </ListGroup>
        </Form.Group>
    );
};

export default Checkbox;
