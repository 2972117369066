import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import * as _ from 'lodash';
import { getTrackBackground, Range } from 'react-range';

import { IBaseProps, InputTypes } from './IInputTypes';
import styles from './input.module.scss';
import InputLabelWithValidation from '../validations/InputLabelWithValidation';
import { formatCurrency } from '../currency-formatter/CurrencyFormatter';
import { ErrorTypes, IErrorProps } from '../validations/validations';

export interface IRangeSliderProps extends IBaseProps {
    placeholder?: string;
    inputProps?: any;
    customDesign?: boolean;
    step?: number;
    sliderFillColors?: string[];
    thumbColor?: string
}

const RangeSlider: React.FC<IRangeSliderProps> = ({
                                                      defaultValue = 1000,
                                                      label = '',
                                                      type = InputTypes.NUMBER,
                                                      helperText,
                                                      placeholder,
                                                      inputProps,
                                                      validations,
                                                      prefix,
                                                      suffix,
                                                      name,
                                                      disabled = false,
                                                      isInvalid = false,
                                                      setValue,
                                                      step = 100,
                                                      min = 0,
                                                      max = 1000,
                                                      error,
                                                      sliderFillColors = ['#181818', 'rgba(0, 0, 0, 0.2)'],
                                                      thumbColor = '#181818'
                                                  }) => {
    const [sliderValue, setSliderValue] = useState<number[]>([defaultValue]);
    const [inputValue, setInputValue] = useState<number | string>(defaultValue);
    const [customError, setCustomError] = useState<IErrorProps | undefined>(undefined);

    const stepValue = max <= 1000 ? 10 : step;
    let minValue = min < stepValue ? +min : Math.ceil(+min / 100) * 100;
    minValue >= max && (minValue = +min);

    useEffect(() => {
        setValue?.(name, inputValue, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true
        });
    }, [defaultValue, inputValue]);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: document.getElementById('range-slider')?.offsetTop,
                behavior: 'smooth'
            });
        }, 400);
    }, []);

    return (
        <Form.Group className={`mb-4 ${styles.textInput}`} id="range-slider">
            <InputLabelWithValidation
                label={label}
                helperText={helperText}
                error={error || customError}
            />
            <InputGroup
                className={`${type === InputTypes.NUMBER ? 'numNoArrows' : ''} ${prefix ? 'input-with-prefix' : suffix ? 'input-with-suffix' : 'plain-control'}`}>
                <Form.Control data-testid={'range-amount'}
                              id="range-slider-input"
                              placeholder={placeholder}
                              value={inputValue}
                              disabled={disabled}
                              isInvalid={isInvalid}
                              type={type}
                              min={minValue}
                              max={max}
                              {...inputProps}
                              {..._.omit(validations, ['validate'])}
                              onBlur={(e) => {
                                  if (!inputValue || (inputValue && (inputValue > +max || inputValue < minValue))) {
                                      setInputValue(+inputValue > +max ? +max : minValue);
                                      setSliderValue([+inputValue > +max ? +max : +minValue]);
                                      setCustomError(undefined);
                                      return;
                                  }

                                  if (+inputValue % stepValue) {
                                      let coercedValue = Math.ceil(+inputValue / 100) * 100;
                                      coercedValue >= max && (coercedValue = +inputValue > max ? +max : +inputValue);
                                      setInputValue(coercedValue);
                                      setSliderValue([coercedValue]);
                                      setCustomError(undefined);
                                  }

                                  setSliderValue([+inputValue]);
                              }}
                              onChange={(e) => {
                                  setInputValue(e.target.value);
                                  if (!e.target.value) {
                                      setCustomError({
                                          type: ErrorTypes.REQUIRED,
                                          message: '',
                                          ref: inputProps?.ref
                                      });
                                  } else if (e.target.value && (+e.target.value < minValue || +e.target.value > +max)) {
                                      setCustomError({
                                          type: ErrorTypes.CUSTOM,
                                          message: `Value must be between ${formatCurrency(+minValue)} and ${formatCurrency(+max)}`,
                                          ref: inputProps?.ref
                                      });
                                  } else {
                                      setCustomError(undefined);
                                  }
                              }}
                />
                {
                    prefix ? (
                        <InputGroup.Text id={`prefix-${name}`}>{prefix}</InputGroup.Text>
                    ) : null
                }
                {
                    suffix ? (
                        <InputGroup.Text id={`prefix-${name}`}>{suffix}</InputGroup.Text>
                    ) : null
                }
            </InputGroup>
            <div className="my-3">
                <Range
                    step={stepValue}
                    min={minValue}
                    max={+max}
                    values={sliderValue}
                    onChange={(value) => {
                        setSliderValue(value);
                        setInputValue(value[0]);
                    }}
                    renderTrack={({props, children}) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                                ...props.style,
                                height: '32px',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                width: '100%'
                            }}
                        >
                            <div
                                ref={props.ref}
                                style={{
                                    position: 'relative',
                                    height: '8px',
                                    width: '100%',
                                    borderRadius: '20px',
                                    background: getTrackBackground({
                                        values: sliderValue,
                                        colors: sliderFillColors,
                                        min: +minValue,
                                        max: +max
                                    }),
                                    alignSelf: 'center'
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({props}) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '28px',
                                width: '28px',
                                border: '4px solid #FFFFFF',
                                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                borderRadius: '50%',
                                backgroundColor: thumbColor,
                                top: 0
                            }}
                        />
                    )}
                />
                <div className="d-flex align-items-center justify-content-between">
                    <span>{`${formatCurrency(minValue)}`}</span>
                    <span>{`${formatCurrency(Math.round(+max))}`}</span>
                </div>
            </div>
        </Form.Group>
    );
};

export default RangeSlider;
