import { useContext, useEffect, useState } from 'react';

import { forgetToken, getToken, validateToken } from '../token/token';
import { useRouter } from 'next/router';
import { LocalStorageContext } from '../../contexts/storage/LocalStorageContext';

export function useLoggedIn(): { isLoggedIn: boolean; logout: () => void; updateIsLoggedIn: (loggedIn: boolean) => void } {
    const {updateStorage} = useContext(LocalStorageContext);
    const [isLoggedIn, setIsLoggedIn] = useState(checkToken());
    const location = useRouter();

    useEffect(() => setIsLoggedIn(checkToken()), [location.pathname]);

    return {isLoggedIn, logout, updateIsLoggedIn};

    function checkToken(): boolean {
        let loggedIn: boolean = false;
        const token = getToken();

        if (token) {
            try {
                loggedIn = validateToken(token);

                if (!loggedIn) {
                    logout();
                }
            } catch (err) {
                loggedIn = false;
                logout();
            }
        }

        return loggedIn;
    }

    function updateIsLoggedIn(loggedIn: boolean) {
        setIsLoggedIn(loggedIn);
    }

    function logout() {
        forgetToken();
        setIsLoggedIn(false);
        updateStorage?.('userType', '');
    }
}
