import React from 'react'
import { Form } from 'react-bootstrap';
import InputLabelWithValidation from '../validations/InputLabelWithValidation';

import { CheckboxTypes, IBaseProps } from './IInputTypes';
import styles from './input.module.scss';

export interface IColorPickerProps extends IBaseProps {
  checkboxProps?: any;
}

const ColorPicker: React.FC<IColorPickerProps> = ({
                                                    defaultValue = false, label = '', type = CheckboxTypes.RADIO,
                                                    helperText, checkboxProps, validations, name, groupOptions,
                                                    disabled = false, isInvalid = false, error
                                                  }) => {

  return (
    <Form.Group className="mb-4">
      <div className='d-flex flex-column'>
        <InputLabelWithValidation
          label={label}
          helperText={helperText}
          error={error}
        />
      </div>
      <div className={styles.colorPicker}>
        <div className={`${error?.type ? 'is-invalid' : ''} wrapper`} data-testid={"vehicle-colors"}>
          {groupOptions?.length ? groupOptions?.map(o => {
            return (
              <div className={`${error?.type ? 'is-invalid' : ''} color-picker-item`} key={o.value}>
                <Form.Check.Input
                  type={type}
                  id={`${name}-${o.text}`}
                  isInvalid={isInvalid}
                  value={o.value}
                  defaultChecked={defaultValue === o.value}
                  disabled={disabled}
                  {...checkboxProps}
                />
                <Form.Check.Label htmlFor={`${name}-${o.text}`}>
                  {o?.hex && <div
                        className={`color-circle ${['#fff', '#ffffff'].includes(o.hex.toLowerCase()) ? 'white' : ''}`}
                        style={{
                            background: o.hex
                        }}
                    />}
                  {o.text}
                </Form.Check.Label>
              </div>
            )
          }) : null}
        </div>
      </div>
    </Form.Group>
  );
}

export default ColorPicker;
