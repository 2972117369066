import React from 'react';
import { acceptIcon } from '../svg-icons/SvgIcons';

interface ISuccessAlertProps {
  message: string
}

const SuccessAlert: React.FC<any> = ({message}) => {
  return (
    <div
        data-testid={'alert-test'}
        style={{
            background: '#ECFCCB',
            border: '1px solid #BEF264',
            borderRadius: '7px',
            minHeight: '64px',
            padding: '24px 16px',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '24px',
            color: '#000000',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.08), 0px 1px 0px rgba(0, 0, 0, 0.03)'
        }}
        className="d-flex align-items-start">
        <span className="me-3">{acceptIcon}</span>
        {message}
    </div>
  )
}

export default SuccessAlert;