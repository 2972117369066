import React from 'react';
import { Form } from 'react-bootstrap';

import { FieldTypes } from '../form/FormBuilder';
import { formErrorIcon } from '../svg-icons/SvgIcons';
import { getErrorMessageByType } from './validations';
import styles from './inputLabelWithValidation.module.scss';
import { IValidationProps } from '../input/IInputTypes';

interface IInputLabelWithValidation {
    label?: string | JSX.Element,
    error?: any,
    fieldType?: FieldTypes,
    showOptionalBadge?: boolean,
    showRequiredBadge?: boolean,
    validations?: IValidationProps;
    helperText?: string | JSX.Element
}

const InputLabelWithValidation: React.FC<IInputLabelWithValidation> = ({
                                                                           label,
                                                                           error,
                                                                           fieldType,
                                                                           showOptionalBadge,
                                                                           showRequiredBadge,
                                                                           helperText,
                                                                           validations
                                                                       }) => {
    return (
        <div className={styles.inputLabelWithValidation}>
            {error?.type ? (
                <div className="error-wrapper">
                    <div className="error-label">
                        {label && <span className="d-flex align-items-center me-2">{formErrorIcon}</span>}
                        <span className="label">{label}</span>
                    </div>
                    <span className="error-message">
            {error.message || getErrorMessageByType(error, label, fieldType, validations)}
          </span>
                </div>
            ) : label || showOptionalBadge || showRequiredBadge ? (
                <>
                    <Form.Label className={`w-100 ${helperText ? 'mb-1' : ''}`}>
                        {showOptionalBadge || showRequiredBadge ? (
                            <div className="label-with-badge">
                                <span className="label">{label}</span>
                                <span className={`badge ${showRequiredBadge ? 'required' : ''}`}/>
                            </div>
                        ) : label}
                    </Form.Label>
                    {helperText && (
                        <span className="helper-text">
              {helperText}
            </span>
                    )}
                </>
            ) : null}
        </div>
    );
};

export default InputLabelWithValidation;
