import { MethodData, useAxios } from '../custom-axios/CustomAxios';
import { getTrackEventPath } from '../api/Api';
import { getUniqueSessionId } from '../utils';

export enum EVENT_TYPE {
    listing_view = 'listing_view',
    listing_search = 'listing_search',
    vin_code_scanner_opened = 'vin_code_scanner_opened',
    vin_code_scanner_closed = 'vin_code_scanner_closed',
    vin_code_scanned = 'vin_code_scanned',
    vin_code_typed = 'vin_code_typed',
    onboarding_agreement_view = 'onboarding_agreement_view'
}

export interface TrackEvent {
    eventName: EVENT_TYPE,
    contextId?: number,
    value?: string,
    sessionId?: string | null;
}

const useTrackEvent = () => {
    const {loading, fetch, data} = useAxios();

    function trackEvent(events: TrackEvent[]) {
        events.forEach(event => {
            if (!event.sessionId) {
                event.sessionId = getUniqueSessionId();
            }
        });

        fetch(getTrackEventPath, {method: MethodData.POST, body: {events}});
    }

    return {trackEvent, data, loading};
};

export default useTrackEvent;
