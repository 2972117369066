export const en = {
    'translation': {
        'user_name': 'User name: ',
        'dashboard': 'Dashboard',
        'agenda': 'Agenda',
        'pipeline': 'Pipeline',
        'vehicles': 'Vehicles',
        'clients': 'Clients',
        'notifications': 'Notifications',
        'marketplace': 'Marketplace',
        'user': 'User',
        'profile': 'Profile',
        'dealership_info': 'Dealership info',
        'operating_hours': 'Operating hours',
        'integrations': 'Integrations',
        'marketplace_cross_listing': 'Marketplace Cross Listing',
        'lead-generation': 'Lead Generation',
        'members': 'Members',
        'billing': 'Billing',
        'settings': 'Settings',
        'reports': 'Reports',
        'deactivate_account': 'Deactivate account',
        'deactivate_dealership': 'Deactivate dealership',
        'other': 'Other',
        'submit': 'Submit',
    }
};
