import React, { ErrorInfo, PropsWithChildren } from 'react';
import { Container } from 'react-bootstrap';

import { EmptyState } from '../empty-state/empty-state';
import { getWindow } from '../utils';
import Link from 'next/link';
import { userId } from '../reducer/user/UserReducer';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IErrorBoundaryProps {
    pathname: string;
}

interface IErrorBoundaryState {
    error: ErrorInfo | null;
}

declare let _LTracker: any;

export class ErrorBoundary extends React.Component<PropsWithChildren<IErrorBoundaryProps>, IErrorBoundaryState> {
    constructor(props: IErrorBoundaryProps) {
        super(props);
        this.state = {
            error: null
        };
    }

    static getDerivedStateFromError(error: IErrorBoundaryState) {
        // Update state so the next render will show the fallback UI.
        return {error: error};
    }

    componentDidUpdate(prevProps: IErrorBoundaryProps, prevState: IErrorBoundaryState) {
        if (prevProps.pathname !== this.props.pathname) {
            this.setState({error: null});
        }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        console.log('componentDidCatch', error, errorInfo);
        _LTracker.push({
            category: 'BrowserJsException',
            exception: {
                message: error.message,
                url: getWindow()?.location.href,
                stack: error ? error.stack : 'n/a',
                userId,
                userAgent: getWindow()?.navigator?.userAgent
            }
        });
    }

    render() {
        if (this.state.error) {
            // You can render any custom fallback UI
            return (
                <Container>
                    <div style={{
                        minHeight: 'calc(100vh - 453px)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <EmptyState title={'Oops. This page had a problem!'}
                                    text={<p>
                                        <p>Hm, there's an issue with your browser. Please reset your browser and try
                                            again.</p>
                                        <p>If this issue persists even after rebooting your device, please contact our
                                            support <Link legacyBehavior href={'/contact-form?type=technical_issues'}>here</Link>.</p>
                                    </p>}
                        />
                    </div>
                </Container>
            );
        }

        return this.props.children;
    }
}
