import React, { useContext } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { MethodData, useAxios } from '../../custom-axios/CustomAxios';
import { acceptContractBySection } from '../../api/Api';
import { JointContext } from '../../../contexts/consignment/JointContext';
import { IConsignment } from '../../interfaces/consignment/consignment';
import { UserContext } from '../../../contexts/user/UserContext';
import { PermissionsEnum } from '../../permissions/Permissions';
import { VehicleStagesEnum } from '../../interfaces/vehicle/vehicle';

interface IAcceptedProps {
    sectionId: string;
    contractId: number;
    onAccept: (data: any) => void;
    forceDisable?: boolean;
    vehicleStage: VehicleStagesEnum | undefined;
}

const Accept: React.FC<IAcceptedProps> = ({sectionId, contractId, onAccept, vehicleStage, forceDisable = false}): JSX.Element => {
    const {canEditVehicle} = useContext(UserContext);
    const disableSignConsignment = forceDisable || vehicleStage && !canEditVehicle?.(vehicleStage);
    const {signToken} = useContext(JointContext);
    const {fetch: acceptSection, loading, data, error} = useAxios();

    return (
        <div>
            <Button
                disabled={disableSignConsignment || loading}
                className={'standardButton btn-outline-dark'}
                variant="secondary"
                onClick={onSectionAccept}>
                    <span className={loading ? 'me-2' : ''}>Accept</span>

                {loading && (
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                )}
            </Button>
            {error ? (
                <Alert className={'mt-3'} variant={'danger'}>
                    {error.message}
                </Alert>
            ) : null}
        </div>
    );

    function onSectionAccept() {
        acceptSection(acceptContractBySection(contractId, signToken), {
            method: MethodData.PUT,
            body: {sectionId}
        }).then((json: IConsignment) => onAccept(json));
    }
};

export default Accept;
