import React from 'react';
import { whiteWarningIcon } from '../svg-icons/SvgIcons';

interface IErrorAlertProps {
    message: string | JSX.Element;
    helperText?: string
}

const ErrorAlert: React.FC<IErrorAlertProps> = ({message, helperText}) => {
  return (
    <div
      className='d-flex align-items-start'
      style={{
        background: '#CC2C00',
        borderRadius: '8px',
        padding: '24px 16px'
      }}>
      <div className='me-3'>
        {whiteWarningIcon}
      </div>
      <div className='d-flex flex-column' style={{flex: 1}}>
        <div
          className={`${helperText ? 'mb-2' : ''}`}
          style={{
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '24px',
            color: '#FFFFFF'
          }}>
            {message}
        </div>
        {helperText && <span style={{color: '#FFFFFF'}}>{helperText}</span>}
      </div>
    </div>
  )
}

export default ErrorAlert;