import { useContext, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';

import { scanIcon } from '../../../../../lib/svg-icons/SvgIcons';
import FormBuilder, { FieldTypes } from '../../../../../lib/form/FormBuilder';
import VinCodeScanner from '../../../../../lib/scanner/VinCodeScanner';
import { UserContext } from '../../../../../contexts/user/UserContext';
import { PermissionsEnum } from '../../../../../lib/permissions/Permissions';
import { validateVinCode } from '../../../../../lib/validations/validations';
import { VehicleStagesEnum } from '../../../../../lib/interfaces/vehicle/vehicle';
import useTrackEvent, { EVENT_TYPE } from '../../../../../lib/track-event/TrackEvent';

interface IIntakeVehicleVinProps {
    onVinScan: Function;
    vinDecodeError: any;
    isVinTypeMode?: boolean
    vehicleStage?: VehicleStagesEnum
}

const VehicleVin: React.FC<IIntakeVehicleVinProps> = ({
                                                          onVinScan,
                                                          vinDecodeError,
                                                          isVinTypeMode = false,
                                                          vehicleStage
                                                      }) => {
    const {trackEvent} = useTrackEvent();
    const {checkPermission, canEditVehicle} = useContext(UserContext);
    const disableWriteIntake = !checkPermission?.(PermissionsEnum.INTAKE_WRITE) || (vehicleStage && !canEditVehicle?.(vehicleStage));
    const [isTypeVin, setIsTypeVin] = useState<boolean>(isVinTypeMode);
    const [showScanner, setShowScanner] = useState(false);

    let content: JSX.Element;

    if (!isTypeVin) {
        content = (
            <>
                {vinDecodeError && <Alert variant="danger">{vinDecodeError?.message}</Alert>}
                <Button
                    className="submitButton mb-4 w-100"
                    disabled={disableWriteIntake}
                    onClick={() => setShowScanner(true)}>
                    {scanIcon}
                    <span className="ms-2">Scan VIN</span>
                </Button>
                <div
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-center mb-4 w-100">
                    <span className="text-nowrap me-2">Not at the vehicle?</span>
                    <Button data-testid={'type-vin'}
                            className="link-button text-nowrap"
                            disabled={disableWriteIntake}
                            variant="link"
                            onClick={() => {
                                setIsTypeVin(true);
                            }}>
                        Type the VIN instead
                    </Button>
                </div>
            </>
        );
    } else {
        content = (
            <FormBuilder
                forceDisable={disableWriteIntake}
                submitLabel="Validate VIN"
                fields={[
                    {
                        fieldType: FieldTypes.INPUT,
                        name: 'vin',
                        defaultValue: '',
                        customDesign: true,
                        label: 'VIN',
                        className: 'upperCase',
                        disabled: disableWriteIntake,
                        suffix: <Button className="button-with-icon"
                                        onClick={() => setShowScanner(true)}>{scanIcon}</Button>,
                        validations: {
                            required: true,
                            validate: {
                                invalidVin: (value: string) => validateVinCode(value)
                            }
                        }
                    }
                ]}
                onSubmit={(data) => {
                    if (!disableWriteIntake) {
                        onVinScan(data.vin.toUpperCase());
                        trackEvent([{
                            eventName: EVENT_TYPE.vin_code_typed
                        }]);
                    }
                }}
            />
        );
    }

    return (
        <div className="mt-4 mx-md-5" data-testid={'vehicle-vin-validate'}>
            {showScanner ? (
                <div className="scanArea">
                    <VinCodeScanner
                        showScanner={showScanner}
                        setShowScanner={setShowScanner}
                        onScan={(data) => onVinScan(data)}
                    />
                </div>
            ) : content}
        </div>
    );
};

export default VehicleVin;
