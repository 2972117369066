import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, Form, Row, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';

import TextInput, { ITextInputProps } from '../input/TextInput';
import { CheckboxTypes, InputTypes } from '../input/IInputTypes';
import CustomSelect, { ISelectProps } from '../select/CustomSelect';
import Checkbox, { ICheckboxProps } from '../input/Checkbox';
import FileInput, { IFileInputProps } from '../input/FileInput';
import YesNo from '../input/YesNo';
import FileUpload, { IFileUploadProps } from '../file-upload/FileUpload';
import { fileUploadPath } from '../api/Api';
import { useAxios } from '../custom-axios/CustomAxios';
import FieldsArray, { IFieldsArrayProps } from '../fields-array/FieldsArray';
import RangeSlider, { IRangeSliderProps } from '../input/RangeSlider';
import ColorPicker from '../input/ColorPicker';
import { IErrorProps, validateFileFormat } from '../validations/validations';
import { getImageFileMimeTypesForFileTypes, makeFileLowQuality } from '../utils';

export enum FieldTypes {
    INPUT = 'input',
    SELECT = 'select',
    CHECKBOX = 'checkbox',
    FILE = 'file',
    YES_NO = 'yesNo',
    FILE_UPLOAD = 'file_upload',
    TEMPLATE = 'template',
    FIELDS_ARRAY = 'fields_array',
    RANGE_SLIDER = 'range_slider',
    COLOR_PICKER = 'color_picker'
}

export interface IFieldItemProps extends ITextInputProps, ISelectProps, ICheckboxProps, IFileInputProps, IFileUploadProps, IFieldsArrayProps, IRangeSliderProps {
    fieldType: FieldTypes;
    inputType?: InputTypes | CheckboxTypes;
    relatedFields?: string[];
    conditionalField?: boolean;
    template?: (index: number, inputProps?: any, error?: IErrorProps) => JSX.Element | null;
    className?: string;
    shouldRegister?: boolean;
    formData?: any;
}

interface IFormBuilderProps {
    fields: IFieldItemProps[];
    onSubmit: (data: any) => void;
    watchers?: string[];
    watcherHandler?: (values: { [key: string]: string }, name: string, setValue?: any) => void;
    submitLabel?: string;
    submitBtnSize?: 'sm' | 'lg',
    forceDisable?: boolean,
    className?: string,
    showSubmitBtn?: boolean
}

const FormBuilder: React.FC<IFormBuilderProps> = ({
                                                      forceDisable = false,
                                                      fields,
                                                      onSubmit,
                                                      watchers = [],
                                                      watcherHandler,
                                                      submitLabel = 'Submit',
                                                      submitBtnSize,
                                                      className,
                                                      showSubmitBtn = true
                                                  }) => {
    const methods = useForm({
        shouldUseNativeValidation: false,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        resolver: undefined,
        context: undefined,
        criteriaMode: 'all',
        shouldFocusError: true,
        shouldUnregister: true
    });
    const {fetch: uploadFile} = useAxios();

    useEffect(() => {
        methods.watch((values, {name, type}) => {
            if (name && watchers?.includes(name)) {
                values && watcherHandler?.(values, name, methods.setValue);
            }

            const nameField = fields.find(f => f.name === name);
            if (nameField && nameField.relatedFields) {
                nameField.relatedFields.forEach(f => {
                    if (values[f]) {
                        methods.setValue(f, '', {
                            shouldValidate: false,
                            shouldDirty: false,
                            shouldTouch: false
                        });
                    }
                });
            }
        });
    }, [methods.watch]);

    useEffect(() => {
        fields.forEach(field => {
            if (field.conditionalField) {
                if (methods.getValues(field.name) !== undefined) {
                    methods.unregister(field.name);
                }
            }
        });
    }, [fields]);

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(checkValidation)} className={className}>
                <Row>
                    {fields.map(((field, index) => {
                        return (
                            <div key={`${field.name}`} className={field.className}>
                                {getFieldComponent(field, index)}
                            </div>
                        );
                    }))}
                </Row>
                {showSubmitBtn ? (
                    <div className="d-grid mb-4">
                        <Button
                            type={'submit'}
                            className="submitButton"
                            data-testid={'form-submit-btn'}
                            disabled={methods.formState.isSubmitting || forceDisable}>
                            <span className={methods.formState.isSubmitting ? 'me-2' : ''}>{submitLabel}</span>
                            {methods.formState.isSubmitting && (
                                <Spinner as="span" animation="border" size="sm"/>
                            )}
                        </Button>
                    </div>
                ) : null}
            </Form>
        </FormProvider>
    );

    function getFieldComponent(field: IFieldItemProps, index: number) {
        const validations = {...field.validations};
        if (field.conditionalField) {
            return;
        }
        switch (field.fieldType) {
            case FieldTypes.INPUT:
                return (
                    <TextInput
                        key={`${field.name}`}
                        {...field}
                        disabled={forceDisable || field.disabled}
                        type={field.inputType || InputTypes.TEXT}
                        setValue={methods.setValue}
                        inputProps={{...methods.register(field.name, validations)}}
                        error={methods.getFieldState(field.name).error}
                    />
                );
            case FieldTypes.SELECT:
                return (
                    <Controller
                        key={`${field.name}`}
                        name={`${field.name}`}
                        control={methods.control}
                        rules={field.validations}
                        defaultValue={field.defaultValue}
                        render={({field: {value, name}}) => {
                            return (
                                <CustomSelect
                                    {...field}
                                    disabled={forceDisable || field.disabled}
                                    setValue={methods.setValue}
                                    selectProps={{value, name}}
                                    error={methods.getFieldState(field.name).error}
                                    onChange={() => field.onChange?.(methods.getValues())}
                                />
                            );
                        }}
                    />
                );
            case FieldTypes.CHECKBOX:
                return (
                    <Checkbox
                        key={`${field.name}`}
                        {...field}
                        disabled={forceDisable || field.disabled}
                        type={field.inputType || CheckboxTypes.CHECKBOX}
                        checkboxProps={{...methods.register(field.name, validations)}}
                        error={methods.getFieldState(field.name).error}
                    />
                );
            case FieldTypes.YES_NO:
                return (
                    <YesNo
                        key={`${field.name}`}
                        {...field}
                        disabled={forceDisable || field.disabled}
                        type={field.inputType || CheckboxTypes.CHECKBOX}
                        control={methods.control}
                        checkboxProps={{...methods.register(field.name, validations)}}
                        error={methods.getFieldState(field.name).error}
                    />
                );
            case FieldTypes.FILE:
                return (
                    <FileInput
                        key={`${field.name}`}
                        {...field}
                        disabled={forceDisable || field.disabled}
                        control={methods.control}
                        setValue={methods.setValue}
                        reset={methods.reset}
                        inputProps={{
                            ...methods.register(field.name, {
                                value: field.defaultValue,
                                validate: {
                                    acceptedFormats: files => {
                                        return files instanceof FileList ?
                                            validateFileFormat(files[0]?.type, (field.accept || getImageFileMimeTypesForFileTypes()).split(',')) :
                                            true;
                                    },
                                    required: (value) => {
                                        if (field.isImageRequired) return !!value;
                                        return true;
                                    }
                                }
                            })
                        }}
                    />
                );
            case FieldTypes.FILE_UPLOAD:
                return (
                    <FileUpload
                        key={`${field.name}`}
                        {...field}
                        disabled={forceDisable || field.disabled}
                        control={methods.control}
                        inputProps={{...methods.register(field.name)}}
                    />
                );
            case FieldTypes.TEMPLATE:
                return (
                    <React.Fragment key={`${field.name}-${index}`}>
                        {field?.template?.(index, field.shouldRegister ? {
                            ...methods.register(field.name, validations),
                            setValue: methods.setValue
                        } : null, field.shouldRegister ? _.get(methods?.formState?.errors, field.name) as any : null)}
                    </React.Fragment>
                );
            case FieldTypes.FIELDS_ARRAY:
                return (
                    <FieldsArray
                        key={`${field.name}`}
                        {...field}
                        disabled={forceDisable || field.disabled}
                        getFieldComponent={getFieldComponent}
                        getValues={methods.getValues}
                        setValue={methods.setValue}
                        unregister={methods.unregister}
                        control={methods.control}
                    />
                );
            case FieldTypes.RANGE_SLIDER:
                return (
                    <RangeSlider
                        key={`${field.name}`}
                        {...field}
                        disabled={forceDisable || field.disabled}
                        type={field.inputType || InputTypes.NUMBER}
                        setValue={methods.setValue}
                        inputProps={{...methods.register(field.name, validations)}}
                    />
                );
            case FieldTypes.COLOR_PICKER:
                return (
                    <ColorPicker
                        key={`${field.name}`}
                        {...field}
                        disabled={forceDisable || field.disabled}
                        control={methods.control}
                        checkboxProps={{
                            ...methods.register(field.name, {
                                ...validations,
                                onChange: () => field.onChange?.(methods.getValues())
                            })
                        }}
                        error={methods.getFieldState(field.name).error}
                    />
                );
        }
    }

    async function uploadFileByField(data: any, field: any, callback?: Function) {
        if (field.fieldType === FieldTypes.FILE) {
            if ((data[field.name] instanceof FileList)) {
                const promises: Promise<any>[] = [];
                const values: File[] = Object.values(data[field.name]);
                let count = 0;
                const uploadingCallback = async (file: File | Blob) => {
                    const formData = new FormData();
                    formData.append('file', file);
                    if(field.formData) {
                        for (const [key, value] of Object.entries(field.formData)) {
                            formData.append(key, value as string);
                        }
                    }

                    promises.push(uploadFile(fileUploadPath, {
                        headers: {},
                        body: formData
                    }));
                    count++;

                    let urls: string[] = [];
                    if (promises?.length && count === values.length) {
                        await Promise.all(promises).then(jsons => {
                            urls = jsons.map(json => json.path);
                            data[field.name] = urls[0];
                            callback?.(data);
                        });
                    }
                };
                if (values?.length) {
                    for (let accFile of values) {
                        if (accFile.type.includes('image')) {
                            await new Promise(resolve => {
                                makeFileLowQuality(accFile, async (result) => {
                                    await uploadingCallback(result);
                                    resolve(true);
                                });
                            });
                        } else {
                            await uploadingCallback(accFile);
                        }
                    }
                } else {
                    data[field.name] = field.defaultUrl;
                }
            }
        }
    }

    function checkValidation(data: any) {
        const fileUploader = async () => {
            for (let field of fields) {
                if (field && field.fieldType === FieldTypes.FIELDS_ARRAY) {
                    for (let fieldItem of (field?.fields || [])) {
                        for (let dataIndex in data[field.name]) {
                            await uploadFileByField(data[field.name][dataIndex], fieldItem, (updatedData: any) => {
                                data[field.name][dataIndex] = updatedData;
                            });
                        }
                    }
                } else {
                    await uploadFileByField(data, field, (updatedData: any) => {
                        data = updatedData;
                    });
                }
            }

            return onSubmit(data);
        };

        return fileUploader();
    }
};

export default FormBuilder;
