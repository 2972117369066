import React from 'react';
import { Form } from 'react-bootstrap';
import InputLabelWithValidation from '../validations/InputLabelWithValidation';

import { CheckboxTypes, IBaseProps } from './IInputTypes';
import styles from './input.module.scss';

export interface ICheckboxProps extends IBaseProps {
    checkboxProps?: any;
}

const YesNo: React.FC<ICheckboxProps> = ({
                                             defaultValue = false,
                                             label = '',
                                             type = CheckboxTypes.RADIO,
                                             helperText,
                                             checkboxProps,
                                             validations,
                                             name,
                                             disabled = false,
                                             isInvalid = false,
                                             error
                                         }) => {
    return (
        <Form.Group className={styles.yesNo}>
            <InputLabelWithValidation 
                label={label}
                helperText={helperText}
                error={error}
            />
            <Form.Check
                className="yes"
                inline
                value={'yes'}
                type={type}
                id={`${name}-yes`}
                isInvalid={isInvalid}
                defaultChecked={defaultValue === 'yes'}
                disabled={disabled}
                {...checkboxProps}
            />
            <Form.Check
                className="no"
                inline
                value={'no'}
                type={type}
                id={`${name}-no`}
                isInvalid={isInvalid}
                defaultChecked={defaultValue === 'no'}
                disabled={disabled}
                {...checkboxProps}
            />
        </Form.Group>
    );
};

export default YesNo;
