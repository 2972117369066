import React, { useContext } from 'react';
import { Card, ListGroup } from 'react-bootstrap';

import styles from './status.module.scss';
import { ConsignmentStatusEnum, IAgreement, IParty, PartyStatusEnum } from '../Agreement';
import { DateFormatter } from '../../date/DateFormatter';
import { acceptIcon } from '../../svg-icons/SvgIcons';
import Resend from './Resend';
import { UserContext } from '../../../contexts/user/UserContext';
import { PermissionsEnum } from '../../permissions/Permissions';

interface IStatusProps {
    parties: Partial<IAgreement['parties']>;
    contractId: number;
    contractStatus: ConsignmentStatusEnum;
    totalSections: number | null;
    forceDisabled?: boolean;
    getContracts?: () => void
}

const Status: React.FC<IStatusProps> = ({parties, contractId, contractStatus, totalSections, forceDisabled, getContracts}): JSX.Element => {
    const {checkPermission} = useContext(UserContext);
    const disableSendConsignment = !checkPermission?.(PermissionsEnum.CONSIGNMENT_WRITE);

    return (
        <div className={styles.status}>
            <Card>
                <Card.Header>
                    <div className="card-header-content">
                        Status
                    </div>
                </Card.Header>
                <Card.Body className="p-0">
                    <ListGroup variant="flush">
                        {
                            parties?.map(party => (
                                <ListGroup.Item
                                    key={party?.id}
                                    className='d-flex flex-wrap gap-2 justify-content-between'>
                                    <span>{party?.name}</span>
                                    <div
                                        style={{whiteSpace: 'nowrap'}}
                                        className="d-flex align-items-center">
                                        {getStatuses(party)}
                                    </div>
                                </ListGroup.Item>
                            ))
                        }
                    </ListGroup>
                </Card.Body>
            </Card>
        </div>
    );

    function getStatuses(party: IParty | undefined) {
        switch (party?.status) {
            case PartyStatusEnum.SIGNED:
                return (
                    <div>
                        Signed (<DateFormatter date={party?.signedDate} format={'MMM DD YYYY hh:mma'}/>)
                        <span className="ms-2">{acceptIcon}</span>
                    </div>
                );
            case PartyStatusEnum.NOT_SENT:
                return (
                    <div>
                        <span className="status-not-sent">Not sent</span>
                    </div>
                );
            case PartyStatusEnum.ACCEPTED:
                return (
                    <>
                        <span className="status-not-sent">Accepted</span>
                    </>
                );
            case PartyStatusEnum.SENT:
                return (
                    <>
                        {party.acceptedSectionIds?.length ? (
                            <div className={"step-progress-bar"}>
                                {totalSections && <span className='progress-text'>{`${party.acceptedSectionIds?.length / (totalSections - 1) * 100}% complete`}</span>}
                                <span className='progress-indicator'>
                                    <span
                                        style={{
                                        width: totalSections
                                        ? ((party.acceptedSectionIds?.length || 1) * 100) / (totalSections - 1)
                                        : 0
                                        }}
                                    />
                                </span>
                            </div>
                        ) : (
                            <div className='d-flex flex-wrap gap-2' data-testid={'status-sent'}>
                                <span className="status-sent">Sent on <DateFormatter date={party.sentDate} format={'MMM DD YYYY hh:mma'}/></span>
                                {!disableSendConsignment && !forceDisabled && <Resend contractId={contractId}
                                         partyId={party?.id}
                                         partyName={party?.name}
                                         getContracts={getContracts}
                                />}
                            </div>
                        )}
                    </>
                );
        }
        return null;
    }
};

export default Status;
