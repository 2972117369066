import { IFavorite, IUser } from '../../../contexts/user/UserContext';

export enum UserDispatchProps {
    SET_USER = 'set_user',
    SET_SOCIAL_PREFILL_USER = 'set_social_prefill_data',
    UPDATE_USER = 'update_user',
    UPDATE_USER_ACTIVATION = 'update_user_activation',
    ADD_FAVORITE = 'add_favorite',
    REMOVE_FAVORITE = 'remove_favorite',
    SET_REDIRECT_URL = 'set_redirect_url',
    REMOVE_REDIRECT_URL = 'remove_redirect_url',
}

export const setUser = (data: IUser) => {
    return {
        type: UserDispatchProps.SET_USER,
        data
    };
};

export const setSocialPrefillData = (data: IUser) => {
    return {
        type: UserDispatchProps.SET_SOCIAL_PREFILL_USER,
        data
    };
};

export const updateUser = (data: IUser) => {
    return {
        type: UserDispatchProps.UPDATE_USER,
        data
    };
};

export const addUserFavorite = (favorite: IFavorite) => {
    return {
        type: UserDispatchProps.ADD_FAVORITE,
        favorite
    };
};

export const removeUserFavorite = (favoriteId: number) => {
    return {
        type: UserDispatchProps.REMOVE_FAVORITE,
        favoriteId
    };
};

export const updateUserActivation = (isFullyOnboarded: boolean) => {
    return {
        type: UserDispatchProps.UPDATE_USER_ACTIVATION,
        isFullyOnboarded
    };
};

export const setRedirectUrl = (redirectUrl: string) => {
    return {
        type: UserDispatchProps.SET_REDIRECT_URL,
        redirectUrl
    };
};

export const removeRedirectUrl = () => {
    return {
        type: UserDispatchProps.REMOVE_REDIRECT_URL
    };
};