export enum JWTKeys {
    CONSUMER = 'JWT_REVERSE_DEALER_TOKEN'
}

export interface IToken {
    accessToken: string;
    refreshToken: string;
}

export const getToken = (isRefreshToken = false) => {
    let token;
    try {
        const storage = localStorage?.getItem(JWTKeys.CONSUMER);
        const parsedStorage = storage && JSON.parse(storage);
        token = isRefreshToken ? parsedStorage.refreshToken : parsedStorage.accessToken;
    } catch (err) {
        token = null;
    }
    return token;
};

export const keepToken = (token: string, key?: string) => {
    localStorage?.setItem(JWTKeys.CONSUMER, token);
};
export const forgetToken = () => {
    localStorage?.removeItem(JWTKeys.CONSUMER);
};
// export const readToken = (token: string) => JWT.read(token);
export const validateToken = (token: string) => {
    return !!getToken();
};

export const isSudo = () => {
    let isSudo;
    try {
        const storage = localStorage?.getItem(JWTKeys.CONSUMER);
        const parsedStorage = storage && JSON.parse(storage);
        isSudo = !!parsedStorage.isSudo
    } catch (err) {
        isSudo = false;
    }

    return isSudo
};