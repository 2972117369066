import * as React from 'react';
import { useEffect, useState } from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import { AccordionEventKey } from 'react-bootstrap/AccordionContext';

import styles from './accordion.module.scss';
import { checkboxIcon, checkedCheckboxIcon } from '../svg-icons/SvgIcons';
import { AccordionCustomToggle } from './AccordionCustomToggle';

export interface IAccordionProps {
    activeKey?: AccordionEventKey;
    openMultiple?: boolean;
    showExpandAll?: boolean;
    defaultExpandAll?: boolean;
    onSelect?: (eventKey: AccordionEventKey, e: React.SyntheticEvent<unknown>) => void;
    items: {
        eventKey: string;
        disabled?: boolean;
        header: JSX.Element | string;
        body: JSX.Element | string;
    }[];
    showOnMount?: boolean;
    buttons?: JSX.Element;
    accordionHeading?: string;
    expandAllBtnClass?: string;
    expandAllBtnVariant?: string;
    useAccordionWithCustomToggle?: boolean;
    accordionToggleComponent?: JSX.Element | string;
}

export const AccordionContainer: React.FC<IAccordionProps> = ({
                                                                  items,
                                                                  activeKey = null, buttons = null,
                                                                  openMultiple = false,
                                                                  showExpandAll = false,
                                                                  onSelect, defaultExpandAll = false,
                                                                  accordionHeading,
                                                                  expandAllBtnClass = 'standardButton',
                                                                  expandAllBtnVariant = '',
                                                                  useAccordionWithCustomToggle = false,
                                                                  accordionToggleComponent = '',
                                                                  showOnMount = false
                                                              }) => {
    const [actives, setActives] = useState<any>(activeKey);
    const [isExpandedAll, setIsExpandedAll] = useState<boolean | null>(defaultExpandAll || null);

    useEffect(() => {
        setActives(activeKey);
        if (!actives?.includes(activeKey)) {
            setTimeout(() => {
                scrollToAccordionItemPosition(document.getElementById(`eventKey-${activeKey}`)?.offsetTop);
            }, 400);
        }
    }, [activeKey]);

    useEffect(() => {
        isExpandedAll !== null && setActives(isExpandedAll ? items.map(item => item.eventKey) : []);
    }, [isExpandedAll]);

    return (
        <div className={styles.accordion} data-testid={'accordion-container'}>
            {
                showExpandAll || buttons ? (
                    <div className="accordion-parent-header flex-wrap">
                        {accordionHeading && <span className="accordion-heading">{accordionHeading}</span>}
                        {showExpandAll && (
                            <Button
                                variant={expandAllBtnVariant}
                                className={`${expandAllBtnClass}`}
                                onClick={() => setIsExpandedAll(!isExpandedAll)}>
                                {expandAllBtnClass === 'standardButton' && (
                                    <span className="d-flex me-2">
                                        {isExpandedAll ? checkedCheckboxIcon : checkboxIcon}
                                    </span>
                                )}
                                {expandAllBtnClass === 'standardButton' ? 'Expand all' : isExpandedAll ? 'Collapse all' : 'Expand all'}
                            </Button>
                        )}
                        {buttons && buttons}
                    </div>
                ) : null
            }
            <Accordion
                activeKey={actives}
                alwaysOpen={openMultiple}
                onSelect={onAccordionSelect}>
                {
                    items.map((item, index) => (
                        useAccordionWithCustomToggle ? (
                            <div key={item.eventKey}>
                                <Card.Header className={'d-flex align-items-center'}>
                                    {item.header}
                                    <AccordionCustomToggle eventKey={item.eventKey}>
                                        {accordionToggleComponent}
                                    </AccordionCustomToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey={item.eventKey}>
                                    <Card.Body>{item.body}</Card.Body>
                                </Accordion.Collapse>
                            </div>
                        ) : (
                            <Accordion.Item
                                data-testid={`accordion-item-${index}`}
                                style={item.disabled ? {
                                    pointerEvents: 'none',
                                    cursor: 'not-allowed',
                                    opacity: 0.4
                                } : {}}
                                key={item.eventKey}
                                id={`eventKey-${item.eventKey}`}
                                eventKey={item.eventKey}>
                                <Accordion.Header>{item.header}</Accordion.Header>
                                <Accordion.Body>
                                    {(!showOnMount || showOnMount && activeKey === item.eventKey) ? item.body : null}
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    ))
                }
            </Accordion>
        </div>
    );

    function scrollToAccordionItemPosition(posTop: number | undefined) {
        posTop && window.scrollTo({
            top: posTop,
            behavior: 'smooth'
        });
    }

    function onAccordionSelect(eventKey: AccordionEventKey, e: React.SyntheticEvent<unknown>) {
        setActives(eventKey);
        onSelect?.(eventKey, e);
    }
};
