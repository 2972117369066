import { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';

import { forgetToken, getToken, keepToken } from '../token/token';
import createToast from '../toast/CreateToast';
import { ToastBg } from '../toast/NotificationToast';
import * as _ from 'lodash';
import { loginByRefreshTokenPath } from '../api/Api';
import { useRouter } from 'next/router';
import { UserContext } from '../../contexts/user/UserContext';
import { getStorage } from '../../contexts/storage/LocalStorageProvider';

export enum MethodData {
    POST = 'post',
    GET = 'get',
    DELETE = 'delete',
    PUT = 'put'
}

interface IOptions {
    method?: MethodData;
    headers?: any;
    body?: any;
    queryParams?: any;
    responseType?: any;
    signal?: AbortSignal;
}

// interface IData {
// data?: any;
// }

export const useAxios = (url?: string, options?: IOptions, resetDataOnRequest = false) => {
    const {user} = useContext(UserContext);
    const [fullData, setFullData] = useState<{ loading: boolean, data: any, error: any }>({
        loading: !!url,
        data: null,
        error: null
    });
    const isFirstLoading: React.MutableRefObject<any> = useRef(true);
    const abortControllerRef: React.MutableRefObject<any> = useRef();
    const router = useRouter();

    useEffect(() => {
        options?.method === MethodData.GET && (abortControllerRef.current = {
            isPrevAborted: false,
            newSignal: new AbortController()
        });

        url && fetch(url, options, resetDataOnRequest);
    }, [url, JSON.stringify(options?.queryParams)]);

    const fetch = (url: string, options: IOptions = {}, resetDataOnRequest = false, showErrorToast = true) => {
        if (options?.method === MethodData.GET) {
            abortControllerRef.current ? (abortControllerRef.current.newSignal.signal.onabort = () => {
                abortControllerRef.current.isPrevAborted = true;
                abortControllerRef.current.newSignal = new AbortController();
            }) : (
                abortControllerRef.current = {
                    isPrevAborted: false,
                    newSignal: new AbortController()
                });
            fullData.loading && !isFirstLoading.current && abortControllerRef.current?.newSignal.abort();
            isFirstLoading.current = false;
        }
        setFullData({
            ...fullData,
            ...(resetDataOnRequest ? {} : {data: null}),
            error: null,
            loading: true
        });

        const requestCustomAxios = (): any => {
            let isRefreshTokenUsed = false;
            return customAxios(url, {...options, signal: abortControllerRef.current?.newSignal.signal}).then(json => {
                setFullData(prevState => ({
                    ...prevState,
                    data: json,
                    loading: false,
                    error: null
                }));

                return json;
            })
            .catch(error => {
                const {userType} = getStorage();
                if (user && user.type !== userType) {
                    router.reload();
                    return;
                }

                if (!isRefreshTokenUsed && error.response?.status === 401) {
                    const refreshToken = getToken(true);
                    if (!refreshToken) {
                        forgetToken();
                        router.reload();
                        return;
                    }
                    // Getting accessToken by refreshToken
                    return customAxios(loginByRefreshTokenPath, {
                        body: {refreshToken}
                    }).catch(error => {
                        if (error.response.status === 401) {
                            forgetToken();
                            router.reload();
                        }
                    }).then(json => {
                        if (json) {
                            isRefreshTokenUsed = true;
                            keepToken(JSON.stringify({...json, refreshToken}));
                            return requestCustomAxios();
                        }
                    });
                }
                error && setFullData({
                    data: null,
                    loading: false,
                    error: error.response?.data
                });
                if (options?.method !== MethodData.GET || (abortControllerRef.current && !abortControllerRef.current.isPrevAborted)) {
                    if (error && showErrorToast) {
                        createToast({
                            bg: ToastBg.Warning,
                            heading: 'Error',
                            content: error.response?.data.message || 'Something went wrong.'
                        });
                    }
                }
                throw {error: error.response?.data};
            });
        };

        return requestCustomAxios();
    };
    return {loading: fullData.loading, error: fullData.error, fetch, data: fullData.data};
};

export const customAxios = (url: string, options: IOptions = {}) => {
    const token = getToken();
    const requestOptions = {method: MethodData.POST, ...options};
    let params: any = new URLSearchParams();

    for (let key in requestOptions.queryParams) {
        if (requestOptions.queryParams.hasOwnProperty(key)) {
            if (_.isArray(requestOptions.queryParams[key])) {
                requestOptions.queryParams[key].forEach((v: string) => {
                    params.append(key, v);
                });
            } else {
                params.append(key, requestOptions.queryParams[key]);
            }
        }
    }

    return axios({
        method: requestOptions.method,
        headers: {
            ...(token ? {'Authorization': `Bearer ${token}`} : {}),
            ...(requestOptions.headers || {'Content-Type': 'application/json'})
        },
        url,
        responseType: requestOptions?.responseType || 'json',
        params,
        signal: requestOptions.signal,

        data: requestOptions.body || {}
    })
    .then(response => response.data);
};
