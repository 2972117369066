import React, { useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Button, Container, Dropdown, Nav, Navbar, Offcanvas } from 'react-bootstrap';

import styles from '../../header/header.module.scss';
import {
    activeNotificationIcon,
    mainLogoIcon,
    mainLogoIconDark,
    noNotificationIcon,
    notificationIcon,
    userRoundIcon,
    xButton
} from '../../../lib/svg-icons/SvgIcons';
import { Translate } from '../../../lib/translation/Translation';
import { NavLink } from '../../../lib/navlink/NavLink';
import { UserContext } from '../../../contexts/user/UserContext';
import { PermissionsEnum } from '../../../lib/permissions/Permissions';
import { useLoggedIn } from '../../../lib/user-logged-in/UserLoggedIn';
import DealerSellsVehicle from '../../../lib/dealer-sells-vehicle/DealerSellsVehicle';
import { useWindowResize } from '../../../lib/window-resize-hook/WindowResizeHook';
import { InitialDataContext } from '../../../contexts/init/InitialDataContext';
import { DealerOnboardedSetupEnum } from "../../../lib/interfaces/dealer/dealerOnboard";

interface IDealerHeaderProps {
    defaultRoute: string;
}

const DealerHeader: React.FC<IDealerHeaderProps> = ({defaultRoute}) => {
    const location = useRouter();
    const {r22Enabled} = useContext(InitialDataContext);
    const {checkPermission, user, isFullyOnboarded, isPaid, hasUnreadNotifications} = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [showLoading, setShowLoading] = useState<boolean>(true);
    const {logout, isLoggedIn} = useLoggedIn();
    const headerNavRef: React.MutableRefObject<any> = useRef(null);
    const {width} = useWindowResize();
    const [expandBP, setExpandBP] = useState('lg');
    const [expandBPwidth, setExpandBPWidth] = useState(992);
    const isMobile = width < expandBPwidth;

    useEffect(() => {
        setOpen(false);
    }, [location.pathname]);

    useEffect(() => {
        !isMobile && setOpen(false);
    }, [width]);

    useEffect(() => {
        if (headerNavRef.current) {
            const navItemsCount = headerNavRef.current.childElementCount;

            switch (navItemsCount) {
                case 6:
                case 5:
                    setExpandBP('lg');
                    setExpandBPWidth(992);
                    break;
                case 4:
                    setExpandBP('md');
                    setExpandBPWidth(768);
                    break;
                default:
                    setExpandBP('sm');
                    setExpandBPWidth(576);
                    break;
            }
        }
    }, [isMobile]);

    useEffect(() => {
        if (isLoggedIn) {
            user && setShowLoading(false);
        } else {
            setShowLoading(false);
        }
    }, [user]);

    return (
        <div className={styles.header}>
            <Navbar expand={expandBP} variant="dark" expanded={open}>
                <Container fluid="md">
                    <Navbar.Brand as="span">
                        <Navbar.Toggle
                            className={'consumer-toggle'}
                            aria-controls="offcanvasHeaderNavbar"
                            onClick={() => {
                                setOpen(!open);
                            }}>
                            <svg width="16" height="24" viewBox="0 0 16 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 3.9999H0.625V0.899902H16V3.9999Z" fill="white"/>
                                <path d="M16 13.9999H0.625V10.8999H16V13.9999Z" fill="white"/>
                                <path d="M16 23.9999H0.625V20.8999H16V23.9999Z" fill="white"/>
                            </svg>
                        </Navbar.Toggle>
                        <NavLink className={'not-menu-item dealer-logo'} href={defaultRoute}>
                            <>
                                {mainLogoIcon}
                                <span>Dealer portal</span>
                            </>
                        </NavLink>
                    </Navbar.Brand>
                    {
                        showLoading ? null : (
                            <>
                                {!isMobile && (
                                    <Nav className="me-auto" style={{gap: '5px'}} ref={headerNavRef}>
                                        {
                                            checkPermission?.(PermissionsEnum.VEHICLE_LIST) ? (
                                                <NavLink href="/dealer/dashboard">
                                                    <Translate keyword={'dashboard'}/>
                                                </NavLink>
                                            ) : null
                                        }
                                        {
                                            checkPermission?.(PermissionsEnum.APPOINTMENT_LIST) && isFullyOnboarded ? (
                                                <NavLink href="/dealer/agenda">
                                                    <Translate keyword={'agenda'}/>
                                                </NavLink>
                                            ) : null
                                        }
                                        {
                                            checkPermission?.(PermissionsEnum.VEHICLE_LIST) && isFullyOnboarded ? (
                                                <>
                                                    <NavLink href="/dealer/pipeline">
                                                        <Translate keyword={'pipeline'}/>
                                                    </NavLink>
                                                    <NavLink href="/dealer/vehicles">
                                                        <Translate keyword={'vehicles'}/>
                                                    </NavLink>
                                                </>
                                            ) : null
                                        }
                                        {
                                            (checkPermission?.(PermissionsEnum.CLIENTS_LIST) || checkPermission?.(PermissionsEnum.FINANCING_READ)) && isFullyOnboarded ? (
                                                <NavLink data-testid={'menu-item-clients'}
                                                         href="/dealer/clients"
                                                         style={checkPermission?.(PermissionsEnum.USERS_MANAGE_SERVICE_REPS) ? {} : {marginRight: 'auto'}}>
                                                    <Translate keyword={'clients'}/>
                                                </NavLink>
                                            ) : null
                                        }
                                        {
                                            checkPermission?.(PermissionsEnum.USERS_MANAGE_SERVICE_REPS) && isFullyOnboarded ? (
                                                <NavLink data-testid={'menu-item-service'}
                                                         href="/dealer/mechanic"
                                                         style={{marginRight: 'auto'}}>
                                                    Service
                                                </NavLink>
                                            ) : null
                                        }
                                    </Nav>
                                )}
                                <div className={`nav-main order-1 order-${expandBP}-2`}>
                                    {
                                        checkPermission?.(PermissionsEnum.VEHICLE_CREATE) && isFullyOnboarded ? (
                                            <DealerSellsVehicle/>) : null
                                    }
                                    <NavLink
                                        className="me-0 p-0"
                                        href="/dealer/notifications"
                                        renderChildren={(isActive) => {
                                            return (
                                                <div>
                                                    {isActive ? activeNotificationIcon : hasUnreadNotifications ? notificationIcon : noNotificationIcon}
                                                </div>
                                            );
                                        }}
                                    />
                                    <Dropdown align={'end'} style={{gap: '5px'}}>
                                        <Dropdown.Toggle as={'div'} data-testid={'user-icon'}>
                                            {userRoundIcon}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.ItemText><strong>Hi {user?.firstName}!</strong></Dropdown.ItemText>
                                            <Dropdown.Divider/>
                                            <Dropdown.Item as={NavLink} href={'/profile'}>
                                                <Translate keyword={'profile'}/>
                                            </Dropdown.Item>
                                            <Dropdown.Item as={NavLink} href={'/profile?stage=password'}>
                                                <Translate keyword={'Password'}/>
                                            </Dropdown.Item>
                                            <Dropdown.Item as={NavLink} href={'/profile?stage=notifications'}>
                                                <Translate keyword={'Notification settings'}/>
                                            </Dropdown.Item>
                                            <Dropdown.Divider/>
                                            {
                                                isFullyOnboarded && checkPermission?.(PermissionsEnum.DATA_DASHBOARD_READ) ? (
                                                    <Dropdown.Item as={NavLink}
                                                                   href={`/dealer/management?stage=reports`}>
                                                        <Translate keyword={'reports'}/>
                                                    </Dropdown.Item>
                                                ) : null
                                            }
                                            {
                                                isFullyOnboarded && checkPermission?.(PermissionsEnum.DEALER_MANAGE) ? (
                                                    <>
                                                        <Dropdown.Item as={NavLink}
                                                                       href={`/dealer/management?stage=${DealerOnboardedSetupEnum.DEALERSHIP_INFO}`}>
                                                            <Translate keyword={'dealership_info'}/>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item as={NavLink}
                                                                       href={`/dealer/management?stage=${DealerOnboardedSetupEnum.OPERATING_HOURS}`}>
                                                            <Translate keyword={'operating_hours'}/>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item as={NavLink}
                                                                       href={`/dealer/management?stage=${DealerOnboardedSetupEnum.CARFAX_INTEGRATIONS}`}>
                                                            <Translate keyword={'Carfax'}/>
                                                        </Dropdown.Item>
                                                        {
                                                            r22Enabled ? (
                                                                <Dropdown.Item as={NavLink}
                                                                               href={`/dealer/management?stage=${DealerOnboardedSetupEnum.R22}`}>
                                                                    <Translate keyword={'marketplace_cross_listing'}/>
                                                                </Dropdown.Item>
                                                            ) : null
                                                        }
                                                    </>
                                                ) : null
                                            }
                                            {
                                                isFullyOnboarded &&
                                                (checkPermission?.(PermissionsEnum.USERS_MANAGE_MANAGERS) ||
                                                    checkPermission?.(PermissionsEnum.DEALER_MANAGE) ||
                                                    checkPermission?.(PermissionsEnum.USERS_MANAGE_SERVICE_REPS) ||
                                                    checkPermission?.(PermissionsEnum.USERS_MANAGE_SALES_REPS)) ? (
                                                    <Dropdown.Item as={NavLink}
                                                                   href={`/dealer/management?stage=${DealerOnboardedSetupEnum.INVITE_STAFF_MEMBERS}`}>
                                                        <Translate keyword={'members'}/>
                                                    </Dropdown.Item>
                                                ) : null
                                            }
                                            {
                                                isFullyOnboarded && checkPermission?.(PermissionsEnum.DEALER_MANAGE) ? (
                                                    <Dropdown.Item as={NavLink}
                                                                   href={`/dealer/management?stage=${DealerOnboardedSetupEnum.CONFIGURE_NOTIFICATIONS}`}>
                                                        <Translate keyword={'notifications'}/>
                                                    </Dropdown.Item>
                                                ) : null
                                            }
                                            {
                                                checkPermission?.(PermissionsEnum.DEALER_BANKING) && isFullyOnboarded ? (
                                                    <Dropdown.Item as={NavLink}
                                                                   href={`/dealer/management?stage=${DealerOnboardedSetupEnum.SETUP_BANKING_INFORMATION}`}>
                                                        <Translate keyword={'billing'}/>
                                                    </Dropdown.Item>
                                                ) : null
                                            }
                                            {
                                                checkPermission?.(PermissionsEnum.DEALER_MANAGE) && isFullyOnboarded ? (
                                                    <>
                                                        <Dropdown.Item as={NavLink}
                                                                       href={`/dealer/management?stage=${DealerOnboardedSetupEnum.PURR_APPROVAL}`}>
                                                            <Translate keyword={'Agreement'}/>
                                                        </Dropdown.Item>
                                                    </>
                                                ) : null
                                            }
                                            {
                                                (
                                                    checkPermission?.(PermissionsEnum.DEALER_MANAGE) ||
                                                    checkPermission?.(PermissionsEnum.USERS_MANAGE_SALES_REPS) ||
                                                    checkPermission?.(PermissionsEnum.USERS_MANAGE_MANAGERS)
                                                ) && isFullyOnboarded && isPaid ? (
                                                    <>
                                                        <Dropdown.Item as={NavLink}
                                                                       href={`/dealer/management?stage=lead-generation`}>
                                                            <Translate keyword={'lead-generation'}/>
                                                        </Dropdown.Item>
                                                    </>
                                                ) : null
                                            }
                                            {
                                                checkPermission?.(PermissionsEnum.DEALER_MANAGE) && isFullyOnboarded ? (
                                                    <>
                                                        <Dropdown.Item as={NavLink}
                                                                       href={'/dealer/management?stage=settings'}>
                                                            <Translate keyword={'Settings'}/>
                                                        </Dropdown.Item>
                                                    </>
                                                ) : null
                                            }
                                            <Dropdown.Divider/>
                                            <Dropdown.Item as={NavLink} href={'/contact-form'}>
                                                Contact Us
                                            </Dropdown.Item>
                                            <Dropdown.Item as={Button} onClick={signout}>Sign Out</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <Offcanvas
                                    show={open}
                                    id="offcanvasHeaderNavbar"
                                    aria-labelledby="offcanvasHeaderNavbar"
                                    placement="bottom"
                                    scroll={true}
                                    onHide={() => setOpen(!open)}
                                    className={`header-nav-offcanvas order-${expandBP}-2`}>
                                    <Offcanvas.Header>
                                        <div>
                                            {mainLogoIconDark}
                                        </div>
                                        <Button
                                            variant="link"
                                            className={'link-button mobile-close'}
                                            onClick={() => {
                                                setOpen(!open);
                                            }}>
                                            Close
                                            <span className="ms-2">{xButton}</span>
                                        </Button>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Nav>
                                            {
                                                checkPermission?.(PermissionsEnum.VEHICLE_LIST) ? (
                                                    <NavLink href="/dealer/dashboard">
                                                        <Translate keyword={'dashboard'}/>
                                                    </NavLink>
                                                ) : null
                                            }
                                            {
                                                checkPermission?.(PermissionsEnum.APPOINTMENT_LIST) && isFullyOnboarded ? (
                                                    <NavLink href="/dealer/agenda">
                                                        <Translate keyword={'agenda'}/>
                                                    </NavLink>
                                                ) : null
                                            }
                                            {
                                                checkPermission?.(PermissionsEnum.VEHICLE_LIST) && isFullyOnboarded ? (
                                                    <>
                                                        <NavLink href="/dealer/pipeline">
                                                            <Translate keyword={'pipeline'}/>
                                                        </NavLink>
                                                        <NavLink href="/dealer/vehicles">
                                                            <Translate keyword={'vehicles'}/>
                                                        </NavLink>
                                                    </>
                                                ) : null
                                            }
                                            {
                                                (checkPermission?.(PermissionsEnum.CLIENTS_LIST) || checkPermission?.(PermissionsEnum.FINANCING_READ)) && isFullyOnboarded ? (
                                                    <NavLink data-testid={'menu-item-clients'}
                                                             href="/dealer/clients"
                                                             style={checkPermission?.(PermissionsEnum.USERS_MANAGE_SERVICE_REPS) ? {} : {marginRight: 'auto'}}>
                                                        <Translate keyword={'clients'}/>
                                                    </NavLink>
                                                ) : null
                                            }
                                            {
                                                checkPermission?.(PermissionsEnum.USERS_MANAGE_SERVICE_REPS) && isFullyOnboarded ? (
                                                    <NavLink data-testid={'menu-item-service'}
                                                             href="/dealer/mechanic"
                                                             style={{marginRight: 'auto'}}>
                                                        Service
                                                    </NavLink>
                                                ) : null
                                            }
                                        </Nav>
                                    </Offcanvas.Body>
                                </Offcanvas>
                            </>
                        )
                    }
                </Container>
            </Navbar>
        </div>
    );

    function signout() {
        logout();
        window.location.href = '/dealer/signin';
    }
};

export default DealerHeader;
