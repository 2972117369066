import React, { useEffect, useState } from 'react';
import { CloseButton } from 'react-bootstrap';
import { imageUploadIcon, imageUploadInvalidIcon } from '../svg-icons/SvgIcons';
import { getPhotoThumbnailPath } from '../utils';
import { IErrorProps } from '../validations/validations';

interface IThumbnailsProps {
    files: File[] | null;
    allowDelete?: boolean;
    defaultValue?: string;
    onChange?: (files: File[] | null) => void;
    onDefaultItemRemove?: (item: string) => void;
    error?: IErrorProps;
}

export const Thumbnails: React.FC<IThumbnailsProps> = ({
                                                           files, defaultValue, onChange,
                                                           allowDelete = false, error
                                                       }) => {
    const [filesData, setFilesData] = useState<File[] | null>(files);

    useEffect(() => {
        files && setFilesData(files);
    }, [files]);

    if (!filesData?.length && !defaultValue) {
        return error?.type ? imageUploadInvalidIcon : imageUploadIcon;
    } else if (!filesData?.length && defaultValue) {
        return (
            <div className={'image-thumbnail'}>
                <img src={getPhotoThumbnailPath(defaultValue, 96, 64)} alt={''}/>
            </div>
        );
    }

    return (
        <div className={`image-thumbnail ${error ? 'is-invalid' : ''}`}>
            {
                filesData?.map((file, index) => (
                    <React.Fragment key={file instanceof File ? file.name : index}>
                        {
                            allowDelete ? (
                                <CloseButton onClick={() => onThumbnailRemove(file)}/>
                            ) : null
                        }
                        <img alt={''}
                             src={file instanceof File ? URL.createObjectURL(file) : getPhotoThumbnailPath(file, 96, 64)}/>
                    </React.Fragment>
                ))
            }
        </div>
    );

    function onThumbnailRemove(file: File) {
        filesData?.splice(filesData?.findIndex(fileData => fileData.name === file.name), 1);

        filesData && setFilesData([...filesData]);
        onChange?.(filesData);
    }
};
