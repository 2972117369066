import { useContext } from 'react';
import { Button, Container } from 'react-bootstrap';

import {
    blackWarnignIcon,
} from '../../lib/svg-icons/SvgIcons';
import { UserContext, UserType } from '../../contexts/user/UserContext';
import { useLoggedIn } from '../../lib/user-logged-in/UserLoggedIn';

const SudoBar: React.FC = () => {
    const {logout} = useLoggedIn();
    const {user} = useContext(UserContext);

    return (
        <div
            style={{
                background: '#FFF9DA',
                border: '1px solid #F4C00F',
                borderTop: '0',
                borderRadius: '0 0 8px 8px',
                padding: '24px 0px'
            }}>
                <Container>
                    <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                        <div className="d-flex">
                            <div className='me-3'>
                                {blackWarnignIcon}
                            </div>
                            <div
                                style={{
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    color: '#00330F'
                                }}>
                                    You are browsing account of {user?.type} "{getInformationMessage()}" as admin
                            </div>
                        </div>
                        <div>
                            <Button className="submitButton btn-dark mt-2 mt-md-0" onClick={signout} style={{height: '42px'}}>
                                End session
                            </Button>
                        </div>
                    </div>
                </Container>
        </div>
    );

    function getInformationMessage(){
        switch(user?.type) {
            case UserType.CONSUMER:
                return `${user.firstName} ${user.lastName} (${user.email})`;
            case UserType.DEALER:
                return `${user?.dealerProfile?.dealer?.name || ''} (${user.email})`;
        }
    }

    function signout() {
        logout();
        window.location.href = '/signin';
    }
};

export default SudoBar;
