import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import styles from './textAreaFormControl.module.scss';

interface ITextAreaFormControlProps {
  defaultValue?: string | null,
  inputProps: any,
  maxCharsCount: number,
  placeholder?: string,
  error?: any,
  disabled?: boolean,
  className?: string,
  name?: string
}

const TextAreaFormControl: React.FC<ITextAreaFormControlProps> = ({
                                                                    defaultValue, inputProps, 
                                                                    maxCharsCount, error, disabled, 
                                                                    className, name, placeholder = ''
                                                                  }) => {
  const textAreaRef: React.MutableRefObject<any> = useRef();

  const [refVisible, setRefVisible] = useState(false);
  const [textAreaHeight, setTextAreaHeight] = useState('75px');
  const [inputValue, setInputValue] = useState(defaultValue || '');

  let heightListenerInterval: any;

  useEffect(() => {
    if (refVisible) {
      if (defaultValue) {
        heightListenerInterval = setInterval(() => {
          const textarea = textAreaRef.current?.querySelector(name ? `textarea[name="${name}"]` : 'textarea');
          if (textarea && textarea.scrollHeight > 75) {
            setTextAreaHeight(textarea?.scrollHeight + 'px');
          }

          if (textarea && !!textarea.textContent && !!textarea.scrollHeight) {
            clearInterval(heightListenerInterval);
          }
        }, 50);

        return () => {
          clearInterval(heightListenerInterval);
        };
      }
    }
  }, [refVisible]);

  return (
    <div >
      <InputGroup
        className={`${styles.textAreaFormControl} plain-control ${className || ''}`}
        ref={(el: any) => {
          textAreaRef.current = el;
          setRefVisible(!!el);
        }}
      >
        <Form.Control
          name={name}
          placeholder={placeholder}
          className={error ? 'is-invalid' : ''}
          as="textarea"
          data-testid={`textarea-${inputProps.name}`}
          maxLength={maxCharsCount}
          defaultValue={defaultValue || ''}
          disabled={disabled}
          style={{height: textAreaHeight}}
          onInput={(e: any) => {
            setInputValue(e.target.value);
            e.target.style.height = 'auto';
            e.target.style.height = e.target.scrollHeight + 'px';
          }}
          {...inputProps}
          onBlur={(ev) => {
            ev.target.value = ev.target.value.trim();
            setInputValue(ev.target.value);
            inputProps?.onBlur(ev);
          }}
        />
        <div
          className={`character-counter ${error ? 'is-invalid' : ''}`}
          style={{
            color: (
              inputValue.length > maxCharsCount
              ? '#FF4000'
              : inputValue.length <= maxCharsCount && inputValue.length >= maxCharsCount - maxCharsCount * 0.3
              ? '#F4C00F'
              : '#181818'
            )
          }}
        >
          {`${inputValue.length} / ${maxCharsCount}`}
        </div>
      </InputGroup>
    </div>
  )
}

export default TextAreaFormControl;