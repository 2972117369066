import { PropsWithChildren, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import styles from './modal.module.scss';

export interface IModalPortalData {
    show: boolean;
    onCloseHandler: () => void;
    onSave?: () => void;
    onExit?: () => void;
    onExited?: () => void;
    showFooter?: boolean;
    showTitle?: boolean;
    heading?: string | JSX.Element;
    closeLabel?: string;
    saveLabel?: string;
    className?: string;
    backdrop?: boolean | 'static';
    disableClose?: boolean;
    disableSave?: boolean;
    showSaveBtn?: boolean;
    showCloseBtn?: boolean;
    size?: 'sm' | 'lg' | 'xl' | undefined;
}

export const ModalPortal: React.FC<PropsWithChildren<IModalPortalData>> = ({
                                                                               show = false,
                                                                               onCloseHandler,
                                                                               onSave,
                                                                               onExit,
                                                                               onExited,
                                                                               showFooter = true,
                                                                               showTitle = true,
                                                                               heading = 'Modal heading',
                                                                               closeLabel = 'Close',
                                                                               saveLabel = 'Save',
                                                                               children,
                                                                               className = '',
                                                                               backdrop = true,
                                                                               disableClose,
                                                                               disableSave,
                                                                               showSaveBtn = true,
                                                                               showCloseBtn = true,
                                                                               size = undefined
                                                                           }): JSX.Element => {

    const [isHidden, setIsHidden] = useState<boolean>(!show);

    useEffect(() => {
        show && setIsHidden(false);
    }, [show]);

    if (isHidden) {
        return <></>;
    }

    return (
        <Modal show={show}
               onExit={onExit}
               onExiting={() => {
                   show && setTimeout(() => {
                       setIsHidden(true);
                   }, 500);
               }}
               onExited={onExited}
               onHide={onCloseHandler}
               
               backdrop={backdrop}
               size={size}
               className={`${styles.modal} ${className}`}>
            {
                showTitle ? (
                    <Modal.Header>
                        <Modal.Title>{heading}</Modal.Title>
                    </Modal.Header>
                ) : null
            }
            {
                children && (
                    <Modal.Body>
                        {children}
                    </Modal.Body>
                )
            }
            {
                showFooter ? (
                    <Modal.Footer>
                        {showCloseBtn && (
                            <Button className="standardButton" disabled={disableClose} onClick={onCloseHandler}>
                                {closeLabel}
                            </Button>
                        )}
                        {showSaveBtn && (
                            <Button data-testid={'save-label'} className="standardButton btn-dark "
                                    disabled={disableSave} onClick={onSave}>
                                {saveLabel}
                            </Button>
                        )}
                    </Modal.Footer>
                ) : null
            }
        </Modal>
    );
};
