import { IDealer, IDealerVehicle } from '../dealer/dealer';
import { IListingPhotoItem } from '../photos/photos';
import { IListingEquipmentItem, IListingFeatureItem } from '../equipment/equipment';
import { IFavorite } from '../../../contexts/user/UserContext';
import { IInspectionResult } from '../inspection/inspection';

export interface IVehicle {
    odometer: number;
    vdVersion: IVDVersion;
    colorHex: string;
    vin: string;
    stockNumber: string;
    vehicleStage: VehicleStagesEnum;
}

export interface IVDVersion {
    id: number;
    externalId: number;
    makeId: number;
    modelId: number;
    name: string;
    vdModel: IVDModel;
    vdMake: IVDMake;
}

export interface IVDMake {
    id: number;
    externalId: number;
    name: string;
}

export interface IVDModel {
    id: number;
    externalId: number;
    makeId: number;
    year: string;
    name: string;
}

export interface IVDColor {
    id: number;
    name: string;
    hex: string;
}

export interface IVDBodyType {
    id: number;
    name: string;
    externalId: number;
}

export interface IVDDriveTrain {
    id: number;
    externalCategoryId: number;
    categoryName: string;
    groupId: number;
    groupName: string;
    headerId: number;
    headerName: string;
    typeId: string;
    typeName: string;
}

export interface IListedVehicleData {
    buyerContext: { [key: string]: boolean };
    id: number;
    dealerVehicleId: number;
    viewsCount: number;
    listedAt: string;
    createdAt: string;
    modifiedAt: string[]
    favorites: IFavorite[],
    dealerVehicle: IDealerVehicle;
    myPurchaseIntentId: number;
    isSold?: boolean;
    conditionallySold?: boolean;
    offersCount: number;
    publishedVersion: {
        id: number;
        listingId: number;
        versionType: string;
        dealerId: number;
        vdVersionId: number;
        vdBodyTypeId: number;
        price: number;
        mileage: number;
        doorsCount: number;
        seatsCount: number;
        engine: string;
        horsepower: number;
        wheels: string;
        transmission: string;
        vdDrivetrainId: number;
        vdExteriorColorId: number;
        vdInteriorColorId: number;
        keywords: string;
        createdAt: string;
        modifiedAt: string;
        vdDriveTrain: IVDDriveTrain,
        vdBodyType: IVDBodyType,
        vdInteriorColor: IVDColor,
        vdExteriorColor: IVDColor,
        inspectionReportDate?: string;
        inspectionComponentStatuses: IInspectionResult[],
        vdVersion: IVDVersion
        photos: IListingPhotoItem[],
        dealer: IDealer;
        features: IListingFeatureItem[];
        equipments: IListingEquipmentItem[];
        photoPath: string;
    };
    carfax: {
        badges: string[],
        reportUrl: string;
    };
    vehicleName: string;
    photoPath: string;
    taxes: {name: string; percentage: number}[]
}

export enum VehicleStagesEnum {
    INCOMING = 'incoming',
    INTAKE = 'intake',
    CONSIGNMENT = 'consignment',
    INSPECTION = 'inspection',
    LISTING = 'listing',
    LISTED = 'listed',
    NEGOTIATING = 'negotiating',
    ACCEPTED = 'accepted',
    SOLD = 'sold',
    SELLER_PAID = 'seller_paid',
    ARCHIVED = 'archived'
}

export enum VehicleControlTypeEnum {
    INTERNAL = 'internal',
    SYNDICATED = 'syndicated'
}

export enum VehicleLeadStatusEnum {
    PENDING = 'pending',
    CANCELLED = 'cancelled',
    COMPLETED = 'completed'
}
