import dayjs from 'dayjs';
import { FieldError } from 'react-hook-form';
import { FieldTypes } from '../form/FormBuilder';
import { maxAge } from '../utils';
import { IValidationProps } from '../input/IInputTypes';

export interface IErrorProps extends FieldError {
    // message?: string;
    // type?: ErrorTypes;
    // types?: any;
    ref?: any;
}

export enum ErrorTypes {
    REQUIRED = 'required',
    MIN = 'min',
    MAX = 'max',
    MAX_DATE = 'maxDate',
    MIN_DATE = 'minDate',
    MAX_LENGTH = 'maxLength',
    NON_EMPTY = 'nonEmpty',
    ACCEPTED_FORMATS = 'acceptedFormats',
    DUPLICATE_NAMES = 'duplicateNames',
    ONLY_DIGITS = 'onlyDigits',
    INVALID_VIN = 'invalidVin',
    INVALID_ZIP_CODE = 'invalidZipCode',
    INVALID_DRIVER_LICENSE = 'invalidDriverLicense',
    INVALID_EMAIL = 'invalidEmail',
    INVALID_PASSWORD = 'invalidPassword',
    INVALID_BN = 'invalidBN',
    INVALID_CONFIRM_PASSWORD = 'invalidConfirmPassword',
    INVALID_AGE_UNDER = 'invalidAgeUnder',
    INVALID_MASK_INPUT = 'invalidMaskInput',
    CUSTOM = 'custom'
}

export enum MaskTypes {
    PHONE_MASK = '(999)-999-9999'
}

const getActionByFieldType = (fieldType: FieldTypes) => {
    switch (fieldType) {
        case FieldTypes.SELECT:
            return 'select';
        case FieldTypes.INPUT:
            return 'enter';

        default:
            break;
    }
};

export const getErrorMessageByType = (error: IErrorProps, fieldName?: string | JSX.Element, fieldType?: FieldTypes, validations?: IValidationProps) => {

    switch (error?.type) {
        case ErrorTypes.REQUIRED:
            return `This field is required`;
        case ErrorTypes.MIN:
        case ErrorTypes.MAX:
            return `Value must be ${(error?.ref.min || (validations?.min || validations?.min === 0)) && (error?.ref.max || validations?.max) ? `between ${error?.ref.min || validations?.min} and ${error?.ref.max || validations?.max}` : (error?.ref.min || (validations?.min || validations?.min === 0)) ? `greater than or equal to ${error?.ref.min || validations?.min}` : `less than or equal to ${error?.ref.max || validations?.max}`}`;
        case ErrorTypes.MAX_LENGTH:
            return 'Your entered value is too long';
        case ErrorTypes.MAX_DATE:
            return `Date must be ${dayjs.utc(error?.ref.max).format('MM/DD/YYYY')} or earlier.`;
        case ErrorTypes.MIN_DATE:
            return `Date must be ${dayjs.utc(error?.ref.min).format('MM/DD/YYYY')} or later.`;
        case ErrorTypes.NON_EMPTY:
            return 'Inputs containing only space char(s) are not allowed';
        case ErrorTypes.ACCEPTED_FORMATS:
            return 'Wrong file format.';
        case ErrorTypes.ONLY_DIGITS:
            return 'Please enter integer value';
        case ErrorTypes.INVALID_VIN:
            return 'Please enter a valid VIN code';
        case ErrorTypes.INVALID_ZIP_CODE:
            return 'Please enter a valid postal code (e.g. A1A 1A1)';
        case ErrorTypes.INVALID_DRIVER_LICENSE:
            return 'Please enter a valid driver\'s license number';
        case ErrorTypes.INVALID_EMAIL:
            return 'Please enter a valid email address';
        case ErrorTypes.INVALID_PASSWORD:
            return 'Password must contain at least 8 characters, including 1 upper case, 1 lower case, 1 numeric, 1 special';
        case ErrorTypes.INVALID_BN:
            return 'Please provide a valid BN15(e.g. 123456789AA1234)';
        case ErrorTypes.INVALID_CONFIRM_PASSWORD:
            return 'Password and confirm password do not match';
        case ErrorTypes.INVALID_AGE_UNDER:
            return `Age must be at least ${maxAge} years`;
        case ErrorTypes.DUPLICATE_NAMES:
            return 'Duplicated names';
        case ErrorTypes.INVALID_MASK_INPUT:
            return `Please enter a valid ${typeof fieldName === 'string' ? fieldName?.toLowerCase() : fieldName}`;
        default:
            return `This field is required`;
    }
};

export const validateInputIsNotEmpty = (input?: string) => {
    if (typeof input === 'string' && (input?.trim() || input.length === 0)) {
        return true;
    }
    return false;
};
export const validateFileFormat = (fileType: string, acceptedFormats: string[]) => {
    return acceptedFormats.includes(fileType);
};

export const validateVinCode = (vin: string): boolean => {
    const specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const restrictedLetters = ['Q', 'O', 'I'];
    const isRestrictedLetter = restrictedLetters.some(l => vin?.includes(l));
    if (vin && (vin.length !== 17 || isRestrictedLetter || vin === '11111111111111111' || specialChars.test(vin))) {
        return false;
    } else {
        return true;
    }
};

export const validateZipCode = (input: string) => {
    return (/^[ABCEGHJ-NPRSTVXYabceghj-nprstvxy]\d[a-zA-Z]\s?\d[a-zA-Z]\d$/).test(input);
};

export const validateDriverLicenseNumber = (input: string) => {
    return (/^[1-9a-zA-Z][a-zA-Z0-9-\s]*$/).test(input);
};

export const validateEmailAddress = (input: string) => {
    return (/^(?:\S*[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:\S*(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9]){1,}?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:\S*[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f]))\])$/).test(input);
};

export const validatePassword = (input: string) => {
    return (/((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,64})/).test(input);
};

export const validatePasswordMethods = (input: string) => {
    return {
        fewCharacters: () => (/.{8,64}/).test(input),
        fewUppercases: () => (/((?=.*[A-Z]).{1,})/).test(input),
        fewLowercases: () => (/((?=.*[a-z]).{1,})/).test(input),
        fewNumbers: () => (/((?=.*\d).{1,})/).test(input),
        fewSpecialCharacters: () => (/((?=.*[\W]).{1,})/).test(input)
    };
};

export const validateBusinessNumber = (input: string) => {
    return (/^\d{9}[a-zA-Z]{2}[0-9]{4}$/).test(input);
};

export const validateAgeLimit = (input: string, age: number = 18, isUnder = true) => {
    const inputDate = dayjs(input).utc().minute(0).second(0).millisecond(0);
    if (isUnder ? inputDate.isAfter(dayjs.utc().minute(0).second(0).millisecond(0).subtract(age, 'y')) : inputDate.isBefore(dayjs.utc().minute(0).second(0).millisecond(0).add(age, 'y'))) {
        return false;
    }
    return true;
};

export const validateInputWithMask = (input: string, mask: MaskTypes = MaskTypes.PHONE_MASK) => {
    switch (mask) {
        case MaskTypes.PHONE_MASK:
            return (/^[(](\d{3})[)](?:[-])(\d{3})[-](\d{4})$/).test(input);
    }
};

export const validateOnlyDigits = (input: string, allowDecimal: boolean = false) => {
    if (!input) {
        return true;
    }
    if (!allowDecimal) {
        return (/^\d*\d$/).test(input);
    } else {
        return (/^\d*\.?\d*$/).test(input);
    }
};
