export const formatCurrency = (value = 0, fractionDigits = 0, prefix = '', suffix = '', currency = 'USD', language = 'en-US') => {
    const formatter = new Intl.NumberFormat(language, {
        currencyDisplay: 'symbol',
        currencySign: 'accounting',
        // signDisplay: 'exceptZero',
        ...(prefix ? {} : {style: 'currency', currency}),
        minimumFractionDigits: fractionDigits
    });

    return `${value < 0 ? '-' : ''}${prefix || ''}${formatter.format(Math.abs(value))} ${suffix}`.trim();
};

export const formatCurrencyByCompact = (value = 0, prefix = '', suffix = '', currency = 'USD', language = 'en-US') => {
    const formatter = new Intl.NumberFormat(language, {
        notation: 'compact',
        compactDisplay: 'short',
        style: 'currency', currency
    });

    return `${value < 0 ? '-' : ''}${prefix || ''}${formatter.format(Math.abs(value)).replace('T', 'K')} ${suffix}`.trim();
};
