import React from 'react';

import styles from './empty-state.module.scss';

interface IEmptyStateProps {
    text: string | JSX.Element;
    action?: JSX.Element;
    title?: string | JSX.Element;
}

export const EmptyState: React.FC<IEmptyStateProps> = ({text, action, title}): JSX.Element => {
    return (
        <div className={styles.emptyState}>
            {
                title ? (
                    <h2 className={'title mb-4'}>{title}</h2>
                ) : null
            }
            <div>
                <>
                    {typeof text === 'string' ? (
                        <p>
                            {text}
                        </p>
                    ) : (
                        <>
                            {text}
                        </>
                    )}
                </>
                {action}
            </div>
        </div>
    );
};
