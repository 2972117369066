import { UserType } from '../../contexts/user/UserContext';

const path: string = '/api';
const wordpressPath: string = 'https://v99c1xq5yz.wpdns.site';

/*--------------------------------------------------------------Me-------------------------------------------------------------*/
export const getMePath = `${path}/me`;
/*-----------------------------------------------------------------------------------------------------------------------------*/
/*-------------------------------------------------------------Init------------------------------------------------------------*/
export const initPath = `${path}/init`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------------Track event----------------------------------------------------*/
export const getTrackEventPath = `${path}/track-event`;
/*-----------------------------------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------Google api----------------------------------------------------*/
export const googleGeolocationPath = (key: string) => `https://www.googleapis.com/geolocation/v1/geolocate?key=${key}`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*----------------------------------------------------------Login/Reg----------------------------------------------------------*/
export const loginPath = (userType: UserType) => `${path}/auth/login/${userType}`;
export const loginByRefreshTokenPath = `${path}/auth/token/exchange`;
export const registerPath = `${path}/auth/consumer/register`;
export const emailVerificationPath = (sessionId: string | number, verificationToken: string) => `${path}/auth/verify-email/${sessionId}/${verificationToken}`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*----------------------------------------------------------Marketplace----------------------------------------------------------*/
export const getMarketplaceDetailByListingId = (listingId: string) => `${path}/marketplace/detail/${listingId}`;
export const getMarketplaceDistanceByListingId = (listingId: number | string) => `${path}/marketplace/distance/${listingId}`;
export const getMarketplaceDetailPreviewByListingId = (listingId: string) => `${path}/marketplace/detail/${listingId}/preview`;
export const getMarketplaceInspectionPdfPath = (listingId: string | number, isPreview: boolean) => `${path}/marketplace/${listingId}/inspection-pdf?isPreview=${isPreview}`;
export const getMarketplaceEquipmentsByListingVersionId = (listingVersionId: number | string) => `${path}/marketplace/equipments/${listingVersionId}`;
export const getMarketplaceInspectionStatusesByListingVersionId = (listingVersionId: number | string) => `${path}/marketplace/inspection/${listingVersionId}`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*----------------------------------------------------------PurchaseIntents------------------------------------------------------------*/
export const getPurchaseIntentByBuyerListingPath = (listingId: string) => `${path}/purchase-intents/by-buyer-listing/${listingId}`;
export const postPurchaseIntentPath = (listingId: string | number) => `${path}/purchase-intents/${listingId}`;
export const putPurchaseIntentPath = (listingId: string) => `${path}/purchase-intents/${listingId}/appointment`;
export const purchaseIntentSendReminderPath = (listingId: number) => `${path}/purchase-intents/${listingId}/appointment/reminder`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*----------------------------------------------------------Dealers------------------------------------------------------------*/
export const dealersPath = `${path}/dealers`;
export const dealersSoldStatsPath = `${path}/dealer/sold-statistics`;
/*-----------------------------------------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------Dealer-vehicle-----------------------------------------------------*/
export const dealerVehiclePath = `${path}/dealer-vehicle`;
export const publishPath = (dealerVehicleId: string | number) => `${path}/dealer-vehicle/${dealerVehicleId}/publish`;
export const dealerVehiclePathById = (dvId: string) => `${path}/dealer-vehicle/${dvId}`;
export const dealerVehicleSummary = `${path}/dealer-vehicle/summary`;
export const proofOfSalePath = (dealerVehicleId: string | number) => `${path}/dealer-vehicle/${dealerVehicleId}/proof-of-sale`;
export const unPublishPath = (dealerVehicleId: string | number) => `${path}/dealer-vehicle/${dealerVehicleId}/unpublish`;
export const cancelSalePath = (dealerVehicleId: string | number) => `${path}/dealer-vehicle/${dealerVehicleId}/back-to-listed`;
export const vehicleFlagPath = (dealerVehicleId: string | number) => `${path}/dealer-vehicle/${dealerVehicleId}/flag`;
export const dealerVehicleSalesPipelinePath = `${path}/dealer-vehicle/sales-pipeline`;
export const dealerVehicleArchivePath = (dealerVehicleId: string | number) => `${path}/dealer-vehicle/${dealerVehicleId}/archive`;
export const vehicleSellToDealerPath = (dealerVehicleId: string | number) => `${path}/dealer-vehicle/${dealerVehicleId}/sold-to-dealer`;
export const dealerVehicleRestoreFromArchivePath = (dealerVehicleId: string | number) => `${path}/dealer-vehicle/${dealerVehicleId}/restore`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*----------------------------------------------------------Dealer-Management--------------------------------------------------*/
export const createDealerPath = `${path}/dealer-signup`;
export const getDealerManagementPath = `${path}/dealer-management`;
export const dealerOnboardingStatusPath = `${path}/dealer-management/onboarding-status`;
export const saveDealerManagementInfoPath = `${path}/dealer-management/information`;
export const getDealerManagementUsersPath = `${path}/dealer-management/users`;
export const getDealerManagementInvitesPath = `${path}/dealer-management/invites`;
export const dealerManagementInvitePath = (inviteId: number) => `${path}/dealer-management/invites/${inviteId}`;
export const dealerManagementReInvitePath = (inviteId: number) => `${path}/dealer-management/invites/${inviteId}/resend-invitation`;
export const dealerManagementUsersPath = (userId: number) => `${path}/dealer-management/users/${userId}`;
export const dealerManagementUsersSkipPath = `${path}/dealer-management/users/skip`;
export const dealerManagementCarfaxPath = `${path}/dealer-management/carfax`;
export const skipDealerManagementCarfaxPath = `${path}/dealer-management/carfax/skip`;
export const dealerManagementR22Path = `${path}/dealer-management/r22`;
export const skipDealerManagementR22Path = `${path}/dealer-management/r22/skip`;
export const signupDealerManagementMemberPath = (token: string) => `${path}/dealer-management/invites/${token}`;
export const checkValidityDealerManagementMemberPath = (token: string) => `${path}/dealer-management/invites/${token}/check-validity`;
export const dealerManagementOperatingHoursPath = `${path}/dealer-management/operating-hours`;
export const dealerManagementHolidaysPath = `${path}/dealer-management/holidays`;
export const deleteDealerManagementHolidayPath = (holiday: string | number) => `${path}/dealer-management/holidays/${holiday}`;
export const dealerManagementNotificationsPath = `${path}/dealer-management/notifications`;
export const checkDealerManagementEmailUniquenessPath = `${path}/dealer-management/check-business-email-uniqueness`;
export const checkMemberInvitationEmailUniquenessPath = `${path}/dealer-management/check-invitation-email-uniqueness`;
export const getDealerRolesPath = `${path}/simple-types/dealer-roles`;
export const getDealerOnboardingAdsPath = `${path}/simple-types/ad-platforms`;
export const getDealerOnboardingToolsPath = `${path}/simple-types/tools-used`;
export const getDealerR22PlatformsPath = `${path}/simple-types/r22platforms`;
export const getDealerManagementReportsPath = `${path}/dealer-management/reports`;
export const skipDealerManagementPaymentPath = `${path}/dealer-management/skip-payment`;
export const checkBusinessNumberUniquenessPath = `${path}/dealer-management/check-business-number-uniqueness`;
export const getPotentialDealersAutocompletePath = `${path}/dealer-management/potential-dealers/autocomplete`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*----------------------------------------------------------Dealer-Billing----------------------------------------------------*/
export const getDealerBillingStatusPath = `${path}/dealer-billing/status`;
export const getDealerBillingInvoicesPath = `${path}/dealer-billing/invoices`;
export const postAddPaymentMethodPath = `${path}/dealer-billing/add-payment-method`;
export const deletePaymentMethodPath = `${path}/dealer-billing/delete-payment-method`;
export const changeDefaultPaymentMethodPath = `${path}/dealer-billing/change-default-payment-method`;
export const changeInvoicePaymentMethodPath = `${path}/dealer-billing/change-invoice-payment-method`;
export const cancelSetupIntentPath = `${path}/dealer-billing/cancel-setup-intent`;
export const getCreditsPath = `${path}/dealer-billing/credits`;
export const getStripeCallbackPath = `${path}/dealer-billing/stripe-callback`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*----------------------------------------------------------Mechanic Routes----------------------------------------------------*/
export const getMechanicDealerVehiclePathById = (dvId: string) => `${path}/dp/mechanic/dealer-vehicle/${dvId}`;
export const getMechanicDealerVehiclesInspectionQueuePath = `${path}/dp/mechanic/inspection-queue`;
export const getMechanicDealerVehiclesInspectionPendigRepairPath = `${path}/dp/mechanic/inspection-pending-repair`;
export const getMechanicSummaryPath = `${path}/dp/mechanic/inspection/summary`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*----------------------------------------------------------Dealer-------------------------------------------------------------*/
export const getDealerByIdPath = (dealerId: string) => `${path}/dealers/${dealerId}`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*----------------------------------------------------------Dealer-intake-------------------------------------------------------------*/
export const startDealerIntakePath = `${path}/dealer-intake`;
export const dealerIntakeVehiclePath = (dealerIntakeId: string) => `${path}/dealer-intake/${dealerIntakeId}/vehicle`;
export const getDealerIntakePath = (dealerIntakeId: string) => `${path}/dealer-intake/${dealerIntakeId}`;
export const dealerIntakeVinPath = (vehicleLeadId: string) => `${path}/dealer-intake/${vehicleLeadId}/vin`;
export const dealerIntakeBasicsPath = (intakeId: string) => `${path}/dealer-intake/${intakeId}/basics`;
export const dealerIntakeConditionPath = (intakeId: string) => `${path}/dealer-intake/${intakeId}/condition`;
export const dealerIntakeHistoryPath = (intakeId: string) => `${path}/dealer-intake/${intakeId}/history`;
export const dealerIntakeOwnershipsPath = (intakeId: string) => `${path}/dealer-intake/${intakeId}/ownerships`;
export const dealerIntakeInsurancePath = (intakeId: string) => `${path}/dealer-intake/${intakeId}/insurance`;
export const dealerIntakeIdentificationPath = (intakeId: string) => `${path}/dealer-intake/${intakeId}/identification`;
export const dealerIntakeLeansPath = (intakeId: string) => `${path}/dealer-intake/${intakeId}/liens`;
export const dealerIntakePricePath = (intakeId: string | number) => `${path}/dealer-intake/${intakeId}/price`;
export const dealerIntakeOdometerPath = (dealerVehicleId: string | number) => `${path}/dealer-intake/${dealerVehicleId}/odometer`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*---------------------------------------------------------Consignment---------------------------------------------------------*/
export const getConsignmentPath = (id: number, signToken = '') => `${path}/consignment/${id}${signToken ? `?signToken=${signToken}` : ''}`;
export const dealerConsignmentByIdPath = (dealerVehicleId: string) => `${path}/consignment/byDealerVehicleId/${dealerVehicleId}`;
export const getContractIdPath = (id: string | number, signToken = '') => `${path}/contract/${id}${signToken ? `?signToken=${signToken}` : ''}`;
export const getContractSectionIdPath = (id: string | number, signToken = '') => `${path}/contract/${id}/html${signToken ? `?signToken=${signToken}` : ''}`;
export const getContractPdfIdPath = (id: number | number, signToken = '') => `${path}/contract/${id}/pdf${signToken ? `?signToken=${signToken}` : ''}`;
export const acceptContractBySection = (id: number, signToken = '') => `${path}/contract/${id}/accept-section${signToken ? `?signToken=${signToken}` : ''}`;
export const sendContractToParties = (id: number, signToken = '') => `${path}/contract/${id}/send${signToken ? `?signToken=${signToken}` : ''}`;
export const saveContractSign = (id: string, signToken = '') => `${path}/contract/${id}/sign${signToken ? `?signToken=${signToken}` : ''}`;
export const sendContractToParty = (id: number, signToken = '') => `${path}/contract/${id}/resend${signToken ? `?signToken=${signToken}` : ''}`;
export const consignmentRenewPath = (id: number) => `${path}/consignment/${id}/renew`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*---------------------------------------------------------Inspection---------------------------------------------------------*/
export const getInspectionByIdPath = (id: string | number) => `${path}/inspection/${id}`;
export const getInspectionCategoriesPath = `${path}/inspection/categories`;
export const getInspectionResultsPath = (id: string | number) => `${path}/inspection/${id}/results`;
export const getInspectionFailedSuggestedItemsCountPath = (id: string | number) => `${path}/inspection/${id}/failed-suggested-count`;
export const addInspectionToQueuePath = (id: string | number) => `${path}/inspection/${id}/queue`;
export const addInspectionRepairStatusPath = (id: string | number) => `${path}/inspection/${id}/repair-status`;
export const updateInspectionComponentStatusById = (inspectionId: string | number, componentId: string | number) => `${path}/inspection/${inspectionId}/component/${componentId}`;
export const skipInspectionPath = (id: string | number) => `${path}/inspection/${id}/skip`;
export const restoreSkippedInspectionPath = (id: string | number) => `${path}/inspection/${id}/restore-skip`;
export const getInspectionPdfById = (id: string | number) => `${path}/inspection/${id}/pdf`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*------------------------------------------------------------Photos-----------------------------------------------------------*/
export const getPhotosListByIdPath = (dealerVehicleId: string | number) => `${path}/photo/${dealerVehicleId}`;
export const createPhotoByIdPath = (dealerVehicleId: string | number) => `${path}/photo/${dealerVehicleId}`;
export const updateExistingPhotoByIdPath = (dealerVehicleId: string | number, photoTypeId: string | number) => `${path}/photo/${dealerVehicleId}/${photoTypeId}`;
export const updateExistingLabelByIdPath = (dealerVehicleId: string | number, photoTypeId: string | number) => `${path}/photo/${dealerVehicleId}/${photoTypeId}/associate-photo-type`;
export const deleteExistingPhotoByIdPath = (dealerVehicleId: string | number, photoTypeId: string | number) => `${path}/photo/${dealerVehicleId}/${photoTypeId}`;
export const createReqSuppressedPhotoByIdPath = (dealerVehicleId: string | number) => `${path}/photo/${dealerVehicleId}/require-suppressed`;
export const getPhotoTypesListByIdPath = (dealerVehicleId: string | number) => `${path}/photo-type/${dealerVehicleId}`;
export const createPhotoTypeLabelByIdPath = (dealerVehicleId: string | number) => `${path}/photo-type/${dealerVehicleId}`;
export const removePhotoTypeLabelByIdPath = (dealerVehicleId: string | number) => `${path}/photo-type/${dealerVehicleId}`;
export const getPhotoTypeLabelAutocompleteByIdPath = (dealerVehicleId: string | number) => `${path}/photo-type/${dealerVehicleId}/autocomplete`;
export const createPhotoFeatureRelationPath = (dealerVehicleId: string | number) => `${path}/photo/${dealerVehicleId}/feature`;
export const deletePhotoFeatureRelationPath = (dealerVehicleId: string | number) => `${path}/photo/${dealerVehicleId}/feature`;
export const sortPhotosByIdPath = (dealerVehicleId: string | number) => `${path}/photo/${dealerVehicleId}/sort`;
export const changeUploadModePhotosByIdPath = (dealerVehicleId: string | number) => `${path}/photo/${dealerVehicleId}/change-upload-mode`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------Vehicle features------------------------------------------------------*/
export const getFeaturesPath = (dealerVehicleId: string | number) => `${path}/feature/${dealerVehicleId}`;
export const createFeaturesPath = (dealerVehicleId: string | number) => `${path}/feature/${dealerVehicleId}`;
export const highlightFeaturesPath = (dealerVehicleId: string | number, featureId: string | number) => `${path}/feature/${dealerVehicleId}/${featureId}/highlighted`;
export const installFeaturesPath = (dealerVehicleId: string | number, featureOptionId: string | number) => `${path}/feature/${dealerVehicleId}/${featureOptionId}/install`;
export const getFeatureAutoCompletePath = (dealerVehicleId: string | number) => `${path}/feature-options/${dealerVehicleId}/autocomplete`;
export const uninstallFeaturesPath = (featureId: string | number) => `${path}/feature/${featureId}/uninstall`;
export const deleteCustomFeaturePath = (featureId: string | number) => `${path}/feature/${featureId}/remove`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*------------------------------------------------------Features Specifications----------------------------------------------*/
export const getEquipmentSpecificationsPath = (dealerVehicleId: string | number) => `${path}/specifications/${dealerVehicleId}`;
export const updateEquipmentSpecificationsPath = (dealerVehicleId: string | number) => `${path}/specifications/${dealerVehicleId}`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*---------------------------------------------------------Negotiations---------------------------------------------------------*/
export const getAllowedRangePath = (listingId: string | number) => `${path}/negotiations/${listingId}/allowed-range`;
export const buyerBuyNowPath = (negotiationId: string | number) => `${path}/negotiations/${negotiationId}/buy-now`;

/*------------------------------------------------------------------------------------------------------------------------------*/

/*---------------------------------------------------------Vehicle lead--------------------------------------------------------*/
export const vehicleLeadPath = `${path}/vehicle-lead`;
export const vehicleLeadReshedulePath = (sessionId: string | number | undefined) => `${path}/vehicle-lead/${sessionId}/reschedule`;
export const vehicleLeadCancelPath = (sessionId: string | number | undefined) => `${path}/vehicle-lead/${sessionId}/cancel`;
export const vehicleLeadSessionPath = (sessionId: string | number | undefined) => `${path}/vehicle-lead/${sessionId}`;
export const vehicleLeadAppointmentPath = (sessionId: string | number | undefined) => `${path}/vehicle-lead/${sessionId}/appointment`;
export const vehicleLeadAssociatePath = (sessionId: string | number | undefined) => `${path}/vehicle-lead/${sessionId}/associate`;
export const vehicleLeadConditionPath = (sessionId: string | number | undefined) => `${path}/vehicle-lead/${sessionId}/condition`;
export const vehicleLeadUpdatePath = (sessionId: string | number | undefined) => `${path}/vehicle-lead/${sessionId}/vehicle`;
export const vdMakesPath = `${path}/vd/makes`;
export const vdModelsPath = `${path}/vd/models`;
export const vdYearsPath = `${path}/vd/years`;
export const vdVersionsPath = `${path}/vd/versions`;
export const vdColorsPath = `${path}/vd/colors`;
export const vdVinDecodePath = `${path}/vd/vin-decode`;
export const vdBodyTypesPath = `${path}/vd/body-types`;
export const vdFuelTypesPath = `${path}/vd/fuel-types`;
export const vdSeatTypesPath = `${path}/vd/seat-types`;
export const vdDriveTrainsPath = `${path}/vd/drive-trains`;
export const vdEngineTypesPath = (dealerVehicleId: string | number) => `${path}/vd/engine-types/${dealerVehicleId}`;
export const vdTransmissionTypesPath = (dealerVehicleId: string | number) => `${path}/vd/transmission-types/${dealerVehicleId}`;
export const vdWheelTypesPath = (dealerVehicleId: string | number) => `${path}/vd/wheel-types/${dealerVehicleId}`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*---------------------------------------------------------Seller lead--------------------------------------------------------*/
export const sellerLeadPath = `${path}/seller-lead`;
export const sellerLeadSessionPath = (sessionId: string | number | undefined) => `${path}/seller-lead/${sessionId}`;
export const sellerLeadConditionPath = (sessionId: string | number | undefined) => `${path}/seller-lead/${sessionId}/condition`;
export const sellerLeadUpdatePath = (sessionId: string | number | undefined) => `${path}/seller-lead/${sessionId}/vehicle`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*---------------------------------------------------------Agenda---------------------------------------------------------*/
export const getHeavyAgendaPath = (from: string, to: string) => `${path}/dealer/agenda/heavy?from=${from}&to=${to}`;
export const getLightweightAgendaPath = (from: string, to: string) => `${path}/dealer/agenda/lightweight?from=${from}&to=${to}`;
export const getClientsPath = `${path}/dealer/clients/all`;
export const getSellerClientsPath = `${path}/dealer/clients/sellers`;
export const getBuyerClientsPath = `${path}/dealer/clients/buyers`;

/*---------------------------------------------------------Dealer availability---------------------------------------------------------*/
export const postDealerAvailabilityPath = `${path}/dealer-availability`;
export const postDealerAvailabilityAffectedAppointmentsPath = `${path}/dealer-availability/affected-appointments`;
export const postDealerAvailabilityToggleSlotPath = `${path}/dealer-availability/toggle-slot`;

/*---------------------------------------------------------File Upload---------------------------------------------------------*/
export const fileUploadPath = `${path}/file/upload`;

/*---------------------------------------------------------Provinces---------------------------------------------------------*/
export const getProvincesPath = `${path}/simple-types/provinces`;

/*---------------------------------------------------------Provinces---------------------------------------------------------*/
export const getFinancialInstitutionsPath = `${path}/financial-institutions`;

/*---------------------------------------------------------Marketplace---------------------------------------------------------*/
export const getMarketplacePath = `${path}/marketplace`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*----------------------------------------------------------Favorite-----------------------------------------------------------*/
export const getFavoritesPath = `${path}/favorite`;
export const updateFavoriteByIdPath = (listingId: string | number) => `${path}/favorite/${listingId}`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------Generate QR code------------------------------------------------------*/
export const createQRCodePath = `${path}/qr-code`;
export const getQRCodeStatusPath = (code: string | number) => `${path}/qr-code/${code}`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*----------------------------------------------------------Test drive-----------------------------------------------------------*/
export const getTestDrivePath = (listingId: string | number) => `${path}/test-drive/${listingId}`;
export const scheduleTestDrivePath = (listingId: string | number) => `${path}/test-drive/${listingId}/schedule`;
export const rescheduleTestDrivePath = (id: string | number) => `${path}/test-drive/${id}/reschedule`;
export const cancelTestDrivePath = (id: string | number) => `${path}/test-drive/${id}/cancel`;
export const resolveTestDrivePath = (id: string | number) => `${path}/test-drive/${id}/resolve`;
export const getAllTestDrivesPath = (listingId: string | number) => `${path}/test-drives/${listingId}`;

/*-----------------------------------------------------------My--------------------------------------------------------*/
export const getMyListingContextPath = (listingId: string | number) => `${path}/my/listing/${listingId}/context`;
export const getMyVehicleByDealerVehicleIdPath = (dealerVehicleId: string | number) => `${path}/my/vehicles/${dealerVehicleId}`;
export const getMyBidsPath = `${path}/my/bids`;
export const getMyVehiclesPath = `${path}/my/vehicles`;
export const getMyDashboardPath = `${path}/my/dashboard`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*----------------------------------------------------------Negotiations-------------------------------------------------------*/
export const getNegotiationByListingPath = (listingId: string | number) => `${path}/negotiations/by-listing/${listingId}`;
export const getNegotiationOfferAllowedRangePath = (listingId: string | number) => `${path}/negotiations/${listingId}/allowed-range`;
export const acceptNegotiationOfferPath = (negotiationId: string | number) => `${path}/negotiations/${negotiationId}/accept`;
export const createNegotiationOfferPath = (negotiationId: string | number) => `${path}/negotiations/${negotiationId}/offers`;
export const withdrawNegotiationPath = (negotiationId: string | number) => `${path}/negotiations/${negotiationId}/withdraw`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------------User-----------------------------------------------------------*/
export const updateUserProfilePath = `${path}/user`;
export const inviteUserPath = `${path}/user/invite`;
export const lookupUserPath = `${path}/user/lookup`;
export const checkEmailUniquenessPath = `${path}/user/check-email-uniqueness`;
export const changeUserPasswordPath = `${path}/auth/user/change-password`;
export const signupInvitationPath = (token: string) => `${path}/auth/signup-invitation/${token}`;
export const findOrSendInvitationPath = `${path}/user/find-or-send-invitation`;
export const federatedSigninPath = (provider: string) => `${path}/federated-signin/${provider}`;
export const federatedSigninCallbackPath = (provider: string) => `${path}/federated-signin/callback/${provider}`;
export const federatedSignupPath = `${path}/federated-signup`;
export const resendEmailVerificationPath = `${path}/auth/resend-verify-email`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*-----------------------------------------------------------Overview----------------------------------------------------------*/
export const getDealerVehicleOverviewPath = (dealerVehicleId: string | number) => `${path}/dealer-vehicle/${dealerVehicleId}/overview`;
export const getDealerVehiclePreviewPath = (dealerVehicleId: string | number) => `${path}/dealer-vehicle/${dealerVehicleId}/preview`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*-----------------------------------------------------------Listing----------------------------------------------------------*/
export const scheduleAppointmentPath = (listingId: string | number) => `${path}/listing/${listingId}/schedule-appointment`;
// @TODO: This route cleaned up from backend, make sure to cleanup this and related components
export const rescheduleAppointmentByListingIdPath = (listingId: string | number) => `${path}/listing/${listingId}/reschedule-appointment`;
// @TODO: This route cleaned up from backend, make sure to cleanup this and related components
export const cancelAppointmentByListingIdPath = (listingId: string | number) => `${path}/listing/${listingId}/cancel-appointment`;
export const getListingAsDealerPath = (listingId: string | number) => `${path}/listing/as-dealer/${listingId}`;
export const getCloseSalePath = (listingId: string | number) => `${path}/listing/sold/${listingId}`;
export const getPublishedListingsPath = `${path}/listings/published`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*-----------------------------------------------------------Appointment----------------------------------------------------------*/
export const rescheduleAppointmentPath = (appointmentId: string | number) => `${path}/appointment/${appointmentId}/reschedule`;
export const cancelAppointmentPath = (appointmentId: string | number) => `${path}/appointment/${appointmentId}/cancel`;
export const getAppointmentsPath = `${path}/appointments`;
export const completeAppointmentPath = (appointmentId: string | number) => `${path}/appointment/${appointmentId}/complete`;
export const markAsMissedAppointmentPath = (appointmentId: string | number) => `${path}/appointment/${appointmentId}/mark-as-missed`;
export const getIncompleteAppointmentsPath = `${path}/appointments/action-needed`;
export const getIncompleteAppointmentsCountPath = `${path}/appointments/action-needed/count`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*-----------------------------------------------------------Credit application----------------------------------------------------------*/
export const getCreditApplicationsPath = `${path}/credit-applications`;
export const getCreditApplicationByIdPath = (creditApplicationId: string | number) => `${path}/credit-application/${creditApplicationId}`;
export const createCreditApplicationPath = `${path}/credit-application/create`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*-----------------------------------------------------------Pre Approval----------------------------------------------------------*/
export const getPreApprovalsPath = `${path}/pre-approvals`;
export const getPreApprovalByIdPath = (preApprovalId: string | number) => `${path}/pre-approval/${preApprovalId}`;
export const createPreApprovalPath = `${path}/pre-approval/create`;
export const getDealerPreApprovalPath = `${path}/dealer/pre-approvals`;
export const getDealerPreApprovalByUserIdPath = (preApprovalId: string | number) => `${path}/dealer/pre-approval/${preApprovalId}`;
export const updatePreApprovalPath = (preApprovalId: string | number) => `${path}/dealer/pre-approval/${preApprovalId}/update`;
export const getPreApprovalUserPath = (preApprovalId: number | string) => `${path}/dealer/pre-approval/${preApprovalId}/user`;
export const getPreApprovalUnavailableDealersList = `${path}/pre-approvals/unavailable-dealerships`;
export const getPreApprovalByDealershipPath = (dealerId: number | string) => `${path}/pre-approval/by-dealership/${dealerId}`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*----------------------------------------------------------Notifications-------------------------------------------------------------*/
export const getNotificationsPath = `${path}/notifications`;
export const getUnreadNotificationsPath = `${path}/notifications/unread`;
export const putMarkAsReadNotificationsPath = `${path}/notifications/mark-as-read`;
export const putMarkAsReadNotificationsByIdPath = (notificationId: number) => `${path}/notifications/${notificationId}/mark-as-read`;
export const getNotificationsSettingsPath = `${path}/notifications/settings`;
export const putNotificationsSettingsPath = `${path}/notifications/settings`;
export const putNotificationsGroupSettingsPath = `${path}/notifications/settings/group`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*-----------------------------------------------------------Documents----------------------------------------------------------*/
export const getUserDocumentsPath = `${path}/user/documents`;
export const updateUserDocumentByTypePath = `${path}/user/documents`;
export const deleteUserDocumentByTypePath = `${path}/user/documents`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*-------------------------------------------------Dealer vehicle documents----------------------------------------------------*/
export const getDealerVehicleDocumentsPath = (dealerVehicleId: string | number) => `${path}/dealer-vehicle/${dealerVehicleId}/documents`;
export const updateDealerVehicleDocumentsPath = (dealerVehicleId: string | number) => `${path}/dealer-vehicle/${dealerVehicleId}/documents/update`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*-------------------------------------------------------Reset password--------------------------------------------------------*/
export const postForgotPasswordPath = `${path}/password-reset/forgot`;
export const postCreateNewPasswordPath = `${path}/password-reset/new-password`;
export const getCheckForgotPassworValidityByIdPath = (passwordResetId: string | number) => `${path}/password-reset/${passwordResetId}/check-validity`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------Contact us-----------------------------------------------------------*/
export const contactUsPath = `${path}/contact-us`;
export const getInTouchPath = `${path}/get-in-touch`;
export const freeAppraisalPath = `${path}/free-appraisal`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*---------------------------------------------Consumer deactivate account-----------------------------------------------------*/
export const getIfUserCanDeactivatePath = `${path}/user/deactivate-check`;
/*-----------------------------------------------------------------------------------------------------------------------------*/


/*---------------------------------------------Seller payments-----------------------------------------------------------------*/
export const putMarkSellerPaymentAsReadyPath = (sellerPaymentId: number | string) => `${path}/seller-payment/${sellerPaymentId}/mark-as-ready`;
export const putMarkSellerPaymentAsPaidPath = (sellerPaymentId: number | string) => `${path}/seller-payment/${sellerPaymentId}/mark-as-paid`;
export const scheduleSellerPaymentPath = (sellerPaymentId: number | string) => `${path}/seller-payment/${sellerPaymentId}/schedule`;
export const putSellerPaymentPayoutPath = (sellerPaymentId: number | string) => `${path}/seller-payment/${sellerPaymentId}/payout`;
export const cancelSellerPaymentPath = (sellerPaymentId: number | string) => `${path}/seller-payment/${sellerPaymentId}/cancel`;
export const sellerPaymentSetRemainderPath = (sellerPaymentId: number | string) => `${path}/seller-payment/${sellerPaymentId}/reminder`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------Calendar-----------------------------------------------------------*/
export const getCalendarPath = (calendarType: string, appointmentId: string | number) => `${path}/calendar-sync/${appointmentId}/${calendarType}`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------Dealer-agreement-----------------------------------------------------------*/
export const getDealerAgreementByIdPath = (agreementId: string | number, signToken = '') => `${path}/dealer-onboarding-agreement/${agreementId}${signToken ? `?signToken=${signToken}` : ''}`;
export const getDealerAgreementPath = `${path}/dealer-onboarding-agreement`;
export const getDealerAgreementSentContractsPath = (agreementId: string | number, signToken = '') => `${path}/dealer-onboarding-agreement/${agreementId}/contracts/by-sign-token${signToken ? `?signToken=${signToken}` : ''}`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------Wordpress-----------------------------------------------------------*/
export const getWordpressPostsPath = () => `${wordpressPath}/wp-json/wp/v2/posts`;
export const getWordpressPostsCategoriesPath = () => `${wordpressPath}/wp-json/wp/v2/categories`;
export const getWordpressRelatedPostsPath = (postId: string | number) => `${wordpressPath}/wp-json/yarpp/v1/related/${postId}`;
/*-----------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------Sitemap-----------------------------------------------------------*/
export const sitemapPath = `${path}/sitemap`;
/*-----------------------------------------------------------------------------------------------------------------------------*/
