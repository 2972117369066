import '../../dbr';
import React from 'react';
import styles from './barcodeScanner.module.scss';
import dayjs from 'dayjs';
import { getWindow } from '../utils';
import { BarcodeScanner, EnumBarcodeFormat, EnumColourConversionMode, EnumGrayscaleTransformationMode } from 'dynamsoft-javascript-barcode';
import { Loader } from '../loader/Loader';

interface IBarcodeScannerProps {
    useDriverLicense?: boolean;
    onScan: (code: any) => void;
    onOpen?: () => void;
    onClose?: () => void;
    onDestroy?: () => void;
}

interface IBarcodeScannerState {

}

class BarcodeScannerComponent extends React.Component<IBarcodeScannerProps, IBarcodeScannerState> {
    private bDestroyed: boolean;
    private pScanner: Promise<any> | null;
    private scanner: any;
    private elRef: React.MutableRefObject<any>;
    private uniqueKey: string = `id-${new Date().getTime()}`;
    private driverLicenseFields = [
        {
            'abbreviation': 'DAB',
            'key': 'lastName',
            'description': 'Last Name'
        }, {
            'abbreviation': 'DCS',
            'key': 'lastName',
            'description': 'Last Name'
        }, {
            'abbreviation': 'DBO',
            'key': 'lastName',
            'description': 'Last Name'
        }, {
            'abbreviation': 'DAC',
            'key': 'firstName',
            'description': 'First Name'
        }, {
            'abbreviation': 'DBP',
            'key': 'firstName',
            'description': 'First Name'
        }, {
            'abbreviation': 'DCT',
            'key': 'firstName',
            'description': 'First Name'
        }, {
            'abbreviation': 'DAG',
            'key': 'address',
            'description': 'Residence Street Address'
        }, {
            'abbreviation': 'DAL',
            'key': 'address',
            'description': 'Residence Street Address'
        }, {
            'abbreviation': 'DAI',
            'key': 'city',
            'description': 'Residence City'
        }, {
            'abbreviation': 'DAN',
            'key': 'city',
            'description': 'Residence City'
        }, {
            'abbreviation': 'DAK',
            'key': 'postalCode',
            'description': 'Postal Code'
        }, {
            'abbreviation': 'DAP',
            'key': 'postalCode',
            'description': 'Postal Code'
        },
        {
            'abbreviation': 'DCF',
            'key': 'driverLicenseNumberAlberta',
            'description': 'Document Discriminator'
        },
        {
            'abbreviation': 'DAQ',
            'key': 'driverLicenseNumber',
            'description': 'License or ID Number'
        },
        {
            'abbreviation': 'DAJ',
            'key': 'provinceId',
            'description': 'State/Province'
        },
        {
            'abbreviation': 'DBA',
            'key': 'driverLicenseExpireDate',
            'format': 'date',
            'description': 'Expiry Date'
        }
    ];

    constructor(props: IBarcodeScannerProps) {
        super(props);
        this.bDestroyed = false;
        this.pScanner = null;
        this.elRef = React.createRef();
    }

    private static getField(keyword: string, barcode_text: string) {
        const k = barcode_text.search('\n' + keyword);
        if (k === -1)
            return false;
        const m = barcode_text.indexOf('\n', k + 1);
        const subtext = barcode_text.substring(k + 4, m);
        return subtext;
    }

    async componentDidMount() {
        try {
            this.scanner = await (this.pScanner = this.pScanner || BarcodeScanner.createInstance());

            this.scanner.bPlaySoundOnSuccessfulRead = true;
            // scanner.soundOnSuccessfullRead = new Audio("./pi.mp3");

            this.scanner.singleFrameMode = false;
            this.scanner._bUseWebgl = false;
            this.scanner.whenToVibrateforSuccessfulRead = true;
            let settings = await this.scanner.getRuntimeSettings();

            settings.expectedBarcodesCount = 1;

            if (this.props.useDriverLicense) {
                settings.furtherModes.grayscaleTransformationModes = [
                    EnumGrayscaleTransformationMode.GTM_ORIGINAL,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ];
                settings.barcodeFormatIds = EnumBarcodeFormat.BF_PDF417;
                settings.localizationModes = [16, 8, 2, 0, 0, 0, 0, 0];
                settings.region.regionMeasuredByPercentage = 1;
                settings.region.regionLeft = 10;
                settings.region.regionTop = 25;
                settings.region.regionRight = 90;
                settings.region.regionBottom = 75;
                settings.deblurLevel = 7;
                // settings.deblurModes = [1, 2, 4, 8, 0, 0, 0, 0, 0, 0];
                // settings.scaleUpModes = [EnumScaleUpMode.SUM_NEAREST_NEIGHBOUR_INTERPOLATION];
            } else {
                settings.barcodeZoneMinDistanceToImageBorders = 9;
                settings.barcodeFormatIds =
                    EnumBarcodeFormat.BF_INDUSTRIAL_25 |
                    EnumBarcodeFormat.BF_CODABAR |
                    EnumBarcodeFormat.BF_CODE_39 |
                    EnumBarcodeFormat.BF_CODE_39_EXTENDED |
                    EnumBarcodeFormat.BF_CODE_93 |
                    EnumBarcodeFormat.BF_CODE_128 |
                    EnumBarcodeFormat.BF_ITF;
                settings.region = this.calculateRegion();
                settings.localizationModes = [32, 8, 2, 0, 0, 0, 0, 0];
                settings.deblurLevel = 9;
                settings.scaleDownThreshold = 100000;
                settings.furtherModes.barcodeColourModes = [
                    1, 2, 32, 0, 0, 0, 0, 0
                ];
                // Commented for testing
                // settings.furtherModes.colourConversionModes[0] = EnumColourConversionMode.CICM_GENERAL;
                // settings.furtherModes.grayscaleTransformationModes[0] = EnumGrayscaleTransformationMode.GTM_ORIGINAL;
                // settings.furtherModes.grayscaleTransformationModes[1] = EnumGrayscaleTransformationMode.GTM_INVERTED;
            }

            await this.scanner.updateRuntimeSettings(settings);
            await this.scanner.updateVideoSettings({
                video: {
                    width: 1920,
                    height: 1080,
                    facingMode: 'environment'
                }
            });

            getWindow()?.addEventListener('resize', () => {
                settings.region = this.calculateRegion();
                this.scanner.updateRuntimeSettings(settings);
            });

            this.scanner.onUnduplicatedRead = async (txt: string, result: any) => {
                const decodedResult = await this.extractInformation(result.barcodeText);
                this.props.onScan({
                    format: result.barcodeFormatString,
                    text: decodedResult.aryTextToShow,
                    obj: decodedResult.aryObjToShow,
                    type: 'result'
                });

                if (result.barcodeText.indexOf('Attention(exceptionCode') !== -1) {
                    this.props.onScan({msg: result.exception.message, type: 'error'});
                }
            };

            if (this.bDestroyed) {
                this.scanner.destroy();
                return;
            }
            this.elRef.current.appendChild(this.scanner.getUIElement());
            const closeBtn: HTMLButtonElement | null = document.querySelector(`#${this.uniqueKey} .dce-btn-close`);
            closeBtn?.setAttribute('type', 'button');
            closeBtn?.addEventListener('click', () => this.props.onClose?.());
            await this.scanner.open();
            this.props.onOpen?.();
        } catch (ex: any) {
            this.props.onScan({msg: ex.message, type: 'error'});
            console.error(ex.message);
        }
    }

    async componentWillUnmount() {
        // this.bDestroyed = true;
        if (this.pScanner) {
            // (await this.pScanner).destroy();
        }
        await this.scanner?.close();
        this.props.onDestroy?.();
    }

    shouldComponentUpdate() {
        // Never update UI after mount, dbrjs sdk use native way to bind event, update will remove it.
        return false;
    }

    render() {
        return (
            <>
                <div id={this.uniqueKey} className={`${styles.scanner}`} ref={this.elRef}>
                    <Loader style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}/>
                </div>
                {/* <div id='cvses' style={{maxWidth: '100%', height: 350}}></div> */}
                <div className={styles.landscapeWarning}>
                    For better performance flip your device
                </div>
            </>
        );
    }

    async extractInformation(text: string) {
        let aryTextToShow = [];
        let aryObjToShow = [];
        for (let i = 0; i < this.driverLicenseFields.length; i++) {
            let __item = this.driverLicenseFields[i];
            let _fieldValue = BarcodeScannerComponent.getField(__item.abbreviation, text);
            if (_fieldValue !== false) {
                await (await this.pScanner).hide();
                aryObjToShow.push({
                    [__item.key]: __item?.format === 'date' ? dayjs(_fieldValue).format('YYYY-MM-DD') : _fieldValue.trim()
                });
                aryTextToShow.push(__item.description + '<br /><strong>' + _fieldValue.trim() + '</strong><br />');
                aryTextToShow.push('------------------------------<br />');
            }
        }

        if (!aryTextToShow?.length) {
            aryTextToShow = [text];
        }

        return {aryTextToShow: aryTextToShow.join(' '), aryObjToShow};
    }

    private calculateRegionLeft() {
        const clientWidth = getWindow()?.innerWidth as number;
        const clientHeight = getWindow()?.innerHeight as number;
        let left = (clientWidth - this.calculateRegionMaskEdgeLength()) / 2 / clientWidth;
        if (clientWidth > clientHeight) {
            return Math.round(left * 100) - 25;
        } else {
            return Math.round(left * 100) - 20;
        }
    }

    private calculateRegionTop() {
        const clientWidth = getWindow()?.innerWidth as number;
        const clientHeight = getWindow()?.innerHeight as number;
        let top =
            (clientHeight - this.calculateRegionMaskEdgeLength()) / 2 / clientHeight;
        if (this.props.useDriverLicense) {
            return Math.round(top * 100) - 5;
        } else {
            if (clientWidth > clientHeight) {
                return Math.round(top * 100) + 5;
            } else {
                return Math.round(top * 100);
            }
        }
    }

    private calculateRegionMaskEdgeLength() {
        const clientWidth = getWindow()?.innerWidth as number;
        const clientHeight = getWindow()?.innerHeight as number;
        let regionMaskEdgeLength = 0.4 * Math.min(clientWidth, clientHeight);
        return Math.floor(regionMaskEdgeLength);
    }

    private calculateRegion() {
        const left = this.calculateRegionLeft();
        const top = this.calculateRegionTop();

        return {
            regionLeft: left,
            regionRight: 100 - left,
            regionTop: top,
            regionBottom: 100 - top,
            regionMeasuredByPercentage: 1
        };
    }
}

export default BarcodeScannerComponent;
