import React from 'react';
import { blackWarnignIcon } from '../svg-icons/SvgIcons';

interface IWarningAlertProps {
  message: string | JSX.Element;
  helperText?: string | JSX.Element;
  className?: string
}

const WarningAlert: React.FC<IWarningAlertProps> = ({message, helperText, className}) => {
  return (
    <div data-testid={'warning-alert'}
      className={`d-flex align-items-start ${className}`}
      style={{
        background: '#FFF9DA',
        border: '1px solid #F4C00F',
        borderRadius: '8px',
        padding: '24px 16px'
      }}>
      <div className='me-3'>
        {blackWarnignIcon}
      </div>
        <div className='d-flex flex-column' style={{flex: 1}}>
        <div
          className={`${helperText ? 'mb-2' : ''}`}
          style={{
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '24px',
            color: '#00330F'
          }}>
            {message}
        </div>
        {helperText && <span style={{color: '#4D4D4D'}}>{helperText}</span>}
      </div>
    </div>
  )
}

export default WarningAlert;