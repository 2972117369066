import React, {PropsWithChildren} from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

export enum ToastPosition {
    TOP_START = 'top-start',
    TOP_CENTER = 'top-center',
    TOP_END = 'top-end',
    MIDDLE_START = 'middle-start',
    MIDDLE_CENTER = 'middle-center',
    MIDDLE_END = 'middle-end',
    BOTTOM_START = 'bottom-start',
    BOTTOM_CENTER = 'bottom-center',
    BOTTOM_END = 'bottom-end'
}

export enum ToastBg {
    Primary = 'primary',
    Secondary = 'secondary',
    Success = 'success',
    Danger = 'danger',
    Warning = 'warning',
    Info = 'info',
    Light = 'light',
    Dark = 'dark'
}

export interface INotificationToastProps {
    bg?: ToastBg,
    position?: ToastPosition,
    autohide?: boolean,
    delay?: number,
    showHeader?: boolean,
    heading?: string | JSX.Element
}

const NotificationToast: React.FC<PropsWithChildren<INotificationToastProps>> = ({
                                                                  bg,
                                                                  autohide = true,
                                                                  delay = 3000,
                                                                  showHeader = true,
                                                                  heading = 'Notification',
                                                                  children
                                                              }): JSX.Element => {
    return (
        <ToastContainer>
            <Toast show={true} autohide={autohide} bg={bg} delay={delay} style={{width: '320px'}}>
                {showHeader && (
                    <Toast.Header>
                        <span className="me-auto">{heading}</span>
                    </Toast.Header>
                )}
                <Toast.Body>
                    {children}
                </Toast.Body>
            </Toast>
        </ToastContainer>
    );
};

export default NotificationToast;
