import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';

import Alert from '../alerts/Alert';
import { Loader } from '../loader/Loader';
import { FieldTypes } from '../form/FormBuilder';
import { ModalPortal } from '../modal/ModalPortal';
import { IDealerVehicle } from '../interfaces/dealer/dealer';
import { MethodData, useAxios } from '../custom-axios/CustomAxios';
import { buildVehicleName, buildVehicleNameByVersion } from '../utils';
import vehicleItemStyles from '../vehicle-item/vehicleItem.module.scss';
import { ErrorTypes, validateEmailAddress } from '../validations/validations';
import InputLabelWithValidation from '../validations/InputLabelWithValidation';
import VehicleVin from '../../components/dealer/vehicle/intake/vehicle/VehicleVin';
import { dealerVehiclePath, inviteUserPath, lookupUserPath, vdVinDecodePath } from '../api/Api';
import VehicleImg from '../vehicle-img/VehicleImg';

interface IDealerSellsVehicleModalProps {
    showVidModal: boolean;
    setShowVidModal: (value: boolean) => void
}

const DealerSellsVehicleModal: React.FC<IDealerSellsVehicleModalProps> = ({showVidModal, setShowVidModal}) => {
    const router = useRouter();
    const [vin, setVin] = useState('');
    const [saveType, setSaveType] = useState('dealer_inventory');
    const [userEmail, setUserEmail] = useState('');
    const [userData, setUserData] = useState<any>(null);
    const [showUserSummary, setShowUserSummary] = useState(false);
    const [stockNumber, setStockNumber] = useState<string>('');

    const {loading: loadingVinData, data: vinData, error: vinError, fetch: fetchVin} = useAxios();
    const {fetch: addDealerVehicle, loading: loadingNewVehicle} = useAxios();
    const {fetch: checkSellerEmail, loading: loadingCheckEmail} = useAxios();
    const {fetch: inviteUser, loading: loadingUserInvite} = useAxios();

    const fetchVinHandler = (vin: string, styleId: number | null = null) => {
        setVin(vin);
        fetchVin(vdVinDecodePath, {
            body: {
                vin: vin,
                reducingExternalStyleId: styleId
            }
        });
    };

    const inviteUserAndAddVehicle = async () => {
        if (userData.found) {
            addVehicleHandler(userData.id);
            return;
        }

        const user = await inviteUser(inviteUserPath, {
            method: MethodData.POST,
            body: {
                email: userEmail
            }
        });

        if (user) {
            addVehicleHandler(user.id);
        }
    };

    const addVehicleHandler = (userId?: number) => {
        addDealerVehicle(dealerVehiclePath, {
            method: MethodData.POST,
            body: {
                vin: vinData.vin,
                vdVersionId: vinData.vdVersionId,
                decodeId: vinData?.decode?.id,
                vdBodyTypeId: vinData.vdBodyTypeId,
                doorsCount: vinData.doorsCount,
                ...(saveType === 'dealer_inventory' ? {stockNumber: stockNumber.trim()} : {}),
                userId
            }
        }).then((dealerVehicle: IDealerVehicle) => {
            setShowVidModal?.(false);
            router.push(`/dealer/vehicle/${dealerVehicle.id}?stage=intake`);
        });
    };

    const checkUser = () => {
        checkSellerEmail(lookupUserPath, {
            method: MethodData.POST,
            body: {
                email: userEmail
            }
        }).then((userData: any) => {
            setUserData(userData);
            setShowUserSummary(true);
        });
    };

    return (
        <ModalPortal
            className={'vin-scanner-modal'}
            show={showVidModal}
            showFooter={false}
            onCloseHandler={() => setShowVidModal(false)}
            onSave={() => {
            }}
            heading={
                <div
                    className={'d-flex justify-content-between align-items-center'}>
                  <span>
                    Sell a vehicle
                  </span>
                    <Button
                        variant={'link'}
                        className="link-button"
                        onClick={() => setShowVidModal(false)}>
                        Cancel
                    </Button>
                </div>
            }>
            {loadingVinData ? (
                <div className="loading-wrapper">
                    <Loader/>
                    <div className="loading-text">Looking up {vin}</div>
                </div>
            ) : vinData?.mustReduceStyles ? (
                <>
                    <div className="pb-3">Please specify your vehicle's trim version</div>
                    <span className="px-3">
                      <b>{buildVehicleName(vinData?.styles[0].modelYear, vinData?.styles[0].make?.name, vinData?.styles[0].model?.name, '')}</b>
                    </span>
                    <ul>
                        {vinData?.styles.map((s: any) => {
                            return (
                                <li key={s.externalId} className={vehicleItemStyles.vehicleItem}
                                    onClick={() => fetchVinHandler(vin, parseInt(s.externalId))}>
                                    <div className="d-flex align-items-center">
                                        <div className="vehicle-img">
                                            <VehicleImg src={s.photoPath} rounded={true}/>
                                        </div>
                                        <div className="d-flex flex-column vehicle-data" style={{borderRight: 'none'}}>
                                            <span>
                                            <b>{s.name + ' ' + (s.trim ? s.trim : '')}</b>
                                            <br/>
                                                {
                                                    vinData.engines.map((e: any, index: number) => {
                                                        if (e.installed) {
                                                            return (
                                                                <span key={index}>
                                                                    {s.bodyType?.name} {e.horsepower ? e.horsepower + 'hp' : ''} {e.engineFuelType?.name} {e.engineType?.name} {s.drivetrain}
                                                                </span>
                                                            );
                                                        }
                                                    })
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </>
            ) : vinData ? (
                <div>
                    {
                        vinData.isDecoded ? (
                            <div className="found-vehicle">
                                <div>Found vehicle</div>
                                <span className="vehicle-ymm">
                                {buildVehicleNameByVersion(vinData?.vdVersion, true)}
                              </span>
                            </div>
                        ) : (
                            <div>
                                <Alert className={'mb-3'} show={true} autoHide={false} type={'warning'}
                                       message={'The VIN decode has failed, please fill in details manually'}/>
                            </div>
                        )
                    }

                    {showUserSummary && userData ? (
                        <section className={'mt-3'}>
                            <div className="found-vehicle" data-testid={'vehicle-found'}>
                                <div>{userData?.found ? 'Found seller' : 'Seller not found'}</div>
                                <span className="vehicle-ymm">
                                    {userData?.name}
                                </span>
                                {!userData?.found && <span className="vehicle-ymm text-center">
                                  Signup invitation will be sent to<br/>{userEmail}
                                </span>}
                            </div>
                            <div className={'d-flex gap-2'}>
                                <Button
                                    onClick={() => {
                                        setShowUserSummary(false);
                                    }}
                                    className={'standardButton mt-3 w-50'}>
                                    <span className={loadingNewVehicle ? 'me-2' : ''}>Back</span>
                                </Button>
                                <Button data-testid={'add-vehicle'}
                                        onClick={() => inviteUserAndAddVehicle()}
                                        className={'standardButton btn-dark mt-3 w-50'}>
                                    <span className={loadingNewVehicle ? 'me-2' : ''}>Add vehicle</span>
                                    {loadingNewVehicle &&
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    }
                                </Button>
                            </div>
                        </section>
                    ) : <Form className="mt-3" onSubmit={e => e.preventDefault()}>
                        <Form.Check data-testid={'dealer-inventory'}
                                    type="radio"
                                    label={`Dealer Inventory`}
                                    name="save-type"
                                    id="dealer_inventory"
                                    defaultChecked={saveType === 'dealer_inventory'}
                                    value="dealer_inventory"
                                    onChange={(e) => {
                                        setSaveType(e.target.value);
                                    }}
                        />
                        {
                            saveType === 'dealer_inventory' ? (
                                <Form.Group controlId={'stock-number'} data-testid={'stock-number'} className="p-3 pb-0 mb-3">
                                    <Form.Label>Stock number</Form.Label>
                                    <InputGroup className="plain-control">
                                        <Form.Control value={stockNumber} maxLength={100}
                                                      onChange={e => setStockNumber(e.target.value)}/>
                                    </InputGroup>
                                </Form.Group>
                            ) : null
                        }
                        <Form.Check data-testid={'consignment-sale'}
                                    type="radio"
                                    label="Consignment Sale"
                                    name="save-type"
                                    id="consignment"
                                    defaultChecked={saveType === 'consignment'}
                                    value="consignment"
                                    onChange={(e) => setSaveType(e.target.value)}
                        />
                        {saveType === 'consignment' &&
                        <Form.Group className="p-3 pb-0" data-testid={'dealer-consignment-sale-email'}>
                          <InputLabelWithValidation
                            label="Seller email address"
                            error={userEmail && !validateEmailAddress(userEmail) ? {
                                type: ErrorTypes.INVALID_EMAIL,
                                message: 'Invalid email address',
                                ref: {}
                            } : undefined}
                            fieldType={FieldTypes.INPUT}
                          />
                          <InputGroup className="plain-control">
                            <Form.Control data-testid={'consignment-sale-input'}
                                          className={`${userEmail && !validateEmailAddress(userEmail) ? 'is-invalid' : ''}`}
                                          defaultValue={userEmail || ''}
                                          onChange={(e) => setUserEmail(e.target.value.trim())}
                            />
                          </InputGroup>
                        </Form.Group>}
                        {saveType === 'consignment' ? (
                            <Button data-testid={'add-consignment-sale-email'}
                                    disabled={!userEmail || !validateEmailAddress(userEmail)}
                                    onClick={checkUser}
                                    className={'submitButton mt-3 w-100'}>
                                <span className={loadingCheckEmail ? 'me-2' : ''}>Continue</span>
                                {loadingCheckEmail &&
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                }
                            </Button>
                        ) : <Button data-testid={'add-vehicle'}
                                    disabled={!stockNumber.trim()}
                                    onClick={() => stockNumber && addVehicleHandler()}
                                    className={'submitButton mt-3 w-100'}>
                            <span className={loadingNewVehicle ? 'me-2' : ''}>Add vehicle</span>
                            {loadingNewVehicle &&
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            }
                        </Button>}
                    </Form>}
                </div>
            ) : (
                <VehicleVin
                    isVinTypeMode={true}
                    onVinScan={fetchVinHandler}
                    vinDecodeError={vinError}
                />
            )}
        </ModalPortal>
    );
};

export default DealerSellsVehicleModal;
