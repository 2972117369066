import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

const DEFAULT_LANG = 'en';

export const I18NextInit = (resources: any) => {

    return i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: resources,
        interpolation: {escapeValue: false},
        lng: typeof localStorage === 'undefined' ? DEFAULT_LANG : localStorage.getItem('i18next_rd_lang') || DEFAULT_LANG,
        fallbackLng: 'en'
        // saveMissing: true, // send not translated keys to endpoint
    });
};
