import React, { useEffect } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useFieldArray } from 'react-hook-form';

import { IBaseProps } from '../input/IInputTypes';
import { FieldTypes, IFieldItemProps } from '../form/FormBuilder';
import styles from './fieldsArray.module.scss';
import { plusIcon } from '../svg-icons/SvgIcons';
import FieldsArrayList from './FieldsArrayList';
import { maskPhoneNumber } from '../utils';

export interface IFieldsArrayProps extends IBaseProps {
    fields?: IFieldItemProps[];
    btnLabel?: string;
    showAddBtn?: boolean;
    showRemoveBtn?: boolean;
    unregister?: (name: string) => void;
    getFieldComponent?: (field: IFieldItemProps, index: number) => void;
    getValues?: () => any;
    setValue?: (name: string, value: any, opt?: any) => void;
}

const FieldsArray: React.FC<IFieldsArrayProps> = ({
                                                      name,
                                                      label,
                                                      fields,
                                                      defaultValue = [],
                                                      getFieldComponent,
                                                      unregister,
                                                      control,
                                                      btnLabel = 'Add',
                                                      testId,
                                                      getValues,
                                                      setValue,
                                                      showAddBtn = true,
                                                      showRemoveBtn = true
                                                  }) => {
    const {fields: fieldsArray, append, remove, replace} = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name // unique name for your Field Array
    });

    useEffect(() => {
        if (defaultValue.length) {
            const fieldValues: any = [];

            defaultValue.forEach((field: any, index: number) => {
                const values = getValues?.()[name]?.[index];
                const fieldValue: any = values ? values : {};

                fields?.forEach((f: IFieldItemProps) => {
                    f.fieldType !== FieldTypes.TEMPLATE && field?.[f.name] && (fieldValue[f.name] = f.mask ? maskPhoneNumber(field?.[f.name]) : field?.[f.name] || '');
                });

                fieldValues.push(fieldValue);
            });

            replace(fieldValues);
        }
    }, [JSON.stringify(defaultValue), replace]);

    return (
        <div className={styles.fieldsArray} data-testid={testId}>
            {
                label ? (
                    <p>{label}</p>
                ) : null
            }
            <React.Fragment key={fieldsArray?.length}>
                {
                    fieldsArray?.length ? (
                        <FieldsArrayList name={name}
                                         fields={fields}
                                         fieldsArray={fieldsArray}
                                         getFieldComponent={getFieldComponent}
                                         showAddBtn={showAddBtn}
                                         onRemove={onRemove}
                                         showRemoveBtn={showRemoveBtn}/>
                    ) : (
                        <div className="mb-4">
                            No Fields
                        </div>
                    )
                }
            </React.Fragment>
            {showAddBtn && (
                <Row className={name}>
                    <Button data-testid={`${testId}-add`}
                            className="d-flex justify-content-start align-items-center py-2"
                            variant="secondary"
                            onClick={onFieldsArrAdd}>
                        {plusIcon}
                        <span className="ms-2"><b>{btnLabel}</b></span>
                    </Button>
                </Row>
            )}
        </div>
    );

    function onFieldsArrAdd() {
        const fieldValue: any = {};
        fields?.forEach((f: IFieldItemProps) => {
            f.fieldType !== FieldTypes.TEMPLATE && (fieldValue[f.name] = '');
        });

        append(fieldValue);
    }

    function onRemove(index: number) {
        remove(index);
    }
};

export default FieldsArray;
