import { PropsWithChildren, useState } from 'react';
import { IStorage, SessionStorageContext } from './LocalStorageContext';
import { getSessionStorage } from '../../lib/utils';

const rdStorageKey = 'rd_storage';

interface ISessionStorageProvider {

}

const SessionStorageProvider: React.FC<PropsWithChildren<ISessionStorageProvider>> = ({children}) => {
    const [storage, setStorage] = useState<IStorage>(getStorage());

    return (
        <SessionStorageContext.Provider value={{...storage, updateStorage}}>
            {children}
        </SessionStorageContext.Provider>
    );

    function updateStorage(storageKey: keyof IStorage, value: string) {
        setStorage(updateSessionStorageData(storageKey, value));
    }
};

function getStorage(): IStorage {
    let parsedStorage: IStorage;
    try {
        parsedStorage = JSON.parse(getSessionStorage()?.getItem(rdStorageKey) || '');
    } catch (e) {
        parsedStorage = {};
    }

    return parsedStorage;
}

function updateSessionStorageData(storageKey: keyof IStorage, value: string) {
    const storage = {
        ...getStorage(), [storageKey]: value
    };
    getSessionStorage()?.setItem(rdStorageKey, JSON.stringify(storage));

    return storage;
}

export default SessionStorageProvider;