import React, { useContext, PropsWithChildren } from 'react';
import { AccordionContext, Button, useAccordionButton } from 'react-bootstrap';
import { AccordionEventKey } from 'react-bootstrap/AccordionContext';

interface IAccordionCustomToggleProps {
    eventKey: AccordionEventKey;
}

export const AccordionCustomToggle: React.FC<PropsWithChildren<IAccordionCustomToggleProps>> = ({eventKey, children}) => {
    const context = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey as string, () =>
        console.log('totally custom!')
    );

    return (
        <Button
            type="button"
            className={`accordion-custom-toggle ${context.activeEventKey?.includes(eventKey as string) ? 'is-opened' : ''}`}
            onClick={decoratedOnClick}
            variant={'link'}
        >
            <span>
                {children}
            </span>
        </Button>
    );
};
