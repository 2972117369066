import React, { Dispatch } from 'react';
import { IUserActionProps } from '../../lib/reducer/user/UserReducer';
import { PermissionsEnum } from '../../lib/permissions/Permissions';
import { IListing } from '../../lib/interfaces/marketPlace/marketplace';
import { DealerRolesEnum } from '../dealer/Context';
import { IDealerOnboardingData, IFeatureFlag } from '../dealer/DealerOnboarding';
import { VehicleStagesEnum } from '../../lib/interfaces/vehicle/vehicle';

export interface IUser {
    id: number;
    lastName?: string;
    firstName?: string;
    phone?: string;
    email?: string;
    birthDate: string;
    type?: UserType;
    credentialType?: UserCredentialType;
    dealerProfile: {
        dealer: IDealerOnboardingData;
        dealerId: number;
        id: number;
        role: DealerRolesEnum;
        userId: number;
        featureFlags: IFeatureFlag[];
    }
    timezone: string;
    favorites: IFavorite[];
    createdAt: string;
    isVerified: boolean;
    modifiedAt: string;
}

export interface IFavorite {
    createdAt: string;
    id: number;
    listing: IListing;
    listingId: number;
    modifiedAt: string;
    userId: number;
}

export interface INotification {
    id: number,
    url: string,
    message: string,
    contextVariables: any,
    isRead: boolean,
    createdAt: string,
    vehicleData: {
        name: string,
        photoPath: string,
        stockNumber: string
        publishedVersion: any;
    }
}

interface IUserContext {
    user?: IUser;
    socialPrefillData?: IUser;
    hasUnreadNotifications?: boolean;
    isFullyOnboarded?: boolean;
    isPaid?: boolean;
    timezone?: string;
    redirectUrl?: string;
    dispatch?: Dispatch<IUserActionProps>;
    checkPermission?: (permission: PermissionsEnum) => boolean;
    canEditVehicle?: (stage: VehicleStagesEnum) => boolean;
    getUnreadNotificationsData?: () => void;
    getMe?: (callback?: Function) => void;
}

export enum UserType {
    DEALER = 'dealer',
    CONSUMER = 'consumer',
    NONE = 'none'
}

export enum UserCredentialType {
    INTERNAL = 'internal',
    FEDERATED = 'FEDERATED'
}

export enum FEDERATED_USER_PROVIDERS {
    APPLE = 'apple',
    AMAZON = 'amazon',
    FACEBOOK = 'facebook',
    GOOGLE = 'google'
};

export const UserContext: React.Context<IUserContext> = React.createContext({});
